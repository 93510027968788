import React from "react";
import ModelA from "./Model/ModelA";

const VideoTextSplit = props => {
  return (
    <>
      <ModelA {...props} />
    </>
  );
};

export default VideoTextSplit;
