import {
  gradientMap,
  textContentFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const TopicJumpLink = styled("div")<{
  $index: number;
  $backgroundColor?: "White" | "Black";
  $iconExists: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: calc((1280px - 120px) / 4);
  gap: 20px;
  height: 100%;

  .top-section {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    height: 64px;

    img {
      width: 35px;
      height: 35px;
    }

    h5 {
      font-size: 21px;
      line-height: 30px;
      text-transform: uppercase;
      width: ${({ $iconExists }) => ($iconExists ? "72.4%" : "100%")};
      margin-top: -6px;
      font-weight: 700;
      letter-spacing: 1.89px;
    }
  }

  hr {
    margin: 16px 0 8px 0px;
    height: 2px;
    border: none;
    background: ${({ $index }) =>
      `linear-gradient(to right, ${gradientMap[$index]})`};
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    p {
      ${textContentFontStyles()}
    }

    a {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      width: fit-content;
      padding: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: ${({ $backgroundColor }) =>
          $backgroundColor === "Black" ? "#fff" : "#000"};
        color: ${({ $backgroundColor }) =>
          $backgroundColor === "Black" ? "#000" : "#fff"};
        border-radius: 2px;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    width: calc((100% - 90px) / 4);
    gap: 16px;

    .top-section {
      gap: 16px;
      min-height: 52px;

      img {
        width: 30px;
        height: 30px;
      }

      h5 {
        font-size: 16px;
        line-height: 25px;
      }
    }

    .bottom-section {
      gap: 16px;
      p {
        ${textContentFontStyles("Tablet")}
      }

      a {
        font-size: 16px;
        line-height: 16px;
        padding: 8px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: calc((100% - 20px) / 2);
    gap: 12px;

    .top-section {
      gap: 12px;
      min-height: 40px;

      img {
        width: 25px;
        height: 25px;
      }

      h5 {
        font-size: 14px;
        line-height: 23px;
      }
    }

    .bottom-section {
      gap: 12px;
      p {
        ${textContentFontStyles("Mobile")}
      }

      a {
        gap: 3px;
        font-size: 14px;
        line-height: 14px;
        padding: 6px;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
  }
`;
