import styled from "styled-components";
import { BREAK_POINTS } from "@/src/lib/breakPoints";

export const CenteredCopyWrapper = styled("section")<{
  $backgroundColor?: string;
  $size?: string;
  $marginTop?: string;
  $highlightColor?: string;
}>`
  content-visibility: auto;
  contain-intrinsic-size: 500px;
  background: ${({ $backgroundColor }) => $backgroundColor};
  display: grid;
  padding: ${({ $size }) =>
    $size && $size === "sm" ? "0px 0px" : "100px 0px"};
  place-items: center;
  margin-top: ${({ $marginTop }) => `${$marginTop}px` || ""};
  position: relative;
  overflow: hidden;
  > div.arrow-wrap {
    position: absolute;
    left: 0;
    bottom: -50px;
    path {
      fill: ${({ $highlightColor }) => $highlightColor};
    }
  }
  &#bottom-page-cta {
    padding: 50px 0;
    > div {
      @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    h2 {
      &:first-child {
        font-size: 48px;
        line-height: 52px;
        max-width: ${({ lang }) => (lang && lang == "ja" ? "100%" : "75%")};
        margin: 0px auto;
      }
      div > #arrows-id-bottom-page-cta {
        display: none;
      }
    }
    & a {
      & span {
      }
      & p {
        font-family: "articulat-cf", sans-serif !important;
      }
    }
    .footer-copy-title {
      font-family: "Agrandir";
      font-size: 53px;
      text-transform: uppercase;
      margin-bottom: -50px;
      p {
        text-align: center;
        font-family: "Agrandir";
      }
      @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
        font-size: 32px;
      }
      @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
        max-width: 100%;
        font-size: 24px;
      }
      &-first-line {
        -webkit-text-stroke: 1px white;
        color: transparent;
      }
    }
    @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
      > div.arrow-wrap {
        left: -20px;
        width: 310px !important;
        height: 300px !important;
      }
    }

    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      > div.arrow-wrap {
        width: 210px !important;
        height: 200px !important;
      }
      &#bottom-page-cta {
        h2 {
          &:first-child {
            line-height: 50px;
            max-width: 100%;
            margin: 0 0;
            font-size: 48px;
            padding: 0;
          }
        }
      }
    }
  }
`;

export const CenteredCopyInner = styled("div")`
  display: grid;
  padding: 60px 20px;
  place-items: center;
  text-align: ${({ lang }) => (lang && lang == "ja" ? "left" : "center")};
  z-index: 1;
`;

export const CenteredCopyTitleWrapper = styled("div")<{
  $textColor?: string;
  $textHighlight?: string;
}>`
  padding-bottom: 45px;

  .centered-copy-wrapper-header {
    text-align: ${({ lang }) => (lang && lang == "ja" ? "left" : "center")};

    &:first-child {
      color: ${({ $textColor }) => $textColor};
      text-transform: initial;
    }
    &:nth-child(2) {
      color: ${({ $textHighlight }) => $textHighlight};
    }

    font-family: var(--primary-font-family), sans-serif;
    font-size: 24px;
    line-height: 34px;
    margin: 0px auto;
    max-width: 1024px;
    padding: 0 40px;
    text-transform: uppercase;
    font-weight: 700;

    @media only screen and (${BREAK_POINTS.TABLET}) {
      font-size: 48px;
      line-height: 54px;
    }

    /* @media only screen and (${BREAK_POINTS.DESKTOP}) {
      font-size: 80px;
      line-height: 68px;
    } */
  }
`;

export const CenteredCopyCTAWrap = styled("div")`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
export const CenteredCopyBody = styled("div")<{ $textColor?: string }>`
  display: grid;
  margin: 0px auto;
  max-width: 90%;
  place-items: center;
  text-align: ${({ lang }) => (lang && lang == "ja" ? "left" : "center")};
  font-size: 22px;
  color: ${({ $textColor }) => $textColor || "#FFFFFF"};
  text-align: left;
  p {
    color: ${({ $textColor }) => $textColor || "#FFFFFF"};
    font-family: "articulat-cf", sans-serif;
    font-size: 22px;
    line-height: 22px;
    padding-bottom: 20px;
  }
  ul {
    padding: 0 40px;
  }
  padding-bottom: 45px;
`;
