import styled from "styled-components";

export const MultiColumnBlock = styled("div")<{
  $backgroundColor?: string;
  $textColor?: string;
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? "transparent"};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;

  h2 {
    color: ${({ $textColor }) => $textColor ?? "#000"};
    font-size: 2.5rem;
    padding-bottom: 30px;
    text-align: center;
    font-weight: 700;
  }
`;

export const MultiColumnBlockGrid = styled("div")<{
  $gridCount?: number;
}>`
  display: flex;
  row-gap: 20px;
  justify-content: center;
  max-width: 1280px;
  column-gap: 30px;
  padding: 0;
  justify-items: center;

  .plain-text-block {
    width: ${({ $gridCount }) => `calc((1280px - 60px) / ${$gridCount})`};
    > div {
      width: 100%;
    }
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1280px) {
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 0 30px;
  }
`;
