import React from "react";
import { useRouter } from "next/navigation";

import { useLightboxData } from "@/src/context/lightboxContext";
import ContentfulImage from "@/src/lib/contentful-image";
import Icon from "../../Global_Components/Icon/Icon";
import * as Styles from "./BlogPostPreviewCardStyles";
import { MainImageType } from "@/src/types/types";

interface BlogPostPreviewCardProps {
  image?: MainImageType;
  imageSource?: string;
  imageDescription?: string;
  blogPostType?: any;
  title?: string;
  author?: any;
  published?: any;
  previewCopy?: any;
  callToActionCopy?: any;
  callToActionLink?: any;
  textColor?: string;
  tags?: any;
  videoId?: any;
  lang?: string;
}

const BlogPostPreviewCard: React.FC<BlogPostPreviewCardProps> = ({
  image,
  imageSource,
  imageDescription,
  blogPostType,
  title,
  author,
  published,
  previewCopy,
  callToActionCopy,
  callToActionLink,
  textColor = "#000",
  tags,
  videoId,
  lang,
}) => {
  const date = new Date(published);
  const year = date.getFullYear().toString();
  let month: any = date.getMonth() + 1;
  let dt: any = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const publishedDate = `${month}.${dt}.${year}`;
  const lightBoxData = useLightboxData();
  const router = useRouter();

  const handleVideoClick = e => {
    e.preventDefault();
    lightBoxData?.setLightBoxData({
      open: true,
      type: "video",
      content: videoId,
    });
  };

  const renderImage = (image, imageSource, imageDescription) => (
    <Styles.ImgContainer>
      <div className="img-inner-container">
        <ContentfulImage
          alt={image?.fields?.title || imageDescription || ""}
          width={300}
          height={300}
          quality={100}
          priority={true}
          src={image?.fields?.file?.url || imageSource}
        />
      </div>
    </Styles.ImgContainer>
  );

  const renderContent = () => (
    <>
      {image && renderImage(image, imageSource, imageDescription)}
      {!image &&
        imageSource &&
        renderImage(null, imageSource, imageDescription)}
      <span>{blogPostType}</span>
      {author && (
        <span className="author-date">
          {publishedDate} BY {author.toUpperCase()}
        </span>
      )}
      <h4>{title}</h4>
      {previewCopy && <p>{previewCopy}</p>}
      {tags && tags.length > 0 && (
        <div>
          {tags.slice(0, 3).map(tag => (
            <span
              key={tag.name}
              className="topic-tag"
              onClick={e => handleTagClick(e, tag.slug)}
            >
              {tag.name}
            </span>
          ))}
        </div>
      )}
      <span>
        <Icon iconImage="arrow" iconColor={textColor} className={""} />
        {callToActionCopy}
      </span>
    </>
  );

  const handleTagClick = (e, tagSlug) => {
    e.preventDefault();
    e.stopPropagation();
    router.push(`/${lang}/resources/blog/tagged?tag=${tagSlug}`);
  };

  const handleLinkClick = e => {
    e.preventDefault();
    router.push(callToActionLink);
  };

  return (
    <Styles.BlogPostPreviewCardWrapper $textColor={textColor}>
      {blogPostType === "Webinar" ||
      blogPostType === "Video" ||
      blogPostType === "brightcoveVideo" ? (
        <div onClick={handleVideoClick} style={{ cursor: "pointer" }}>
          {renderContent()}
        </div>
      ) : (
        <div onClick={handleLinkClick} style={{ cursor: "pointer" }}>
          {renderContent()}
        </div>
      )}
    </Styles.BlogPostPreviewCardWrapper>
  );
};

export default BlogPostPreviewCard;
