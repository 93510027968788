import React from "react";
import Markdown from "markdown-to-jsx";

import * as Styles from "./styles";
import ComponentLookup from "../../Global_Components/ComponentLookup/ComponentLookup";

interface IMultiColumnBlockProps {
  backgroundColor?: string;
  customCss?: string;
  items?: any[];
  headline: string;
  showHeadline: boolean;
  slug: string;
  textColor?: string;
  lang: string;
}

const MultiColumnBlock = ({
  backgroundColor,
  customCss,
  items,
  headline,
  showHeadline,
  slug,
  textColor,
  lang,
}: IMultiColumnBlockProps) => {
  const gridCount = items?.length;
  return (
    <Styles.MultiColumnBlock
      id={slug}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
    >
      {/* If custom CSS property is used, inject CSS here */}
      {customCss && <Markdown>{customCss}</Markdown>}
      {/* If the headline is passed in and showHeadline is set to true, display h2 with html */}
      {showHeadline && headline ? (
        <h2>
          <Markdown>{headline}</Markdown>
        </h2>
      ) : null}
      <Styles.MultiColumnBlockGrid $gridCount={gridCount}>
        {/* Map over all items in multiColumnBlock, looking up the element
         based off of sys.contentType.sys.id value using lookup function */}
        {items &&
          items.map(col => {
            col = {
              block: {
                lang,
                ...col,
              },
            };
            return ComponentLookup({ ...col, key: col.id });
          })}
      </Styles.MultiColumnBlockGrid>
    </Styles.MultiColumnBlock>
  );
};

export default MultiColumnBlock;
