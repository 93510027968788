import { MainImageType } from "@/src/types/types";
import React from "react";
import * as Styles from "./styles";
import CTA, { ICTA } from "../CTA";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";

export interface IColumnTopic {
  imageAsset?: MainImageType;
  title?: string;
  content?: string;
  cta?: ICTA;
  lang: string;
  backgroundColor?: "Black" | "White" | "Grey";
  numOfColumns: number;
}

const ColumnTopic = ({
  imageAsset,
  title,
  content,
  cta,
  lang,
  backgroundColor,
  numOfColumns,
}: IColumnTopic) => {
  return (
    <Styles.TopicColumn $numOfColumns={numOfColumns}>
      {imageAsset && (
        <ContentfulImage
          alt={imageAsset.fields?.description || ""}
          width={imageAsset.fields?.file?.details.image.width || 1000}
          height={imageAsset.fields?.file?.details.image.height || 1000}
          quality={80}
          priority={true}
          src={imageAsset.fields?.file?.url}
        />
      )}
      <div className="content-section">
        {title && (
          <Markdown
            options={{
              wrapper: "h6",
              forceWrapper: true,
            }}
          >
            {title}
          </Markdown>
        )}

        {content && (
          <Markdown
            options={{
              wrapper: "span",
              forceWrapper: true,
            }}
          >
            {content}
          </Markdown>
        )}
        {cta && (
          <CTA
            cta={cta}
            ctaLength={1}
            index={0}
            lang={lang}
            backgroundColor={backgroundColor}
            type="textLink"
          />
        )}
      </div>
    </Styles.TopicColumn>
  );
};

export default ColumnTopic;
