import { MainImageType } from "@/src/types/types";
import React from "react";
import * as Styles from "./styles";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import Link from "next/link";
import { TfiArrowRight } from "react-icons/tfi";

export interface INavItem {
  fields: {
    internalName: string;
    icon?: MainImageType;
    title?: string;
    content?: string;
    linkText: string;
    url: string;
  };
}

interface IJumpLinkProps {
  navItem: INavItem;
  index: number;
  backgroundColor?: "White" | "Black";
}

const JumpLinkTopic = ({ navItem, index, backgroundColor }: IJumpLinkProps) => {
  const { linkText, url, content, icon, title } = navItem.fields;
  return (
    <Styles.TopicJumpLink
      $iconExists={!!icon}
      $backgroundColor={backgroundColor}
      $index={index}
    >
      <div className="top-section">
        {icon && (
          <ContentfulImage
            alt={icon.fields?.description || ""}
            width={icon.fields?.file?.details.image.width || 1000}
            height={icon.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={icon.fields?.file?.url}
          />
        )}
        {title && <h5>{title}</h5>}
      </div>
      <hr />
      <div className="bottom-section">
        {content && (
          <Markdown
            options={{
              wrapper: "span",
              forceWrapper: true,
            }}
          >
            {content}
          </Markdown>
        )}
        <Link prefetch href={`#${url}`}>
          <TfiArrowRight />
          {linkText}
        </Link>
      </div>
    </Styles.TopicJumpLink>
  );
};

export default JumpLinkTopic;
