import { JSONValue, MainImageType } from "@/src/types/types";
import React, { lazy } from "react";
import CTA, { ICTA } from "../../Topics/CTA";
import * as Styles from "./styles";
import Markdown from "markdown-to-jsx";
import ContentfulImage from "@/src/lib/contentful-image";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);

export interface ICalloutProps {
  internalName: string;
  imageAsset?: MainImageType;
  video?: {
    fields: {
      internalName: string;
      playerId: string;
      video: JSONValue;
    };
  };
  title?: string;
  content?: string;
  quote?: {
    fields: {
      internalName: string;
      quote: string;
      attribution?: string;
      jobTitle?: string;
      company?: string;
    };
  };
  cta?: ICTA[];
  backgroundColor?: "Black" | "White" | "Grey";
  paddingTop?: "Medium" | "Small";
  paddingBottom?: "Medium" | "Small";
  lang: string;
  alignment?: "Asset Left" | "Asset Right";
}

const CalloutModule = (props: ICalloutProps) => {
  const {
    content,
    backgroundColor,
    cta,
    imageAsset,
    paddingBottom,
    paddingTop,
    quote,
    video,
    title,
    lang,
    alignment,
  } = props;
  return (
    <Styles.ModuleCallout
      $quoteExists={!!quote}
      $assetExists={!!imageAsset || !!video}
      $backgroundColor={backgroundColor}
      $paddingBottom={paddingBottom}
      $paddingTop={paddingTop}
      $alignment={alignment}
    >
      {!!quote ? (
        <div className="content-section">
          {title && (
            <Markdown
              className="title"
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {title}
            </Markdown>
          )}
          <p className="quote">
            <span className="quotation">“</span>
            <Markdown
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {quote.fields.quote}
            </Markdown>
            <span className="quotation">”</span>
          </p>
          {quote.fields.attribution ||
          quote.fields.company ||
          quote.fields.jobTitle ? (
            <div className="attribution-section">
              {quote.fields.attribution && (
                <Markdown
                  className="attribution-line"
                  options={{
                    wrapper: "span",
                    forceWrapper: true,
                  }}
                >
                  {quote.fields.attribution}
                </Markdown>
              )}
              {quote.fields.jobTitle && (
                <Markdown
                  className="job-title-line"
                  options={{
                    wrapper: "span",
                    forceWrapper: true,
                  }}
                >
                  {quote.fields.jobTitle}
                </Markdown>
              )}
              {quote.fields.company && (
                <Markdown
                  className="company-line"
                  options={{
                    wrapper: "span",
                    forceWrapper: true,
                  }}
                >
                  {quote.fields.company}
                </Markdown>
              )}
            </div>
          ) : null}
          {cta &&
            cta.map((c, i) => (
              <CTA
                cta={c}
                ctaLength={cta.length}
                key={i}
                index={i}
                backgroundColor={backgroundColor}
                lang={lang}
                type="textLink"
              />
            ))}
        </div>
      ) : (
        <div className="content-section">
          {title && (
            <Markdown
              className="title"
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {title}
            </Markdown>
          )}
          {content && (
            <Markdown
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {content}
            </Markdown>
          )}
          {cta &&
            cta.map((c, i) => (
              <CTA
                cta={c}
                ctaLength={cta.length}
                key={i}
                index={i}
                backgroundColor={backgroundColor}
                lang={lang}
                type="textLink"
              />
            ))}
        </div>
      )}
      {imageAsset || video ? (
        <div className="asset-section">
          {imageAsset ? (
            imageAsset.fields.file.contentType === "application/json" ? (
              <Player
                className="lottie-file"
                src={imageAsset.fields.file.url}
                autoplay
                loop
              >
                {/* <Controls
								visible={true}
								darkTheme={true}
								buttons={[
									"play",
									"repeat",
									"frame",
									"stop",
								]}
							/> */}
              </Player>
            ) : (
              <ContentfulImage
                alt={imageAsset.fields?.description || ""}
                width={imageAsset.fields?.file?.details.image.width || 1000}
                height={imageAsset.fields?.file?.details.image.height || 1000}
                quality={100}
                priority={true}
                src={imageAsset.fields?.file?.url}
              />
            )
          ) : video ? (
            <React.Suspense
              fallback={
                <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                  <div
                    className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                    style={{
                      backgroundSize: "200% 100%",
                      animation: "shimmer 1.5s infinite",
                    }}
                  />
                </div>
              }
            >
              <LazyReactPlayerLoader
                playerId={video.fields.playerId}
                baseUrl="https://players.brightcove.net"
                accountId="1160438696001"
                videoId={video.fields.video["video_id"]}
              />
            </React.Suspense>
          ) : null}
        </div>
      ) : null}
    </Styles.ModuleCallout>
  );
};

export default CalloutModule;
