import React from "react";
import ModelA from "./Model/ModelA";
import ModelB from "./Model/ModelB";
import { MainImageType } from "@/src/types/types";

interface IPlayseason {
  title: string;
  header: string;
  body: string;
  bodyRight: string;
  type: string;
  backgroundColor: string;
  image: MainImageType;
  contentCta: any;
  blockLogo: any;
  lang: string;
}

const PlaySeason = (props: IPlayseason) => {
  const { type } = props;
  return (
    <>
      {type === "model-A" && <ModelA {...props} />}
      {type === "model-B" && <ModelB {...props} />}
    </>
  );
};

export default PlaySeason;
