import React, { lazy } from "react";
// Style
import * as Styles from "../FullWidthVideoBlockStyles";
import Cookies from "js-cookie";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);

const FullWidthVideoBlock = ({
  video,
  eyebrow,
  body,
  header,
  contentCta,
  playerId,
  data,
  lang,
}) => {
  const options = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
    aspectRatio: "16:9",
    nativeTextTracks: false,
    preloadTextTracks: false,
    nativeVideoTracks: false,
    nativeControlsForTouch: false,
    userActions: {
      click: false,
      doubleClick: false,
    },
  };
  // Change Different Class on header
  // If German or French Spanish Language on HomePage Dev, Prod and local
  let headerClass = "header";
  if (data && data.slug === "brightcove-home" && data.node_locale === "de") {
    headerClass = "header-german-homepage";
  } else if (
    data &&
    data.slug === "brightcove-home" &&
    (data.node_locale === "fr" || data.node_locale === "es")
  ) {
    headerClass = "header-french-homepage";
  }

  return (
    <Styles.FullWidthVideoBlock>
      <ContainerStyle fluid="maxFluid" id="Model-F">
        <div className="video-wrapper">
          {/* Video Player */}
          <React.Suspense
            fallback={
              <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                  style={{
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
              </div>
            }
          >
            <LazyReactPlayerLoader
              playerId={playerId || "RUsPQ8qzl"}
              baseUrl="https://players.brightcove.net"
              refNode="video-wrap"
              accountId={video.account_id || "1160438696001"}
              videoId={video.video_id}
              options={options}
              className="vjs-fluid"
              onEmbedCreated={el => {
                sfmcCookie();
                el.setAttribute("data-bc-known-user", 1);
                el.setAttribute(
                  "data-bc-custom-guid",
                  Cookies.get("_sfmc_id_")
                );
              }}
            />
          </React.Suspense>
        </div>
        {/* Text  */}
        <div className="container-text">
          <div className="wrapper-text">
            {/* Header Eyebrow Body */}
            {eyebrow && <Markdown>{eyebrow}</Markdown>}
            {header && (
              <Markdown
                className={
                  lang === "de"
                    ? "header-german-homepage"
                    : lang === "fr"
                      ? "header-french-homepage"
                      : "header"
                }
              >
                {header}
              </Markdown>
            )}
            {body && <Markdown>{body}</Markdown>}
            {/* Content CTA */}
            {contentCta ? (
              <div className="wrapper-button">
                <ContentCTA contentCta={{ ...contentCta, lang }} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </ContainerStyle>
    </Styles.FullWidthVideoBlock>
  );
};

export default FullWidthVideoBlock;
