import styled from "styled-components";

export const Column = styled("div")<{
  $backgroundColor?: string;
  $headerColor?: string;
}>`
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding-bottom: 20px;
  .disabled-link {
    pointer-events: none;
  }
  .wrapper-column {
    border: 1px solid #bebebe;
    width: 100%;
    background: #fff;
    .header-block {
      background: ${({ $backgroundColor }) => $backgroundColor};
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .header-column {
        color: ${({ $headerColor }) => $headerColor};
        font-size: 25px;
        text-align: center;
        padding: 10px;
      }
    }
    .body {
      padding: 20px;
      text-align: center;

      ul {
        list-style: disc;
        padding-left: 10px;
      }
    }
    .cta-link {
      padding: 20px;
    }
    /* Big Tablet:  max-width: 1279px */
    @media only screen and (max-width: 1279px) {
    }
    /* Tablet and Mobile: max-width: 768px */
    @media only screen and (max-width: 768px) {
    }
  }
`;
