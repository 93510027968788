import React from "react";

import * as Styles from "./styles";
import Markdown from "markdown-to-jsx";

// IFrame Component Interface
interface IIFrameComponentProps {
  // This is the url of the website displayed in the iframe.
  url: string;
  // If used in a 2 column grid, 'Half-Size' will be used. If full width, 'Full-Size'
  // will be used. If the values will be custom, 'Override' will be used
  desktopSize: "Full-Size" | "Half-Size" | "Override";
  // If 'Override' is selected, width and height will be used for the dimensions
  // of the desktop size of the iframe.
  width?: number;
  iFrameHeight?: number;
  header?: string;
  subheader?: string;
  backgroundColor?: string;
  headerTextColor?: string;
  aspectRatio?: string;
}

const IFrameComponent = ({
  url,
  desktopSize,
  iFrameHeight,
  width,
  header,
  subheader,
  headerTextColor,
  backgroundColor,
  aspectRatio,
}: IIFrameComponentProps) => {
  // This function derives the width and height of the iframe for desktop size, in pixels
  const getWidthAndHeight = () => {
    switch (desktopSize) {
      case "Full-Size":
        return {
          width: 1120,
          height: 630,
        };
      case "Half-Size":
        return {
          width: 560,
          height: 315,
        };
      case "Override":
        return {
          width,
          height: iFrameHeight || 0,
        };
      default:
        return {
          width: 560,
          height: 315,
        };
        break;
    }
  };
  return (
    <Styles.MainStyles
      $width={getWidthAndHeight().width}
      $backgroundColor={backgroundColor || "transparent"}
      $headerTextColor={headerTextColor || "#000"}
      $aspectRatio={aspectRatio || "16:9"}
    >
      <div className="parent-wrapper">
        {header && (
          <h2>
            <Markdown>{header}</Markdown>
          </h2>
        )}
        {subheader && <Markdown>{subheader}</Markdown>}
        <div>
          <IFrameContainer url={url} getWidthAndHeight={getWidthAndHeight} />
        </div>
      </div>
    </Styles.MainStyles>
  );
};

const IFrameContainer = ({ getWidthAndHeight, url }) => {
  return (
    <div className="relative-container-iframe">
      <iframe
        className={`iframe-container`}
        src={url}
        width={`${getWidthAndHeight().width}px`}
        height={`${getWidthAndHeight().height}px`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IFrameComponent;
