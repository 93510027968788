import styled from "styled-components";

export const PartnerListWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  @media only screen and (max-width: 1280px) {
    margin: 0 30px;
  }
  .partner-list-filters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 1279px) {
    .partner-item {
      width: calc((100% - 30px) / 2) !important;
    }
  }
`;
