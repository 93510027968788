import React from "react";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import { GridCardWrapper } from "./GridCardStyles";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Link from "next/link";
import getLinkFromContent from "@/src/lib/getLinkFromContent";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";

export function GridCard({ card, cardStyle, lang }) {
  const cardClass = cardStyle.toLowerCase();
  const numberColumn = cardClass === "image-left" ? 6 : 4;
  const { contentCta } = card.fields;

  const handleNavigation = () => {
    const href = contentCta.fields.externalLink
      ? contentCta.fields.externalLink
      : getLinkFromContent({
          linkTo: contentCta.fields.linkTo,
          lang,
          staticPage: contentCta.fields.staticPage,
        });

    if (contentCta.fields.noExternalLink) {
      window.location.href = href;
    } else {
      window.open(href, "_blank");
    }
  };

  return (
    <ColStyle lg={3} md={6} sm={12}>
      {contentCta ? (
        <div
          onClick={handleNavigation}
          style={{ cursor: "pointer" }}
          role="button"
          tabIndex={0}
          onKeyPress={e => {
            if (e.key === "Enter") handleNavigation();
          }}
        >
          <GridCardInner lang={lang} cardClass={cardClass} card={card} />
        </div>
      ) : (
        <GridCardInner lang={lang} cardClass={cardClass} card={card} />
      )}
    </ColStyle>
  );
}

const GridCardInner = ({ cardClass, card, lang }) => {
  const { image, header, subHeader, contentCta, hideCta } = card.fields;
  return (
    <GridCardWrapper
      $textColor={card.fields.textColor}
      $backgroundColor={card.fields.backgroundColor}
      $cardClass={cardClass}
    >
      <div className={`card-${cardClass}`}>
        {image && (
          <ContentfulImage
            alt={image?.fields?.title || ""}
            width={image?.fields?.file?.details.image.width || 1000}
            height={image?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image?.fields?.file?.url}
          />
        )}
        <div className="text-container">
          {header && (
            <h5 className="header-text">
              <Markdown>{header}</Markdown>
            </h5>
          )}
          {subHeader && (
            <div className="sub-text">
              <Markdown
                options={{
                  wrapper: "span",
                  forceWrapper: true,
                }}
              >
                {subHeader}
              </Markdown>
            </div>
          )}
          <div style={{ display: `${hideCta ? "none" : ""}` }}>
            {contentCta && <ContentCTA contentCta={{ ...contentCta, lang }} />}
          </div>
        </div>
      </div>
    </GridCardWrapper>
  );
};
