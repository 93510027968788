import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const MainStyles = styled.div`
  width: 100%;
  padding: 0 calc((100% - 1280px) / 2) 30px;
  background-color: #f8f8fa;

  @media only screen and (max-width: 1279px) {
    padding: 0 30px 30px;
  }

  .press-release-list {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    row-gap: 20px;
    flex-wrap: wrap;
  }

  .press-release-header {
    color: #000;
    display: inline-block;
    padding: 10px;
    margin-bottom: 25px;
    margin-top: 50px;
    font-size: 32px;
    padding-left: 0;
  }

  .press-release-cta-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    p {
      margin-top: 0;
    }
  }
`;

export const LinkWrapperYikes = styled.div`
  overflow: hidden;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transform: scale(1);
  background-color: #fff;
  transition: all 300ms ease-in-out;
  width: calc((1280px - 60px) / 3);
  border-left-width: 3px;
  border-style: solid;
  border-bottom: none;
  border-top: none;
  border-image: linear-gradient(
      rgb(78, 169, 209),
      rgb(170, 108, 126),
      rgb(247, 101, 49),
      rgb(239, 217, 0)
    )
    0 0 0 100%;
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    width: calc((100% - 30px) / 2);
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transform: scale(1.02);
  }
  a {
    display: flex;
    cursor: pointer;
    margin-top: 0;
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    a {
      display: block;
    }
  }
`;

export const PressTextWrapper = styled.div`
  padding: 15px 20px;
  .press-list-title {
    display: block;
    margin: 5px 0 0;
    font-size: 16px;
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
  }
`;

export const PressListSmall = styled.small`
  font-family: "articulat-cf", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 1);

  strong {
    font-family: "Agrandir", sans-serif;
    display: inline-block;
    margin-right: 10px;
  }
`;
const PressListCTA = styled.span`
  font-family: "articulat-cf", sans-serif;
  font-weight: 400;
  p {
    line-height: 20px;
  }
`;

const PressListByline = styled.p`
  font-size: 1rem;
  color: rgb(0, 0, 0);
`;
