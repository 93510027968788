import React from "react";
import * as Styles from "./styles";
import CardTopic, { ICard } from "../../Topics/Card";

interface ICardGridProps {
  internalName: string;
  items?: ICard[];
  paddingTop?: "Medium" | "Small";
  paddingBottom?: "Medium" | "Small";
  lang: string;
}

const CardGridModule = (props: ICardGridProps) => {
  const { lang, items, paddingBottom, paddingTop } = props;
  return (
    <Styles.ModuleCardGrid
      $paddingBottom={paddingBottom}
      $paddingTop={paddingTop}
      $numOfItems={items?.length}
    >
      {items?.map((item, index) => {
        return <CardTopic item={item} lang={lang} key={index} />;
      })}
    </Styles.ModuleCardGrid>
  );
};

export default CardGridModule;
