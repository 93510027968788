import React from "react";
import * as Styles from "./styles";
import Markdown from "markdown-to-jsx";

interface IHeadlineProps {
  internalName: string;
  jumpLinkId?: string;
  tag?: string;
  headline?: string;
  content?: string;
  paddingTop?: "Small" | "Medium";
  paddingBottom?: "Small" | "Medium";
  lang: string;
  backgroundColor?: "Black" | "White";
  headlineStyle?:
    | "Regular"
    | "Gradient 1"
    | "Gradient 2"
    | "Gradient 3"
    | "Gradient 4";
}

const HeadlineModule = (props: IHeadlineProps) => {
  const {
    lang,
    content,
    headline,
    jumpLinkId,
    paddingBottom,
    paddingTop,
    backgroundColor,
    tag,
    headlineStyle,
  } = props;
  return (
    <Styles.ModuleHeadline
      $backgroundColor={backgroundColor}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      id={jumpLinkId}
      $headlineStyle={headlineStyle}
    >
      {tag && (
        <Markdown
          className="content-tag"
          options={{
            wrapper: "span",
            forceWrapper: true,
          }}
        >
          {tag}
        </Markdown>
      )}
      {headline && <h2 className="gradient-header">{headline}</h2>}
      {content && (
        <Markdown
          className="content-tag"
          options={{
            wrapper: headline ? "p" : "h3",
            forceWrapper: true,
          }}
        >
          {content}
        </Markdown>
      )}
    </Styles.ModuleHeadline>
  );
};

export default HeadlineModule;
