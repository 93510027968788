import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Markdown from "markdown-to-jsx";
import * as Styles from "./FullWidthFeatureBlockStyles";
import CallToAction from "../CallToAction/CallToAction";
import ContentfulImage from "@/src/lib/contentful-image";

const FullWidthFeatureBlock = ({
  slug,
  logo,
  ctaText,
  ctaLink,
  textColor = "#FFF",
  highlightColor = "#FFF",
  backgroundColor = "transparent",
  backgroundImage,
  headline,
  lang,
}) => {
  return (
    <Styles.FullWidthFeatureBlock
      id={slug}
      $textColor={textColor}
      $backgroundColor={backgroundColor}
    >
      {backgroundImage && (
        <ContentfulImage
          alt={backgroundImage?.fields?.title || ""}
          width={backgroundImage?.fields?.file?.details.image.width || 1000}
          height={backgroundImage?.fields?.file?.details.image.height || 1000}
          quality={100}
          priority={true}
          src={backgroundImage?.fields?.file?.url}
        />
      )}
      <Container>
        <Row>
          <Col lg={8}>
            {logo && (
              <ContentfulImage
                alt={logo?.fields?.title || ""}
                width={logo?.fields?.file?.details.image.width || 1000}
                height={logo?.fields?.file?.details.image.height || 1000}
                quality={100}
                priority={true}
                src={logo?.fields?.file?.url}
              />
            )}
            <h2>
              <Markdown>{headline}</Markdown>
            </h2>
            <CallToAction
              callToActionCopy={ctaText}
              iconColor={highlightColor}
              linkPath={`/${lang}/${ctaLink}`}
              textColor={textColor}
              lang={lang}
            />
          </Col>
        </Row>
      </Container>
    </Styles.FullWidthFeatureBlock>
  );
};

export default FullWidthFeatureBlock;
