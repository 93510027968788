import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const FullWidthFeatureBlock = styled("section")<{
  $backgroundColor?: string;
  $textColor?: string;
}>`
  content-visibility: auto;
  contain-intrinsic-size: 1280px 600px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "#transparent"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: ${({ $textColor }) => $textColor || "#FFF"};
  padding: 100px 0;
  position: relative;

  > div > div {
    h2 {
      font-family: var(--primary-font-family), sans-serif;
      margin: 0;
      text-transform: uppercase;
      font-size: 3.5rem;
      line-height: 0.9;
      padding: 20px 0;
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 60px 0;
    contain-intrinsic-size: 320px 600px;
    > div > div {
      h2 {
        font-size: 2rem;
      }
    }
  }
`;
