import React, { lazy } from "react";
import ReactPlayerLoader from "@brightcove/react-player-loader";

import * as Styles from "../FullWidthVideoBlockStyles";
import Cookies from "js-cookie";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);


export const FullWidthVideoBlock = ({ video, playerId }) => {
  return (
    <Styles.FullWidthVideoBlock>
      <ContainerStyle id="Model-A" fluid="maxFluid">
        <div className="video-wrapper">
          {/* Video Player */}
          <React.Suspense
            fallback={
              <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                  style={{
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
              </div>
            }
          >
            <LazyReactPlayerLoader
              playerId={playerId || "RUsPQ8qzl"}
              baseUrl="https://players.brightcove.net"
              refNode="video-wrap"
              accountId={video.account_id || "1160438696001"}
              videoId={video.video_id}
              onEmbedCreated={el => {
                sfmcCookie();
                el.setAttribute("data-bc-known-user", 1);
                el.setAttribute(
                  "data-bc-custom-guid",
                  Cookies.get("_sfmc_id_")
                );
              }}
            />
          </React.Suspense>
        </div>
      </ContainerStyle>
    </Styles.FullWidthVideoBlock>
  );
};

export default FullWidthVideoBlock;
