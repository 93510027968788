import React, { useEffect, useRef, useState } from "react";
import * as Styles from "./styles";
import { TextBlock } from "../TextBlock/TextBlock";
import { FaMinus, FaPlus } from "react-icons/fa";

interface IAccordionProps {
  name: string;
  label: string;
  textContent?: any;
  subAccordionList?: any;
  topAccordionFontSize?: string[];
}

const Accordion = ({
  name,
  label,
  textContent,
  subAccordionList,
  topAccordionFontSize,
}: IAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accordionHeight, setAccordionHeight] = useState(0);
  const textRef = useRef(null);
  const listRef = useRef(null);
  return (
    <Styles.MainStyles $topAccordionFontSize={topAccordionFontSize || null}>
      <button
        onClick={() => {
          if (isOpen) {
            setAccordionHeight(0);
            setIsOpen(false);
          } else {
            setAccordionHeight(
              // @ts-ignore
              (textRef.current?.scrollHeight || 0) +
                // @ts-ignore
                (listRef.current?.scrollHeight || 0)
            );
            setIsOpen(true);
          }
        }}
        className="top-line"
      >
        <span>{label}</span>
        {isOpen ? <FaMinus className="icon" /> : <FaPlus className="icon" />}
      </button>
      <AccordionOpenSection accordionHeight={accordionHeight} isOpen={isOpen}>
        {textContent && (
          <div ref={textRef} className="text-content-container-top-level">
            <TextBlock {...textContent.fields} />
          </div>
        )}
        {subAccordionList && (
          <ul ref={listRef}>
            {subAccordionList.map((item, i) => (
              <SubAccordionItem
                accordionHeight={accordionHeight}
                setAccordionHeight={setAccordionHeight}
                item={item.fields}
                key={i}
              />
            ))}
          </ul>
        )}
      </AccordionOpenSection>
    </Styles.MainStyles>
  );
};

const AccordionOpenSection = ({
  children,
  isOpen,
  accordionHeight,
}: {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  accordionHeight: number;
}) => {
  const accordion = useRef(null);
  useEffect(() => {}, []);

  return (
    <Styles.TopLevelAccordion
      $accordionHeight={accordionHeight}
      ref={accordion}
      aria-expanded={!isOpen}
    >
      {children}
    </Styles.TopLevelAccordion>
  );
};

const SubAccordionItem = ({ item, accordionHeight, setAccordionHeight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subAccordionHeight, setSubAccordionHeight] = useState(0);
  const innerAccordion = useRef(null);
  return (
    <li>
      <button
        onClick={() => {
          if (isOpen) {
            setIsOpen(false);
            setSubAccordionHeight(0);
            setAccordionHeight(
              accordionHeight -
                // @ts-ignore
                (innerAccordion.current?.scrollHeight || 0) +
                300
            );
          } else if (!isOpen) {
            setSubAccordionHeight(
              // @ts-ignore
              (innerAccordion.current?.scrollHeight || 0) + 300
            );
            setAccordionHeight(
              accordionHeight +
                // @ts-ignore
                (innerAccordion.current?.scrollHeight || 0) +
                300
            );
            setIsOpen(true);
          }
        }}
        className="sub-accordion-top-line"
      >
        <span>{item.label}</span>

        {isOpen ? <FaMinus className="icon" /> : <FaPlus className="icon" />}
      </button>
      {item.textContent ? (
        // @ts-ignore
        <Styles.InnerAccordion
          $subAccordionHeight={subAccordionHeight}
          ref={innerAccordion}
          aria-expanded={!isOpen}
        >
          <TextBlock {...item.textContent.fields} />
        </Styles.InnerAccordion>
      ) : null}
    </li>
  );
};

export default Accordion;
