import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const ResourcesListWrapper = styled("section")<{
  $backgroundColor?: string;
}>`
  content-visibility: auto;
  contain-intrinsic-size: 0 500px;
  background: ${({ $backgroundColor }) => $backgroundColor || "#FFF"};
  padding: 100px 0;

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    padding: 60px 0;
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    > div > div > div:nth-child(4) {
    }
  }
`;

export const ResourcesListTitleWrapper = styled("div")<{ $textColor?: string }>`
  h3 {
    font-family: var(--primary-font-family), sans-serif;
    font-style: normal;
    font-weight: 700;
    color: ${({ $textColor }) => $textColor || "#000"};
    text-transform: uppercase;
    font-size: 34px;
    line-height: 32px;
    margin: 0;

    @media only screen and (${BREAK_POINTS.DESKTOP}) {
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 30px;
    }
  }

  a > p {
    text-align: left;
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    padding-bottom: 60px;
  }
`;

export const BlogPostPreviewCardWrapper = styled("div")<{
  $textColor?: string;
}>`
  color: ${({ $textColor }) => $textColor || "#000"};

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding-bottom: 60px;
  }
`;
