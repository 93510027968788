import { BREAK_POINTS } from "@/src/lib/breakPoints";
import React from "react";
import styled from "styled-components";

export const FilterWrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  border-bottom: 1px solid #d9d9d6;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 97;
  &:after,
  &:before {
    display: table;
    clear: both;
    content: " ";
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }
  h3 {
    width: 100%;
    padding: 20px 0;
    text-transform: uppercase;
    border-bottom: 1px solid #d9d9d6;
    font-weight: 700;
    font-size: 20px;
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      display: none;
    }
  }
  label {
    div {
      margin-right: 15px !important;
    }
  }
`;
export const SearchBoxHolder = styled.div`
  float: right;
  padding: 18px;
  padding-top: 14px;
  border-right: 1px solid #d9d9d6;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    background: #e7e7e7;
    border: 1px solid #e7e7e7;
    padding: 5px 10px;
  }
  label {
    display: inline-block;
    text-transform: uppercase;
    font-family: var(--primary-font-family), Arial, Helvetica, sans-serif;
    font-weight: 800;
    margin-right: 20px;
  }
  button {
    background-color: transparent;
    border: none;
    margin-left: 20px;
    .icon-close {
      margin: 0;
      top: 0;
    }
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    float: none;
    border-right: 0;
    justify-content: flex-start;
    padding-left: 36px;
  }
`;
export const CloseButton = styled.div`
  z-index: 9999;
  padding-top: 25px;
  position: absolute;
  right: 20px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%2017L17%201M17%2017L1%201%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E");
  @media only screen and (${BREAK_POINTS.DESKTOP}) {
    display: none;
  }
`;

const FilterHeader = styled.div`
  padding-left: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 32px;
  text-transform: uppercase;
  color: #000000;
  font-family: var(--primary-font-family), Arial, Helvetica, sans-serif;
  @media only screen and (${BREAK_POINTS.DESKTOP_MIN}) {
    display: none;
  }
`;

const OverlayLabel = styled.div`
  padding-left: 20px;
  margin-bottom: 15px;
  font-family: var(--primary-font-family), sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 23px;
  text-transform: uppercase;
  color: #000000;

  @media only screen and (${BREAK_POINTS.DESKTOP_MIN}) {
    display: none;
  }
`;
export const Blackbar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: #000;
  transition: width 0.2s;
  &.active {
    width: 100%;
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    height: 0px;
  }
`;

export const CheckBoxHolder = styled.div`
  display: inline-block;
  position: relative;
  outline: none;
  // Hacky as all hell, sorry if this causes frustration in the future
  select {
    option[value="date"] {
      display: none;
    }
  }
  hr {
    transform: translateX(-30px);
    width: 120%;
    margin-top: 20px;
    margin-bottom: 28px;

    @media only screen and (${BREAK_POINTS.DESKTOP_MIN}) {
      display: none;
    }
  }
  &:nth-of-type(1) {
    h5 {
      border-left: 1px solid #d9d9d9;
      @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
        border-left: none !important;
      }
      &.active {
        &:hover {
          border-left: 1px solid #000;
        }
      }
    }
  }
  h5 {
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      padding: 17px !important;
      border-right: none;
    }
    border-right: 1px solid #d9d9d9;
    &.active {
      &:hover {
        border-right: 1px solid #000;
        background: #000;
        color: #fff;
        @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
          border: none;
          background: #fff;
          color: #000;
        }
        & > .dropdown-arrow {
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M4.7%201.4a1%201%200%20011.6%200l3.9%206A1%201%200%20019.3%209H1.7a1%201%200%2001-.9-1.5l3.9-6.1z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E");
          @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6.3%207.6a1%201%200%2001-1.6%200l-3.9-6A1%201%200%20011.7%200h7.6c.8%200%201.3.9.9%201.5L6.3%207.6z%22%20fill%3D%22%23000%22/%3E%3C/svg%3E");
          }
        }
      }
    }
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      display: block;
      padding: 0;
      margin-left: 20px;
      border: none;
    }

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    outline: none;

    text-transform: uppercase;
    padding: 25px;
    display: inline-block;
    background: #fff;
    color: #000;
    &:hover {
      cursor: pointer;
      background: #000;
      color: #fff;

      & > .dropdown-arrow {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6.3%207.6a1%201%200%2001-1.6%200l-3.9-6A1%201%200%20011.7%200h7.6c.8%200%201.3.9.9%201.5L6.3%207.6z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E");
      }
    }
  }

  ul {
    position: absolute;
    top: 63px;
    width: 600px;
    padding: 12px 0 18px;
    margin: 0;
    outline: none;
    list-style: none;
    pointer-events: none;
    border: 1px solid #d9d9d6;
    border-top: none;
    background: #fff;
    overflow: scroll;
    display: grid;
    max-height: 0px;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    transition:
      max-height 0.2s,
      opacity 0.2s;
    opacity: 0;

    &:after {
      content: "";
      width: 100%;
      display: inline-block;
    }

    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      transform: translateY(100%);
      overflow: hidden;
      display: block;
      left: 0;
      max-width: 100%;
      padding-left: 21px;
      bottom: -100px;
      position: fixed;
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      overflow-y: scroll;
      opacity: 1;
      transition: transform 0.2s;
    }

    li {
      line-height: 1;
      display: block;
      @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
        width: 100%;
        &:first-of-type {
          margin-top: 15px;
        }
      }
    }
  }
  &.open {
    ul {
      max-height: 600px;
      opacity: 1;
      pointer-events: inherit;
      transition:
        max-height 0.2s 0s,
        opacity 0s 0s;

      @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
        padding-top: 62px;
        max-height: 1000px;
        bottom: 0;
        top: 0;
        z-index: 999;
        transform: translateY(0);
        transition: transform 0.2s;
      }
    }
  }
  .dropdown-arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-left: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6.3%207.6a1%201%200%2001-1.6%200l-3.9-6A1%201%200%20011.7%200h7.6c.8%200%201.3.9.9%201.5L6.3%207.6z%22%20fill%3D%22%23000%22/%3E%3C/svg%3E");

    &.active {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M4.7%201.4a1%201%200%20011.6%200l3.9%206A1%201%200%20019.3%209H1.7a1%201%200%2001-.9-1.5l3.9-6.1z%22%20fill%3D%22%23000%22/%3E%3C/svg%3E");
    }

    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      margin-left: 10px;
    }
  }
  strong {
    display: inline-block;
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: var(--primary-font-family), Arial, Helvetica, sans-serif;
  }
`;

export const ContentFilterStyles = styled.div`
  .sort-holder {
    padding-left: 36px;
  }
`;
