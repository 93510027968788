import React from "react";
import Markdown from "markdown-to-jsx";
import { Container, Row, Col } from "react-grid-system";

import * as Styles from "./CopyBlockStyles";
import CallToAction from "../CallToAction/CallToAction";

const CopyBlock = ({
  slug,
  headline,
  body,
  ctaText,
  ctaLink,
  textColor,
  backgroundColor,
  highlightColor,
  lang,
}) => {
  return (
    <Styles.CopyBlock
      id={slug}
      $backgroundColor={backgroundColor}
      $highlightColor={highlightColor}
      $textColor={textColor}
    >
      <Container>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            {headline && (
              <h2>
                <Markdown>{headline}</Markdown>
              </h2>
            )}
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            {body && (
              <p>
                <Markdown>{body}</Markdown>
              </p>
            )}
            {ctaText && (
              <CallToAction
                callToActionCopy={ctaText}
                iconColor={highlightColor}
                linkPath={ctaLink}
                iconImage="arrow"
                textColor={textColor}
                lang={lang}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Styles.CopyBlock>
  );
};

export default CopyBlock;
