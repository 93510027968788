import React from "react";

import {
  SecondaryNavbarStyles,
  CtaBrightcoveStyles,
} from "../SecondaryNavbarStyles";
import CtaBrightcove from "@/src/components/Contentful_Components/CTABrightcove";

interface ISecondaryNavbar {
  backgroundColor?: string;
  height?: string;
  displayLinks?: any;
  lang: string;
}

const ModelB = ({
  backgroundColor,
  displayLinks,
  height,
  lang,
}: ISecondaryNavbar) => {
  return (
    <SecondaryNavbarStyles $backgroundColor={backgroundColor} $height={height}>
      <div className="Model-B">
        {displayLinks &&
          displayLinks.map((card, index) => {
            return (
              <CtaBrightcoveStyles
                $textColor={card.fields.textColor}
                className="secondary-link"
                key={index}
              >
                <CtaBrightcove lang={lang} {...card.fields} />
              </CtaBrightcoveStyles>
            );
          })}
      </div>
    </SecondaryNavbarStyles>
  );
};

export default ModelB;
