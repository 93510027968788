import React from "react";

import SocialShareWidget from "./components/SocialShareWidget";
import PressReleaseList from "./components/PressReleaseList";
import PartnerList from "./components/PartnerList";

interface ISpecialComponentProps {
  componentName: "social-widget" | "press-release-list" | "partner-list";
  lang: string;
  translations: any;
}

const SpecialComponent = ({
  componentName,
  lang,
  translations,
}: ISpecialComponentProps) => {
  return (
    <section>
      {/* Map over list of components tied to specific string.
          Add new components here. */}
      {componentName === "social-widget" && <SocialShareWidget />}
      {componentName === "press-release-list" && (
        <PressReleaseList lang={lang} />
      )}
      {componentName === "partner-list" && (
        <PartnerList lang={lang} translations={translations} />
      )}
    </section>
  );
};

export default SpecialComponent;
