import styled from "styled-components";

const CustomersBlockSection = styled("section")<{
  $backgroundColor: string | undefined;
  $logoPosition: string | undefined;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  /* Headline */
  h4 {
    text-align: center;
    font-family: "articulat-cf";
    letter-spacing: 0px;
    font-size: 30px;
    font-weight: bold;
    margin: 60px 15%;
  }
  .title-customerblock {
    font-size: 53px;
  }

  /* Logos */
  .customer-logos {
    display: flex;
    width: 100%;
    justify-content: ${({ $logoPosition }) => $logoPosition || "space-around"};
    padding-bottom: 60px;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    a {
      display: block;
      margin-right: ${({ $logoPosition }) => ($logoPosition ? "10px" : "0px")};
      img {
        width: 100px;
        filter: grayscale(100%);
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .title-customerblock {
      font-size: 32px;
    }
  }

  /***************************** Customer Card ***************************************/
  /***********************************************************************************/
  .customer-cards {
    border: 1px solid #c7c7c7;
    background-color: #fff;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    /* Image */
    .wrapper-image {
      width: 41.5%;
      flex: 0 0 41.5%;
      max-width: 41.5%;
    }
    /* Text */
    .card-text {
      width: 58.28%;
      flex: 0 0 58.28%;
      max-width: 58.28%;
      padding: 30px 30px 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .body {
        font-family: "articulat-cf";
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0px;
        color: #000000;
      }
      .quote {
        .title {
          display: block;
          margin-top: 10px;
          font-family: "articulat-cf";
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0px;
          color: black;
        }
        .subtitle {
          font-family: "articulat-cf";
          font-size: 18px;
          letter-spacing: 0px;
          color: black;
        }
      }
      a {
        margin-top: 10px;
        font-weight: bold;
      }
      /* Wrapper CTA and logo */
      .cta-logo-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 0px 0px;
      }
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .customer-cards {
      .card-text {
        .cta-logo-wrapper {
          margin: 20px 0px;
        }
      }
    }
  }

  /***************************** customer-cards-Model-b ******************************/
  /***********************************************************************************/
  .col-style {
    display: flex;
  }
  .customer-cards-Model-b {
    border: 1px solid #c7c7c7;
    background-color: #fff;
    .wrapper-image {
      height: 193px;
      display: flex;
    }
    .card-text {
      padding: 30px 30px 10px 30px;
      background-color: #fff;
      .body {
        font-family: "articulat-cf";
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0px;
        color: #000000;
      }
      .quote {
        .title {
          display: block;
          margin-top: 10px;
          font-family: "articulat-cf";
          font-size: 18px;
          line-height: 1.3;
          font-weight: bold;
          letter-spacing: 0px;
          color: black;
        }
        .subtitle {
          font-family: "articulat-cf";
          font-size: 18px;
          line-height: 1.3;
          letter-spacing: 0px;
          color: black;
        }
      }
      .cta-logo-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 10px;
      }
      a {
        margin: 15px 0;
      }
    }
  }

  /* Pre-content */
  .wrapper-pre-content {
    margin: 60px auto 60px auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      width: 80%;
      margin: 0px auto;
      display: block;
      font-size: 25px;
      font-family: "articulat-cf";
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
    }
    .cta-content {
      margin-top: 30px;
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    h4 {
      margin: 60px 10%;
    }
    .customer-cards-Model-b {
      margin-bottom: 20px;
      .wrapper-image {
        height: auto;
        display: flex;
      }
    }
    .customer-cards {
      flex-direction: column;
      max-width: 683px;
      margin-left: auto;
      margin-right: auto;
      .wrapper-image {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
      }
      .card-text {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    h4 {
      margin: 60px 5%;
    }
    .wrapper-pre-content {
      h5 {
        width: 100%;
      }
    }
  }
`;
export default CustomersBlockSection;
