import React from "react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import { useLightboxData } from "@/src/context/lightboxContext";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import Link from "next/link";
import CallToAction from "../CallToAction/CallToAction";
import getLinkFromContent from "@/src/lib/getLinkFromContent";
import ContentfulImage from "@/src/lib/contentful-image";
import ContentCard from "../ContentCard/ContentCard";

const GoToMarketStyle = styled.div`
  color: black;
  .header {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Agrandir";
    margin: 40px 0 10px;
    font-size: 2rem;
  }
  .body {
    font-size: 1.2rem;
  }
  .call-to-action {
    margin-top: 20px;
    a {
      p {
        font-family: "articulat-cf" !important;
      }
    }
  }
`;
const buildLink = (item, lang, type) => {
  let path = "";
  let realType = "post";
  if (type) {
    realType = type;
  } else if (item && item.sys && item.sys.contentType.sys.id) {
    realType = item.sys.contentType.sys.id;
  } else if (item.customer) {
    realType = "story";
  } else {
    realType = "post";
  }

  switch (realType) {
    case "story":
      path =
        item.slug || (item.node && item.node.slug)
          ? `/${lang}/customers/${item.slug || item.node.slug}/`
          : `/${lang}/customers/${item.fields.slug}/`;
      break;
    case "card":
      path = item.link ? item.link : "";
      break;
    default:
      path =
        item && item.publishedAt ? `/${lang}/resources/blog/${item.slug}/` : "";
  }
  return path;
};
const parseImage = obj => {
  const item = obj;
  if (item.mainImageUrl) {
    return item.mainImageUrl;
  }

  if (item.fields.mainImageUrl) {
    return item.fields.mainImageUrl;
  }

  if (item.videoData) {
    return item.videoData.poster;
  }

  if (item.fields.mainImage) {
    return item.fields.mainImage;
  }

  return null;
};
const extractImgObj = obj => {
  const item = obj;
  if (item.mainImage) {
    return item.mainImage;
  }
  return null;

  return false;
};
const parseTitle = item => {
  let title = "";
  if (typeof item !== "undefined") {
    if (item.title) {
      title = item.title;
    } else if (item.node) {
      title = item.node.title;
    } else if (item.videoData) {
      title = item.videoData.name;
    } else if (item.fields.title) {
      title = item.fields.title;
    } else {
      title = item.name;
    }
  }

  return title;
};

const parseType = item => {
  let type = "";
  if (item.sys && item.sys.contentType && item.sys.contentType.sys.id) {
    type = item.sys.contentType.sys.id;
    return type;
  }
  return type;
};
const RelatedCards = props => {
  const { cardType, items } = props;
  const lightBoxData = useLightboxData();
  if (cardType === "Featured content") {
    return (
      <RowStyle gutter={"30"} justify="center">
        {items &&
          items.map(
            (item, index) =>
              typeof item !== "undefined" && (
                <ColStyle key={index} lg={5} md={6} sm={6}>
                  <Link prefetch href={buildLink(item, props.lang, props.type)}>
                    <ContentCard
                      title={parseTitle(item)}
                      span="4"
                      image={parseImage(item)}
                      imageObj={extractImgObj(item)}
                      small={item.date}
                      type={cardType}
                      label={item.label}
                      previewCopy={
                        item.body
                          ? item.body.childMarkdownRemark.html
                          : item.previewCopy
                      }
                      backgroundColor={item.highlightColor}
                      itemLength={items.length}
                    />
                  </Link>
                </ColStyle>
              )
          )}
      </RowStyle>
    );
  }
  if (cardType === "Square color blocks") {
    return (
      <RowStyle gutter={"10"} justify="center" align="stretch">
        {items &&
          items.map(
            (item, index) =>
              typeof item !== "undefined" && (
                <ColStyle key={index} lg={3} md={12} sm={12}>
                  <Link
                    prefetch
                    href={buildLink(item, props.lang.slice(0, 2), props.type)}
                  >
                    <ContentCard
                      title={parseTitle(item)}
                      span="3"
                      type={cardType}
                      label={item.label}
                      previewCopy={
                        item.body
                          ? item.body.childMarkdownRemark.html
                          : item.previewCopy
                      }
                      backgroundColor={item.highlightColor}
                    />
                  </Link>
                </ColStyle>
              )
          )}
      </RowStyle>
    );
  }

  if (cardType === "Go-to-market card") {
    const GoToMarketCard = ({ image, title, body, link, label }) => {
      return (
        <GoToMarketStyle>
          {image && (
            <ContentfulImage
              alt={image?.fields?.title || ""}
              width={image?.fields?.file?.details.image.width || 1000}
              height={200}
              quality={100}
              priority={true}
              src={image?.fields?.file?.url}
            />
          )}
          {title && <strong className="header">{title}</strong>}
          {body && <Markdown className="body">{body}</Markdown>}
          {link && label && (
            <CallToAction
              className="call-to-action"
              linkPath={link}
              callToActionCopy={label}
              lang={props.lang}
            />
          )}
        </GoToMarketStyle>
      );
    };
    return (
      <RowStyle gutter={"50"} justify="space-around">
        {items &&
          items.map(
            (item, index) =>
              typeof item !== "undefined" && (
                <ColStyle key={index} lg={4} md={12} sm={12}>
                  <Link
                    prefetch
                    href={buildLink(item, props.lang.slice(0, 2), props.type)}
                  >
                    <GoToMarketCard
                      title={item.title}
                      image={item.mainImage.gatsbyImageData}
                      body={item.body.childMarkdownRemark.html}
                      link={item.link}
                      label={item.label}
                    />
                  </Link>
                </ColStyle>
              )
          )}
      </RowStyle>
    );
  }
  return (
    <RowStyle
      gutter={cardType === "Color block" ? "7" : "30"}
      style={{
        marginBottom: cardType === "Product image block" ? "10px" : "unset",
        overflow: "hidden",
      }}
      justify={
        cardType === "Product image block" || cardType === "Featured content"
          ? "center"
          : "start"
      }
    >
      {items &&
        items.map((item, index) => {
          return (
            typeof item !== "undefined" && (
              <ColStyle
                key={index}
                lg={items.length === 4 ? 3 : 4}
                md={items.length === 4 ? 3 : 6}
                sm={12}
                className="related-cards-list"
              >
                {parseType(item) === "Webinar" ||
                parseType(item) === "video" ||
                parseType(item) === "brightcoveVideo" ? (
                  <a
                    onClick={e => {
                      e.preventDefault();
                      lightBoxData!.setLightBoxData({
                        open: true,
                        type: "video",
                        content: item.videoData.video_id,
                      });
                    }}
                    onKeyPress={e => {
                      e.preventDefault();
                      lightBoxData!.setLightBoxData({
                        open: true,
                        type: "video",
                        content: item.videoData.video_id,
                      });
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <ContentCard
                      title={parseTitle(item)}
                      span="4"
                      image={parseImage(item)}
                      imageObj={item.thumbnail}
                      small={item.type ? item.type : "Video"}
                      itemLength={items.length}
                    />
                  </a>
                ) : (
                  <Link
                    prefetch
                    href={
                      item.fields.contentCta
                        ? getLinkFromContent({
                            linkTo: item.fields.contentCta.fields.linkTo,
                            lang: props.lang,
                          })
                        : buildLink(item, props.lang.slice(0, 2), props.type)
                    }
                  >
                    <ContentCard
                      title={parseTitle(item)}
                      span="4"
                      image={parseImage(item)}
                      imageObj={extractImgObj(item)}
                      small={item.date}
                      darkImage={item.darkImage}
                      type={cardType}
                      label={item.label}
                      previewCopy={item.body ? item.body : item.previewCopy}
                      backgroundColor={item.highlightColor}
                      itemLength={items.length}
                    />
                  </Link>
                )}
              </ColStyle>
            )
          );
        })}
    </RowStyle>
  );
};
export default RelatedCards;
