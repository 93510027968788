import {
  defaultStyles,
  smallHeaderFontStyles,
  textContentFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleCallout = styled("section")<{
  $backgroundColor?: "Black" | "White" | "Grey";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $quoteExists: boolean;
  $assetExists: boolean;
  $alignment?: "Asset Left" | "Asset Right";
}>`
  ${({
    $quoteExists,
    $backgroundColor,
    $paddingTop,
    $paddingBottom,
    $alignment,
  }) =>
    defaultStyles({
      direction:
        $quoteExists || $alignment === "Asset Left" ? "row-reverse" : "row",
      gap: $quoteExists ? "30px" : "50px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })}

  .content-section {
    width: ${({ $quoteExists, $assetExists }) =>
      !$assetExists
        ? "1280px"
        : !$quoteExists
          ? "calc((1280px - 50px) / 2)"
          : "calc(1280px - 30px - 300px)"};
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
      ${smallHeaderFontStyles()}
      font-weight: 600;
    }

    > p {
      ${textContentFontStyles()}
      :not(:last-child) {
        padding-bottom: 20px;
      }
    }

    .quote {
      font-size: 33px;
      font-weight: 200;
      line-height: 45px;
      letter-spacing: 1.65px;

      .quotation {
        font-family: "Agrandir";
        font-weight: 800;
      }
    }

    .attribution-section {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .attribution-line {
        font-size: 18px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0.9px;
        text-transform: uppercase;
      }

      .job-title-line {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
      }

      .company-line {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
      }
    }
  }
  .asset-section {
    width: ${({ $quoteExists }) =>
      !$quoteExists ? "calc((1280px - 50px) / 2)" : "300px"};
  }

  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom, $quoteExists }) =>
      defaultStyles({
        gap: $quoteExists ? "20px" : "40px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
    .content-section {
      width: ${({ $quoteExists, $assetExists }) =>
        !$assetExists
          ? "100%"
          : !$quoteExists
            ? "calc((100% - 40px) / 2)"
            : "calc(1280px - 20px - 200px)"};
      gap: 20px;

      .title {
        ${smallHeaderFontStyles("Tablet")}
      }

      p {
        ${textContentFontStyles("Tablet")}
      }

      .quote {
        font-size: 30px;
        line-height: 42px;
        letter-spacing: 1.35px;
      }

      .attribution-section {
        gap: 3px;

        .attribution-line {
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.9px;
          text-transform: uppercase;
        }

        .job-title-line {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.75px;
        }

        .company-line {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.75px;
        }
      }
    }
    .asset-section {
      width: ${({ $quoteExists }) =>
        !$quoteExists ? "calc((100% - 40px) / 2)" : "200px"};
    }
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom, $quoteExists }) =>
      defaultStyles({
        direction: "column-reverse",
        gap: $quoteExists ? "10px" : "30px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Mobile",
      })}
    .content-section {
      width: 100%;
      gap: 10px;

      .title {
        ${smallHeaderFontStyles("Mobile")}
      }

      p {
        ${textContentFontStyles("Mobile")}
      }

      .quote {
        font-size: 26px;
        line-height: 38px;
        letter-spacing: 1.15px;
      }

      .attribution-section {
        gap: 1px;

        .attribution-line {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.7px;
        }

        .job-title-line {
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.55px;
        }

        .company-line {
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.55px;
        }
      }
    }
    .asset-section {
      width: 100%;
    }
  }
`;
