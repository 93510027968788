import React from "react";
import * as Styles from "./TextBlockStyles";
import Remark from "@/src/components/Global_Components/Remark";
import CtaBrightcove from "../CTABrightcove";

interface ITextBlock {
  title: string;
  header?: string;
  body?: string;
  containerGrid: string;
  backgroundColor?: string;
  padding?: string;
  className?: string;
  multipleCta?: any[];
  lang: string;
}

export const TextBlock = (textBlock: ITextBlock) => {
  const {
    header,
    body,
    containerGrid,
    backgroundColor,
    padding,
    className,
    multipleCta,
    lang,
  } = textBlock;
  return (
    <Styles.TextBlockStyles
      $containerGrid={containerGrid}
      $backgroundColor={backgroundColor}
      $padding={padding}
    >
      <div className={`container-text-block ${className}`}>
        {header && (
          <div className="header-text-block">
            <Remark string={header} />
          </div>
        )}
        {body && (
          <div className="body-text-block">
            <Remark string={body} />
            {multipleCta?.map((cta, i) => {
              return <CtaBrightcove key={i} lang={lang} {...cta.fields} />;
            })}
          </div>
        )}
      </div>
    </Styles.TextBlockStyles>
  );
};
