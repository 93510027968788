"use client";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Styles from "./styles";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Link from "next/link";
import Icon from "@/src/components/Global_Components/Icon/Icon";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import Spinner from "@/src/components/Global_Components/Spinner/Spinner";
import ContentFilter from "@/src/components/Global_Components/ContentFilter/ContentFilter";
import { useFilteredContent } from "@/src/context/filteredContentContext";
import ContentfulImage from "@/src/lib/contentful-image";

const CustomersWrap = styled.section`
  width: 100%;
  .card {
    min-height: 500px;
  }
  .nothing-found {
    padding: 100px;
    font-size: 1.2rem;
    a {
      border-bottom: 2px solid rgb(0, 234, 228);
      &:hover {
        font-weight: bold;
      }
    }
  }
`;

const CustomerItem = styled.div`
  border: 1px solid #e3e3e3;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  height: 100%;
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-family: "articulat-cf", sans-serif;
    margin-bottom: 20px;
  }
  .logo-wrap {
    max-height: 60px;
    display: flex;
    align-items: center;
    .lazyload-wrapper {
      width: calc(100% - 34px);
      display: inline-block;
    }
    picture > img {
      object-position: left;
      height: 60px;
    }
    span {
      float: right;
    }
  }
  padding: 0 40px 20px 40px;
  span {
    top: 0;
  }
`;
const CustomerImage = styled.div`
  padding: 40px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentList = ({ lang }) => {
  const { filteredContent } = useFilteredContent();
  return filteredContent.length > 0 ? (
    filteredContent &&
      filteredContent.map(data => {
        if (data.slug && data.name) {
          const { name } = data;
          const blurb = data.tagline;
          const { capabilities } = data;
          const slug = `/${lang}/partners/${data.slug}`;
          return (
            <ColStyle
              key={`content_item_${data.slug}`}
              //   lg={4}
              //   md={4}
              sm={12}
              align="stretch"
              className="partner-item"
              style={{
                marginBottom: `10px`,
                paddingLeft: 0,
                paddingRight: 0,
                width: "calc((1280px - 60px) / 3)",
              }}
            >
              <Link prefetch href={slug}>
                <CustomerItem>
                  <CustomerImage>
                    {data.logoUrl && !data.logo && !data.mainImage && (
                      //eslint-disable-next-line
                      <img
                        src={data.logoUrl}
                        alt={data.name}
                        style={{
                          maxWidth: "400px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {data.logo && (
                      <ContentfulImage
                        className="main-image"
                        alt={data.logo?.fields?.title || ""}
                        width={
                          data.logo?.fields?.file?.details.image.width || 1000
                        }
                        height={
                          data.logo?.fields?.file?.details.image.height || 1000
                        }
                        quality={80}
                        priority={true}
                        src={data.logo?.fields?.file?.url}
                      />
                    )}
                  </CustomerImage>
                  <div>
                    {name && <h5>{name}</h5>}
                    {capabilities && (
                      <strong
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "500",
                          margin: "10px 0",
                          display: "inline-block",
                          color: "#7D7D7D",
                        }}
                      >
                        {capabilities.slice(0, 3).join(", ")}
                      </strong>
                    )}
                    {blurb && <p>{blurb}</p>}
                  </div>
                  <Icon iconColor="#000" iconImage="arrow" className={""} />
                </CustomerItem>
              </Link>
            </ColStyle>
          );
        }
        return null;
      })
  ) : (
    <ColStyle lg={12} md={12} sm={12} className="nothing-found">
      <strong>Not what you&apos;re looking for?</strong>
      <br />
      Check out our{" "}
      <a href="https://marketplace.brightcove.com">Partner Marketplace</a> for
      more.
    </ColStyle>
  );
};

const PartnerList = ({ lang, translations }) => {
  const [partners, setPartners] = useState<
    {
      name: string;
      slug: string;
      logo: {
        fields: {
          title: string;
          description: string;
          file: {
            url: string;
            details: {
              size: number;
              image: {
                width: number;
                height: number;
              };
            };
            fileName: string;
            contentType: string;
          };
        };
      };
      url: string;
      tagline: string;
      body: string;
      types: string[];
      capabilities: string[];
      products: [
        {
          fields: {
            title: string;
            name: string;
            slug: string;
            logo: {
              fields: {
                title: string;
                file: {
                  url: string;
                  details: {
                    size: 3014;
                    image: {
                      width: 543;
                      height: 136;
                    };
                  };
                  fileName: string;
                  contentType: string;
                };
              };
            };
          };
        },
      ];
      regions: string[];
      contacts: string;
      resources: string;
      solution: string[];
      industries: string[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`/api/get-partner-list?lang=${lang}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => {
        setPartners(data.filter(partner => partner.slug));
        setIsLoading(false);
      });
    //eslint-disable-next-line
  }, []);

  let regions: string[] = [];
  const products: string[] = [];
  let solutions: string[] = [];
  const industries: string[] = [];
  partners.forEach(partner => {
    if (partner && partner.regions) {
      if (partner.products) {
        partner.products.forEach(prod => {
          products.push(prod.fields.name);
        });
      }
      // products = products.concat(partner.node.products)
      regions = regions.concat(partner.regions);
      if (partner.solution) {
        solutions = solutions.concat(partner.solution);
      }
    }
    if (partner && partner.industries) {
      partner.industries.forEach(industry => industries.push(industry));
    }
  });
  let filtered = false;

  if (!filtered) {
    filtered = true;
  }
  // @ts-ignore
  const regionsList = [...new Set(regions)];
  // @ts-ignore
  const productsList = [...new Set(products)];
  // @ts-ignore
  const solutionsList = [...new Set(solutions)];
  // @ts-ignore
  const industriesList = [...new Set(industries)];
  const filterConfig = [
    {
      label: translations["Regions"],
      type: "checkbox",
      values: regionsList,
    },
    {
      label: translations["Products"],
      type: "checkbox",
      values: productsList,
    },
    {
      label: translations["Use video to..."],
      type: "checkbox",
      values: solutionsList,
    },
    {
      label: translations["Industry"],
      type: "checkbox",
      values: industriesList,
    },
  ];
  if (isLoading) return <Spinner color="#000" width="100px" height="100px" />;
  return (
    <Styles.PartnerListWrapper>
      {" "}
      <ContentFilter
        title={translations["Filter Partners By"]}
        items={partners}
        filters={filterConfig}
        className="partner-list-filters"
      />
      <CustomersWrap>
        <ContainerStyle>
          <RowStyle style={{ gap: "30px", marginBottom: "50px" }}>
            {/* {partners.length} */}
            {/* @ts-ignore */}
            <ContentList lang={lang} />
          </RowStyle>
          {/* LOAD MORE ASYNC <Row>
            <button onClick={fetchCustomers}>Load More</button>
          </Row> */}
        </ContainerStyle>
      </CustomersWrap>
    </Styles.PartnerListWrapper>
  );
};

export default PartnerList;
