import React from "react";

import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import * as Styles from "./CenteredCopyContainerStyles";
import CallToAction from "../CallToAction/CallToAction";

// Style for chevron
const Chevron = styled("div")<{ $backgroundColor?: string }>`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  .svg-triangle {
    display: inline-block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  .svg-triangle polygon {
    fill: ${({ $backgroundColor }) => $backgroundColor};
    stroke: ${({ $backgroundColor }) => $backgroundColor};
  }
`;

const CenteredCopyContainer = ({
  slug,
  backgroundColor,
  textColor,
  ctaText,
  ctaLink,
  ctaType,
  formOptions,
  secondaryCtaLink,
  secondaryCtaText,
  secondaryCtaType,
  linkFunction,
  highlightColor,
  body,
  primaryTitle,
  secondaryTitle,
  lightboxData,
  arrows,
  size,
  afterShape,
  marginTop,
  lang,
  buttonStyle,
  backgroundButton,
  colorTextButton,
}) => {
  return (
    <>
      <Styles.CenteredCopyWrapper
        id={slug}
        $backgroundColor={backgroundColor}
        lang={lang}
        $size={size}
        $marginTop={marginTop}
      >
        <Styles.CenteredCopyInner>
          <Styles.CenteredCopyTitleWrapper
            $textHighlight={highlightColor}
            $textColor={highlightColor}
            lang={lang}
          >
            {!!primaryTitle && (
              <div className="centered-copy-wrapper-header">
                <Markdown>{primaryTitle}</Markdown>
              </div>
            )}
            {!!secondaryTitle && typeof secondaryTitle !== "undefined" && (
              <p>
                <Markdown>{secondaryTitle}</Markdown>
              </p>
            )}
          </Styles.CenteredCopyTitleWrapper>
          {!!body && (
            <Styles.CenteredCopyBody lang={lang} $textColor={textColor}>
              <Markdown>{body}</Markdown>
            </Styles.CenteredCopyBody>
          )}
          <Styles.CenteredCopyCTAWrap lang={lang}>
            {ctaText && ctaText !== "" && (
              <CallToAction
                id="footer-primary-cta"
                callToActionCopy={ctaText}
                linkPath={ctaLink}
                linkFunction={linkFunction}
                iconColor={textColor}
                iconImage={ctaType || "arrow"}
                textColor={textColor}
                backgroundColor={backgroundColor}
                buttonStyle={buttonStyle}
                backgroundButton={backgroundButton}
                colorTextButton={colorTextButton}
                lightboxData={lightboxData}
                lang={lang.slice(0, 2)}
              />
            )}
            {!!secondaryCtaLink && (
              <CallToAction
                id="footer-secondary-cta"
                callToActionCopy={secondaryCtaText}
                linkPath={secondaryCtaLink}
                iconColor={highlightColor}
                iconImage={secondaryCtaType}
                textColor={textColor}
                buttonStyle={buttonStyle}
                backgroundColor={backgroundColor}
                backgroundButton={backgroundButton}
                colorTextButton={colorTextButton}
                lang={lang.slice(0, 2)}
              />
            )}
          </Styles.CenteredCopyCTAWrap>
        </Styles.CenteredCopyInner>
      </Styles.CenteredCopyWrapper>
      {/* Style for chevron or wave */}
      {afterShape === "chevron" ? (
        <Chevron $backgroundColor={backgroundColor}>
          <svg xmlns="http://www.w3.org/2000/svg" className="svg-triangle">
            <polygon points="0,0 100,0 50,40" />
          </svg>
        </Chevron>
      ) : (
        ""
      )}
      {afterShape === "wave" ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill={backgroundColor}
            fillOpacity="1"
            d="M0,96L48,101.3C96,107,192,117,288,128C384,139,480,149,576,138.7C672,128,768,96,864,112C960,128,1056,192,1152,192C1248,192,1344,128,1392,96L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          />
        </svg>
      ) : (
        ""
      )}
    </>
  );
};
export default CenteredCopyContainer;
