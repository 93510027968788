import React from "react";
// Styles
import PlaySeasonStyles from "../PlaySeasonStyles";
import Link from "next/link";
import ContentfulImage from "@/src/lib/contentful-image";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

interface ModelBProps {
  header?: string;
  body?: string;
  type?: string;
  backgroundColor?: string;
  contentCta?: any;
  blockLogo?: any;
  lang: string;
}

const ModelB = ({
  body,
  backgroundColor,
  contentCta,
  blockLogo,
  header,
  lang,
}: ModelBProps) => {
  // Function Check if internal or external Link
  const DisplayLink = card => {
    if (!card.clickableItem) {
      return <PlayLogo card={card} />;
    } else if (card.clickableItem?.includes("https")) {
      return (
        <a href={card.clickableItem} target="_blank">
          <PlayLogo card={card} />
        </a>
      );
    } else {
      return (
        <Link prefetch href={`/${lang}/${card.clickableItem}`}>
          <PlayLogo card={card} />
        </Link>
      );
    }
  };

  // Logo
  const PlayLogo = ({ card }) => {
    return (
      <div className="logo">
        {card.fields.image && (
          <ContentfulImage
            alt={card.fields.image.fields.title || ""}
            width={card.fields.image.fields.file?.details.image.width || 1000}
            height={card.fields.image.fields.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={card.fields.image.fields.file?.url}
          />
        )}
      </div>
    );
  };

  return (
    <PlaySeasonStyles $backgroundColor={backgroundColor}>
      <ContainerStyle fluid="maxFluid">
        <RowStyle className="row-model-B">
          <ColStyle lg={5} md={12} sm={12} gutter={"60"} className="col-left">
            <div className="wrapper-text">
              {header && (
                <div className="header">
                  <Markdown>{header}</Markdown>
                </div>
              )}
              {body && (
                <div className="body">
                  <Markdown>{body}</Markdown>
                </div>
              )}
              {contentCta && (
                <div className="button-play">
                  <ContentCTA contentCta={{ ...contentCta, lang }} />
                </div>
              )}
            </div>
          </ColStyle>
          <ColStyle lg={7} md={12} sm={12} gutter={"0"}>
            <div className="block-right">
              <RowStyle>
                {blockLogo &&
                  blockLogo.map((card, i) => {
                    return (
                      <ColStyle key={i} lg={4} md={4} sm={6} gutter={"0"}>
                        <div className="wrapper-logo-play-season">
                          {DisplayLink(card)}
                        </div>
                      </ColStyle>
                    );
                  })}
              </RowStyle>
            </div>
          </ColStyle>
        </RowStyle>
      </ContainerStyle>
    </PlaySeasonStyles>
  );
};
export default ModelB;
