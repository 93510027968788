import styled from "styled-components";

const PlaySeason = styled("div")<{
  $backgroundColor?: string;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  color: #fff;
  /************************************** Model A **********************************************/
  /*********************************************************************************************/
  /* Row */
  .row-model-A {
    height: 100%;
    justify-content: center;
    flex-direction: "row";
    display: flex;
    .wrapper-text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 60px 0px;
      .body {
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
      }
      .button-play {
        margin-top: 30px;
        .button-rounded-corner {
          background: linear-gradient(
            to right,
            #870a5a 15%,
            #2e89bc 60%,
            #00eae4 90%
          );
          border: none;
        }
      }
    }

    /* Block right */
    .block-right {
      margin: 60px 0px;
      padding: 0px 60px;
      max-width: 100%;
      border-left-width: 3px;
      border-style: solid;
      border-image: linear-gradient(
          to top,
          #870a5a 15%,
          #2e89bc 60%,
          #00eae4 90%
        )
        0 0 0 100%;
    }

    .bodyRight {
      h3 {
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 300;
        font-size: 110px;
        line-height: 1;
        .span {
          font-weight: bold;
        }
      }
      p {
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
      }
    }
  }
  /* Big Tablet:  max-width: 1280px */
  @media only screen and (max-width: 1280px) {
    .row-model-A {
      .wrapper-text {
        margin-left: inherit;
        margin-right: inherit;

        .header {
          font-size: 45px;
        }
        .logo {
          width: 175px;
          height: auto;
        }
        .body {
          font-size: 25px;
          img {
            width: 135px;
            height: auto;
          }
        }
      }
      /* Right Part */
      .block-right {
        margin: 0px 0px 60px 0px;
        border: none;
        .bodyRight {
          h3 {
            font-size: 45px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
    /* Logo */
    .wrapper-logo-play-season {
      .logo {
        img {
          object-fit: contain;
          opacity: 1;
          width: 220px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  /* Big Tablet:  max-width: 769px */
  @media only screen and (max-width: 769px) {
    .row-model-A {
      /* left part */
      .col-left {
        padding: 0px 30px;
      }
      .wrapper-text {
        padding: 30px 0px;
        .logo {
          width: 125px;
          height: auto;
        }
        .header {
          font-size: 35px;
        }
        .body {
          font-size: 20px;
        }
      }
      /* Right Part */
      .block-right {
        padding: 0px 30px;
        margin: 30px 0px;
        border: none;
      }
      .bodyRight {
        margin: 0px;
        h3 {
          font-size: 35px;
        }
        p {
          font-size: 25px;
        }
      }
      /* Logo */
      .wrapper-logo-play-season {
        .logo {
          img {
            object-fit: cover;
            opacity: 1;
            width: 100%;
          }
        }
      }
    }
  }
  /************************************** Model B **********************************************/
  /*********************************************************************************************/
  /* Row */
  .row-model-B {
    height: 100%;
    justify-content: center;
    flex-direction: "row";
    display: flex;
    padding: 40px 0px;
    .wrapper-text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 60px 0px;
      .header,
      .header span {
        font-family: "Agrandir", "sans-serif";
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        text-transform: uppercase;
        color: black;
        line-height: 0.8;
      }
      .body {
        margin-top: 20px;
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        color: black;
        line-height: 1.3;
      }
      .button-play {
        margin-top: 30px;
        .button-rounded-corner {
          font-size: 15px;
          border-radius: 50px;
          padding: 15px 30px;
        }
      }
    }

    /* Block right */
    .block-right {
      margin: 60px 0px;
      padding: 0px 60px;
      max-width: 100%;
      border-left-width: 2px;
      border-style: solid;
      border-image: linear-gradient(
          to bottom,
          #4ea9d1,
          #aa6c7e,
          #f76531,
          #efd900
        )
        0 0 0 100%;
    }

    .bodyRight {
      h3 {
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 300;
        font-size: 110px;
        line-height: 1;
        .span {
          font-weight: bold;
        }
      }
      p {
        font-family: "articulat-cf", "sans-serif";
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
      }
    }
  }
  /* Big Tablet:  max-width: 1280px */
  @media only screen and (max-width: 1280px) {
    .row-model-B {
      .wrapper-text {
        margin-left: inherit;
        margin-right: inherit;
        .header,
        .header span {
          font-size: 35px;
        }
        .body {
          font-size: 25px;
          img {
            width: 135px;
            height: auto;
          }
        }
      }
      /* Block right */
      .block-right {
        margin: 60px 0px;
        padding: 0px 60px;
        max-width: 100%;
        border: none;
      }
      /* Logo */
      .wrapper-logo-play-season .logo img {
        width: 100%;
      }
    }
  }

  /* Big Tablet:  max-width: 769px */
  @media only screen and (max-width: 769px) {
    .row-model-B {
      /* left part */
      .col-left {
        padding: 0px 30px;
      }
      .wrapper-text {
        padding: 0px 0px 40px 0px;
        text-align: center;
        background: /* gradient can be an image */ linear-gradient(
            to left,
            #4ea9d1,
            #aa6c7e,
            #f76531,
            #efd900
          )
          left bottom ${({ $backgroundColor }) => $backgroundColor || "#fff"}
          no-repeat;
        background-size: 100% 2px; /* if linear-gradient, we need to resize it */
        .header,
        .header span {
          font-size: 25px;
        }
        .body {
          font-size: 20px;
        }
        .button-play {
          .button-rounded-corner {
            padding: 8px 30px;
          }
        }
      }
    }
  }
`;

export default PlaySeason;
