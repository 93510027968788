"use client";
import React from "react";

interface FilteredContentType {
  filteredContent: any;
  setFilteredContent: React.Dispatch<React.SetStateAction<any>>;
}

const FilteredContent = React.createContext<FilteredContentType>({
  filteredContent: [],
  setFilteredContent: () => [],
});

type Props = {
  children: React.ReactNode;
};

export const FilteredContentProvider = ({ children }: Props) => {
  const [filteredContent, setFilteredContent] = React.useState([]);

  return (
    <FilteredContent.Provider value={{ filteredContent, setFilteredContent }}>
      {children}
    </FilteredContent.Provider>
  );
};

export const useFilteredContent = () => React.useContext(FilteredContent);
