"use client";
import React, { useRef, useEffect, useState } from "react";

import * as Styles from "./ResourceContainerStyles";
import { FilterStyles } from "./Filter/FilterStyles";

import FilterBar from "./Filter/FilterBar";
import { useOutsideClick } from "./Filter/useOutsideClick";

import { Pagination } from "./Pagination/Pagination";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import Icon from "../../Global_Components/Icon/Icon";

const ResourceContainer = props => {
  const {
    header,
    backgroundColor,
    textColor,
    resourceCard,
    translations,
    wave,
    waveColor,
    lang,
  } = props;
  const [filterContent, setFilterContent] = useState([]); // list of filter per type
  const [isLoad, setIsLoad] = useState(false);

  const [Allcards, setAllCards] = useState([]);

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const { search } = window.location;
      const re = /\[(.*?)\]/g;
      const str = search;
      const myArray = str.match(re);
      const preFilter = [
        { type: "type", value: [] },
        { type: "tags", value: [] },
      ];
      // // for each categories add to papafilter
      if (myArray) {
        for (let i = 0; i < myArray.length; i++) {
          preFilter.some(({ type, value }) => {
            // check whether the value we want to filter by is an arry like tags
            if (myArray[i].includes(type)) {
              // Remove all characters before the '=' and remove '%20'  and push to prefilter Array
              // @ts-ignore
              const matche = myArray[i]
                .split("=")
                .pop()
                .trim()
                .replace(/%20/g, " ");
              const neWmatche = matche.replace("]", "").replace("[", "");
              // @ts-ignore
              value.push(neWmatche);
            }
          });
        }
        setPapaFilter(preFilter);
      }
    }
    setIsLoad(true);
    // @ts-ignore
    setAllCards(
      props.resourceCard.map(card => {
        if (card.sys.contentType.sys.id === "videoLandingPage") {
          return {
            header: card.fields.cardHeader,
            type: "Video",
            image: card.fields.cardImage,
            body: card.fields.cardBody,
            ctaLabel: card.fields.cardCtaLabel,
            ...card.fields,
          };
        } else if (card.sys.contentType.sys.id === "productPage") {
          return {
            header: card.fields.cardHeader,
            type: card.fields.cardType,
            image: card.fields.cardImage,
            body: card.fields.cardBody,
            ctaLabel: card.fields.cardCtaLabel,
            ...card.fields,
          };
        } else if (card.sys.contentType.sys.id === "downloadPage") {
          return {
            header: card.fields.cardHeader,
            type:
              card.fields.downloadType === "Whitepapers"
                ? "White Paper"
                : card.fields.downloadType === "Guides"
                  ? "Guide"
                  : card.fields.downloadType === "Reports"
                    ? "Report"
                    : "Study",
            image: card.fields.cardImage,
            body: card.fields.cardBody,
            ctaLabel: card.fields.cardCtaLabel,
            ...card.fields,
          };
        } else {
          return card;
        }
      })
    );
    //eslint-disable-next-line
  }, []);
  // data for EventCards

  //  PapaFilter
  const [papaFilter, setPapaFilter] = useState([
    { type: "type", value: [] },
    { type: "tags", value: [] },
  ]);
  // Search
  const [search, setSearch] = useState("");

  //  scroll to top filter fter click on pagination
  const topCard = useRef(null);

  // Function to get rid off the dropdown-content if open outside filter.
  const ref = useRef();
  useOutsideClick(ref, () => {
    // Remove Content-dropdown
    const allShowClass = document.getElementsByClassName("show");
    while (allShowClass.length) allShowClass[0].classList.remove("show");
    // check all class arrow-up and remove it.
    const allArrowUp = document.getElementsByClassName("arrow-up");
    while (allArrowUp.length) allArrowUp[0].classList.remove("arrow-up");
  });

  /// Method to filter card with papaFilter and return finalArray ///
  const filterCard = (items, papaFilter, translations) => {
    // If Search Filter
    if (search.length > 0) {
      items = Allcards.filter(item => {
        return JSON.stringify(item)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    const filteredArr = papaFilter.filter(obj => obj.value != "");
    // return the result of ALL card based on the filter

    const result = items.filter(({ fields }) =>
      filteredArr.every(({ type, value }) => {
        // check whether the value we want to filter by is an arry like tags
        if (Array.isArray(fields[type])) {
          // yes, we are dealing with an array => check if any of the filter criteria are matched
          // if you want to check for all criteria use "every()" instead of "some"
          return fields[type].some(valueInArray =>
            value.includes(valueInArray.fields.name)
          );
        }
        // if we are not dealing with an array we need to just check one value like type
        return value.includes(fields[type]);
      })
    );
    // return Method
    return (
      <>
        <br />
        <ContainerStyle>
          <RowStyle>
            <ColStyle lg={12}>
              {isLoad && (
                <div className="result">
                  <h3>
                    {translations.Result}:{" "}
                    <span className="value-result">{result.length}</span>
                  </h3>
                  {papaFilter &&
                    papaFilter.map((select, i) => {
                      return (
                        <h3 key={i}>
                          {select.type}:{" "}
                          <span className="value-result">
                            {select.value.length > 0
                              ? select.value.join(", ")
                              : "All"}
                          </span>
                        </h3>
                      );
                    })}
                </div>
              )}
            </ColStyle>
          </RowStyle>
        </ContainerStyle>
        <Pagination
          topCard={topCard}
          translations={translations}
          lang={props.lang}
          items={result}
          tagOnClick={tagOnClick}
        />
      </>
    );
  };
  // function Scroll to top element on click
  const scrollToBottom = () => {
    // @ts-ignore
    topCard.current?.scrollIntoView({ behavior: "smooth" });
  };

  const tagOnClick = name => {
    // Set papaFilter and filterContent
    const data = papaFilter.map(item => {
      // @ts-ignore
      if (item.type === "tags" && !item.value.includes(name)) {
        // @ts-ignore
        if (!filterContent.includes(name)) setFilterContent([name]);
        const updateFilter = [name]; // add new category Selected
        // @ts-ignore
        item.value = updateFilter;
        return item;
      }
      if (item.type === "type") {
        item.value = [];
        return item;
      }
      return item;
    });
    setPapaFilter(data);
    // @ts-ignore
    setFilterContent([name]);
    scrollToBottom();
  };

  return (
    <Styles.ResourceContainer
      $backgroundColor={backgroundColor}
      $textColor={textColor}
    >
      {/* Wave */}
      {wave && (
        <div className="wave">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill={waveColor || "#fff"}
              fillOpacity="1"
              d="M0,256L60,229.3C120,203,240,149,360,122.7C480,96,600,96,720,128C840,160,960,224,1080,250.7C1200,277,1320,267,1380,261.3L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            />
          </svg>
        </div>
      )}
      <ContainerStyle>
        <RowStyle>
          {/* Header */}
          {header && (
            <div ref={topCard} className="header">
              <Markdown>{header}</Markdown>
            </div>
          )}
        </RowStyle>
        {/* Filter and Card Display IF resourceCard */}
        {resourceCard && (
          <>
            <FilterStyles>
              <div className="filter-wrapper">
                {/* @ts-ignore */}
                <div ref={ref} className="filter-type">
                  <FilterBar
                    Allcards={resourceCard}
                    types={["type", "tags"]}
                    setPapaFilter={setPapaFilter}
                    papaFilter={papaFilter}
                    translations={translations}
                    filterContent={filterContent}
                    setFilterContent={setFilterContent}
                  />
                </div>
                {/* Search */}
                <div className="filter-search">
                  <label htmlFor="contentsearch">{translations.Search}</label>
                  <input
                    type="text"
                    className="textbox"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    name="contentsearch"
                  />
                  {search && search.length > 0 && (
                    <button onClick={() => setSearch("")}>
                      <Icon iconImage="close" iconColor={""} className={""} />
                    </button>
                  )}
                </div>
              </div>
            </FilterStyles>
            <RowStyle>
              <ContainerStyle>
                {filterCard(Allcards, papaFilter, translations)}
              </ContainerStyle>
            </RowStyle>
          </>
        )}
      </ContainerStyle>
    </Styles.ResourceContainer>
  );
};

export default ResourceContainer;
