import React from "react";

import { Container, Row, Col } from "react-grid-system";

import * as Styles from "./ResourcesListStyles";
import CallToAction from "../CallToAction/CallToAction";
import BlogPostPreviewCard from "../BlogPostPreviewCard/BlogPostPreviewCard";

const ResourcesList = ({
  slug,
  title,
  blogPosts,
  textColor,
  backgroundColor,
  ctaText,
  ctaLink,
  lang,
}) => {
  return (
    <Styles.ResourcesListWrapper id={slug} $backgroundColor={backgroundColor}>
      <Container>
        <Row>
          <Col lg={3} md={12} sm={12} xs={12}>
            <Styles.ResourcesListTitleWrapper $textColor={textColor}>
              <h3>{title}</h3>
              {ctaText && ctaText !== "" && (
                <CallToAction
                  textColor={textColor}
                  iconColor={textColor}
                  callToActionCopy={ctaText}
                  linkPath={ctaLink}
                  lang={lang}
                />
              )}
            </Styles.ResourcesListTitleWrapper>
          </Col>
          {blogPosts.map(blogPostItem => (
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={`blog-card-${Math.random()}`}
            >
              <Styles.BlogPostPreviewCardWrapper $textColor={textColor}>
                <BlogPostPreviewCard
                  blogPostType={blogPostItem.blogPostType || "Partner"}
                  callToActionCopy={blogPostItem.callToActionCopy}
                  callToActionLink={
                    blogPostItem.video_id ? "#" : blogPostItem.link
                  }
                  imageDescription={blogPostItem.imageDescription}
                  image={blogPostItem.image || blogPostItem.mainImage}
                  imageSource={
                    blogPostItem.imageSource || blogPostItem.mainImage
                  }
                  previewCopy={blogPostItem.previewCopy}
                  title={blogPostItem.title}
                  textColor={textColor}
                  videoId={blogPostItem.video_id}
                />
              </Styles.BlogPostPreviewCardWrapper>
            </Col>
          ))}
        </Row>
      </Container>
    </Styles.ResourcesListWrapper>
  );
};

export default ResourcesList;
