"use client";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import * as Styles from "./ContentFilterStyles";
import FilterHolder from "./FilterHolder";
import Icon from "../Icon/Icon";
import { useFilteredContent } from "@/src/context/filteredContentContext";

const ContentFilter = ({
  title = "Filter Items",
  items = [{}],
  filters = [{}],
  sortDefault = "newest",
  showSort = true,
  eventsList = false,
  eventsArrays = [],
  className,
}) => {
  const { filteredContent, setFilteredContent } = useFilteredContent();
  const [setItems] = useState(items);
  const [search, setSearch] = useState("");
  const [sortMode, setSort] = useState(sortDefault || "newest");
  const [filterTerms, setFilterTerms] = useState([]);
  const [whenTerms, setWhenTerms] = useState(["Upcoming"]);

  const sortContent = toSort => {
    if (toSort && toSort.length > 0) {
      switch (sortMode) {
        case "A-Z":
          toSort.sort((a, b) => {
            //
            const stringA = a.customer ? a.customer.name : a.name;
            const stringB = b.customer ? b.customer.name : b.name;
            if (
              stringA &&
              stringB &&
              stringA.toLowerCase() > stringB.toLowerCase()
            ) {
              return 1;
            }
            return -1;
          });
          break;
        case "Z-A":
          toSort.sort((a, b) => {
            const stringA = a.customer ? a.customer.name : a.name;
            const stringB = b.customer ? b.customer.name : b.name;
            if (
              stringA &&
              stringB &&
              stringB.toLowerCase() > stringA.toLowerCase()
            ) {
              return 1;
            }
            return -1;
          });
          break;
        case "newest":
          toSort.sort((a, b) => {
            const dateA = new Date(a.startAt || a.updatedAt || a.startAt);
            const dateB = new Date(b.startAt || b.updatedAt || b.startAt);
            return dateB - dateA;
          });
          break;
        // Events need a date sort, this field is hidden
        case "oldest":
          toSort.sort((a, b) => {
            const dateA = new Date(a.startAt);
            const dateB = new Date(b.startAt);
            return dateA - dateB;
          });
          break;
        case "jobs":
          toSort.sort((a, b) => {
            const stringA = a.title;
            const stringB = b.title;
            if (
              stringA &&
              stringB &&
              stringA.toLowerCase() > stringB.toLowerCase()
            ) {
              return 1;
            }
            return -1;
          });
          break;
        // case 'date-reverse':
        //   toSort.sort((a, b) => {
        //     const dateA = new Date(a.startAt)
        //     const dateB = new Date(b.startAt)
        //     return dateB - dateA
        //   })
        // break;
        default:
          toSort.sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return dateB - dateA;
          });
          break;
      }
    }
    return toSort;
  };
  const updateFilter = value => {
    const arr = filterTerms;
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    // if(value == 'Upcoming' || value == 'Past'){
    //   setSort(value == 'Past'? 'newest' : 'newest')
    // }
    setFilterTerms([...arr]);
    // Adjusts URL based on filter selections
    if (arr.length > 0) {
      const excludedItems = [
        "Sell my products/services",
        "Engage and educate people",
        "Monetize video content",
        "Deliver a virtual or hybrid event",
      ];
      const excludedArr = arr.filter(item => {
        return excludedItems.indexOf(item) == -1;
      });
      const arrStr = excludedArr.toString();
      const newURL = `?type=${arrStr}`;
      excludedArr.length > 0 ? history.replaceState(null, "", newURL) : null;
    } else {
      history.replaceState(null, "", window.location.pathname);
    }
  };
  const updateWhen = value => {
    if (value != "") {
      setFilteredContent(eventsArrays[value]);
      setWhenTerms([value]);
    }
  };

  // 2
  useEffect(() => {
    let toFilter = eventsList ? eventsArrays[whenTerms] : items;
    if (search.length > 0) {
      toFilter = items.filter(item => {
        return JSON.stringify(item)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    // loop through filters
    let filtered = [];
    if (filterTerms.length > 0) {
      filtered = toFilter.filter(item => {
        const stringified = JSON.stringify(item).toLowerCase();
        return filterTerms.some(term => {
          return stringified.includes(term.toLowerCase());
        });
      });
    } else {
      // return all items since we havent activated filters
      filtered = toFilter;
    }
    // sort by sort preference
    filtered = sortContent(filtered).slice(0, -1);
    // set the filtered content if it has changed or if there are no terms
    if (filtered !== toFilter || filterTerms.length == 0) {
      setFilteredContent(filtered);
    }
    //eslint-disable-next-line
  }, [search, filterTerms, items, sortMode]);

  // 3
  // useEffect(() => {
  // 	setFilteredContent(sortContent(filteredContent));
  // }, [sortMode]);

  const SortHolder = () => (
    <Styles.CheckBoxHolder className="sort-holder">
      <strong>Sort By :</strong>
      <select
        value={sortMode}
        onChange={e => {
          setSort(e.target.value);
        }}
      >
        <option value="newest">Newest First</option>
        <option value="A-Z">A - Z</option>
        <option value="Z-A">Z - A</option>
        <option value="date">Date</option>
      </select>
    </Styles.CheckBoxHolder>
  );

  // Update filter on window.load if query params are present
  const paramFilter = () => {
    const params = new URLSearchParams(window.location.search);
    const typeParams = params.getAll("type");
    const categoryParams = params.getAll("category");
    const locationParams = params.getAll("location");
    const filterParams = params.getAll("filter");
    // let whenParams = params.getAll('when')
    const allParamTerms = typeParams.concat(
      categoryParams,
      locationParams,
      filterParams
    );
    // Some params are sent as comma separated strings, so we split those out into their own array values
    allParamTerms.forEach(termItem => {
      if (termItem.includes(",")) {
        const itemArray = termItem.split(",");
        itemArray.forEach(newItem => {
          allParamTerms.push(newItem);
        });
        const termIndex = allParamTerms.indexOf(termItem);
        allParamTerms.splice(termIndex, 1);
      }
    });
    const capitalize = s => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    // If we have params, set the filter. Capitalization is needed for the controls to reflect the changes.
    if (allParamTerms.length > 0) {
      setFilterTerms(allParamTerms.map(x => capitalize(x)));
    }
    // if(whenParams.length > 0){
    //   setWhenTerms([whenParams])
    //   setFilterTerms(eventsArrays[whenParams[0]].map(x => capitalize(x)))
    // }
  };

  // Global useEffect to check for window load. We could get react-dom or something to do this more thoroughly
  useEffect(() => {
    if (window) {
      window.addEventListener("load", paramFilter);
    }
    return () => {
      window.removeEventListener("load", paramFilter);
    };
  }, []);
  // Shitty lazy check if the filter is on the jobs page, so we ignore the initial window load and update when the component is actually re-rendered.
  useEffect(() => {
    if (sortDefault === "jobs") {
      paramFilter();
    }
    //eslint-disable-next-line
  }, []);
  // 1
  useEffect(() => {
    if (eventsList) {
      setFilteredContent(eventsArrays.Upcoming);
      setWhenTerms(["Upcoming"]);
    } else {
      setFilteredContent(items);
      setFilterTerms([]);
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Styles.ContentFilterStyles>
      <Container
        style={{
          position: "sticky",
          position: "-webkit-sticky",
          background: "#fff",
          zIndex: "20",
          top: "0px",
        }}
        className={className}
      >
        <Row>
          <Col md={12}>
            <Styles.FilterWrapper>
              <h3>{title}</h3>
              {filters.map(filterObject => {
                return (
                  <FilterHolder
                    key={filterObject.label}
                    obj={filterObject}
                    callback={updateFilter}
                    arr={filterTerms}
                  />
                );
              })}
              {eventsList && (
                <FilterHolder
                  key="When"
                  obj={{
                    label: "When",
                    type: "checkbox",
                    values: ["Upcoming", "Past", "All"],
                  }}
                  callback={updateWhen}
                  arr={whenTerms}
                />
              )}
              <Styles.SearchBoxHolder>
                <label htmlFor="contentsearch">Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  name="contentsearch"
                />
                {search && search.length > 0 && (
                  <button onClick={() => setSearch("")}>
                    <Icon iconImage="close" iconColor={"#000"} className={""} />
                  </button>
                )}
              </Styles.SearchBoxHolder>
            </Styles.FilterWrapper>
          </Col>
        </Row>
        <Row
          style={{
            margin: "0",
            display: `${!showSort ? "none" : "initial"}`,
          }}
        >
          <Col style={{ padding: "0" }} />
          <Col xs="content" style={{ padding: "20px 0" }}>
            <SortHolder sortDefault={sortDefault} />
          </Col>
        </Row>
      </Container>
    </Styles.ContentFilterStyles>
  );
};

export default ContentFilter;
