import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Markdown from "markdown-to-jsx";
import * as Styles from "./LogoGridStyles";
import Carousel from "./Carousel/Carousel";
import Link from "next/link";
import ContentfulImage from "@/src/lib/contentful-image";
import CallToAction from "../CallToAction/CallToAction";

const LogoGrid = ({
  slug,
  lang,
  name,
  textColor,
  highlightColor,
  backgroundColor,
  items,
  ctaText,
  ctaLink,
  style,
}) => {
  const isMobile =
    typeof window !== "undefined" ? window.innerWidth <= 768 : false;
  const langFromLocale = lang.slice(0, 2);
  return (
    <Styles.LogoGrid
      id={slug}
      $textColor={textColor}
      $backgroundColor={backgroundColor}
      $carousel={style === "Overflow" ? "120px" : ""}
      $lang={langFromLocale}
    >
      <Container>
        <Row>
          <Col lg={12}>
            {name && (
              <h3>
                <Markdown>{name}</Markdown>
              </h3>
            )}
          </Col>
        </Row>
        <Row gutterWidth={10} justify="center">
          {items &&
            (style !== "Overflow" || isMobile) &&
            items.map(element => {
              let elementLink = "";
              if (element.fields.slug) {
                elementLink = `/${lang.slice(0, 2)}/partners/${
                  element.fields.slug
                }/`;
              } else {
                elementLink =
                  element.fields.story &&
                  element.fields.story[0] &&
                  element.fields.story[0].slug
                    ? `/${lang.slice(0, 2)}/customers/${
                        element.fields.story[0].slug
                      }/`
                    : "";
              }
              return (
                <Col
                  key={`element-grid-${element.fields.name}`}
                  lg={1.7}
                  md={1.7}
                  sm={4}
                  xs={6}
                >
                  {elementLink !== "" && (
                    <Link prefetch href={elementLink}>
                      {element.fields.logo ? (
                        <ContentfulImage
                          alt={element.fields.logo.fields.title || ""}
                          width={
                            element.fields.logo.fields.file?.details.image
                              .width || 1000
                          }
                          height={
                            element.fields.logo.fields.file?.details.image
                              .height || 1000
                          }
                          quality={100}
                          priority={true}
                          src={element.fields.logo.fields.file?.url}
                        />
                      ) : (
                        // eslint-disable-next-line
                        <img
                          key={`img-grid-${element.fields.name}`}
                          src={element.fields.logoUrl}
                          alt={element.fields.name}
                          title={`Read ${element.fields.name} customer story`}
                        />
                      )}
                    </Link>
                  )}
                  {elementLink === "" &&
                    (element.fields.logo ? (
                      <ContentfulImage
                        alt={element.fields.logo.fields.title || ""}
                        width={
                          element.fields.logo.fields.file?.details.image
                            .width || 1000
                        }
                        height={
                          element.fields.logo.fields.file?.details.image
                            .height || 1000
                        }
                        quality={100}
                        priority={true}
                        src={element.fields.logo.fields.file?.url}
                      />
                    ) : (
                      // eslint-disable-next-line
                      <img
                        key={`img-grid-${element.fields.name}`}
                        src={element.fields.logoUrl}
                        alt={element.fields.name}
                        title={`Read ${element.fields.name} customer story`}
                      />
                    ))}
                </Col>
              );
            })}
          {/* If Overflow option selected */}
          {items && style === "Overflow" && (
            <Col>
              <Carousel items={items} lang={lang} />
            </Col>
          )}
        </Row>
        {ctaLink && (
          <Row className="cta-div">
            <Col>
              <CallToAction
                linkPath={`${ctaLink}`}
                iconColor={highlightColor}
                textColor={textColor}
                callToActionCopy={ctaText}
                lang={lang}
              />
            </Col>
          </Row>
        )}
      </Container>
    </Styles.LogoGrid>
  );
};

export default LogoGrid;
