import React from "react";
import Markdown from "markdown-to-jsx";
import * as Styles from "./ContentCardStyles";
import ContentfulImage from "@/src/lib/contentful-image";
import Icon from "../../Global_Components/Icon/Icon";

const ContentCard = props => {
  const {
    fit,
    image,
    imageObj,
    small,
    label,
    logo,
    title,
    topic,
    teaser,
    type,
    previewCopy,
    backgroundColor,
    itemLength,
    darkImage,
  } = props;

  const imgData = img => (img.file ? img.file.s3_url : img);

  if (type === "Color block") {
    return (
      <Styles.ContentCardBlock
        $darkImage={darkImage}
        $fit={fit}
        className="color-block"
        $backgroundColor={backgroundColor}
      >
        {image && !imageObj && (
          <ContentfulImage
            alt={image?.fields?.title || ""}
            width={400}
            height={image?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image?.fields?.file?.url}
          />
        )}
        {imageObj && (
          <ContentfulImage
            alt={imageObj?.fields?.title || ""}
            width={imageObj?.fields?.file?.details.image.width || 1000}
            height={imageObj?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={imageObj?.fields?.file?.url}
          />
        )}
        <div className="card-text-container">
          <h5>
            <Markdown>{title}</Markdown>
          </h5>
          <p>
            <Markdown>{previewCopy}</Markdown>
          </p>
          <div className="link-container">
            <Icon iconColor="#fff" iconImage="arrow" className={""} />
            {small && <span>{small}</span>}
          </div>
        </div>
      </Styles.ContentCardBlock>
    );
  }
  if (type === "Product image block") {
    return (
      <Styles.ContentCardBlock
        $fit={fit}
        $darkImage={darkImage}
        className="product-image-block"
        $backgroundColor={backgroundColor}
        $itemLength={itemLength}
      >
        {imageObj && (
          <ContentfulImage
            alt={imageObj?.fields?.title || ""}
            width={imageObj?.fields?.file?.details.image.width || 1000}
            height={imageObj?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={imageObj?.fields?.file?.url}
          />
        )}
        <div className="card-text-container">
          <div className="header-wrapper">
            <h5>{title}</h5>
          </div>
          <p>
            <Markdown>{previewCopy}</Markdown>
          </p>
          <div className="link-container">
            <Icon iconColor="#fff" iconImage="arrow" className={""} />
            {small && <span>{small}</span>}
          </div>
        </div>
      </Styles.ContentCardBlock>
    );
  }
  if (type === "Featured content") {
    return (
      <Styles.ContentCardBlock
        $darkImage={darkImage}
        $fit={fit}
        className="featured-content-block"
        $backgroundColor={backgroundColor}
        $itemLength={itemLength}
      >
        {imageObj && (
          <ContentfulImage
            alt={imageObj?.fields?.title || ""}
            width={imageObj?.fields?.file?.details.image.width || 1000}
            height={imageObj?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={imageObj?.fields?.file?.url}
          />
        )}
        <div className="card-text-container">
          {title && <h5>{title}</h5>}
          {previewCopy && (
            <p>
              <Markdown>{previewCopy}</Markdown>
            </p>
          )}
          <div className="link-container">
            <Icon iconColor="#000" iconImage="arrow" className={""} />
            {small && <span>{small}</span>}
          </div>
        </div>
      </Styles.ContentCardBlock>
    );
  }
  if (type === "Square color blocks") {
    return (
      <Styles.ContentCardBlock
        $darkImage={darkImage}
        $fit={fit}
        className="square-color-block"
      >
        <div className="card-text-container">
          {title && <h5>{title}</h5>}
          {previewCopy && (
            <p>
              <Markdown>{previewCopy}</Markdown>
            </p>
          )}
          <div className="link-container">
            <Icon iconColor="#fff" iconImage="arrow" className={""} />
            {label && <span style={{ color: `#fff` }}>{label}</span>}
          </div>
        </div>
      </Styles.ContentCardBlock>
    );
  }

  return (
    <Styles.ContentCardBlock $darkImage={darkImage} $fit={fit}>
      {image && !imageObj && (
        <div className="image-wrapper">
          <ContentfulImage
            alt={image?.fields?.title || ""}
            width={image?.fields?.file?.details.image.width || 1000}
            height={image?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image?.fields?.file?.url || image}
          />
        </div>
      )}
      {imageObj && (
        <ContentfulImage
          alt={imageObj?.fields?.title || ""}
          width={imageObj?.fields?.file?.details.image.width || 1000}
          height={imageObj?.fields?.file?.details.image.height || 1000}
          quality={100}
          priority={true}
          src={imageObj?.fields?.file?.url}
        />
      )}
      {logo && !imageObj && (
        <ContentfulImage
          alt={logo?.fields?.title || ""}
          width={logo?.fields?.file?.details.image.width || 1000}
          height={logo?.fields?.file?.details.image.height || 1000}
          quality={100}
          priority={true}
          src={logo?.fields?.file?.url}
        />
      )}
      {small && <span>{small}</span>}
      <h5 className="bold">{title}</h5>
      {teaser && (
        <p>
          <Markdown>{teaser}</Markdown>
        </p>
      )}
      <Icon iconColor="#000" iconImage="arrow" className={""} />
      {topic && <span className="topic-tag">{topic}</span>}
    </Styles.ContentCardBlock>
  );
};

export default ContentCard;
