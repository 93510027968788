"use client";
import React, { useEffect, useState } from "react";

import {
  SecondaryNavbarStyles,
  CtaBrightcoveStyles,
} from "../SecondaryNavbarStyles";
import CtaBrightcove from "@/src/components/Contentful_Components/CTABrightcove";

interface ISecondaryNavbar {
  backgroundColor?: string;
  height?: string;
  displayLinks?: any;
  lang: string;
}

const ModelA = ({
  backgroundColor,
  displayLinks,
  height,
  lang,
}: ISecondaryNavbar) => {
  const [secondNavScroll, setSecondNavScroll] = useState(false); //  SecondNavbar Desktop
  const [secondNavScrollTablet, setSecondNavScrollTablet] = useState(false); //  SecondNavbar Desktop
  // Function on scroll
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  // on scroll SecondNavbar Desktop
  const changeNav = () => {
    window.scrollY >= 110
      ? setSecondNavScroll(true)
      : setSecondNavScroll(false);
    window.scrollY >= 67
      ? setSecondNavScrollTablet(true)
      : setSecondNavScrollTablet(false);
  };

  return (
    <SecondaryNavbarStyles
      $backgroundColor={backgroundColor}
      $height={height}
      $secondNavScroll={secondNavScroll}
      $secondNavScrollTablet={secondNavScrollTablet}
    >
      <div className="Model-A">
        {displayLinks &&
          displayLinks.map((card, index) => {
            return (
              <CtaBrightcoveStyles
                $textColor={card.fields.textColor}
                className="secondary-link"
                key={index}
              >
                <CtaBrightcove lang={lang} {...card.fields} />
              </CtaBrightcoveStyles>
            );
          })}
      </div>
    </SecondaryNavbarStyles>
  );
};

export default ModelA;
