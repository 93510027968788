import { JSONValue, MainImageType } from "@/src/types/types";
import React, { lazy } from "react";
import CTA, { ICTA } from "../../Topics/CTA";
const LazyReactPlayerLoader = lazy(() => import("@brightcove/react-player-loader"))
import ReactPlayerLoader from "@brightcove/react-player-loader";
import * as Styles from "./styles";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";

interface IHeroProps {
  internalName: string;
  heroImage?: MainImageType;
  video?: {
    fields: {
      internalName: string;
      playerId: string;
      video: JSONValue;
    };
  };
  eyebrow?: string;
  tag?: string;
  headline: string;
  content?: string;
  cta?: ICTA[];
  backgroundColor?: "Black" | "White" | "Grey";
  variant?: "Default" | "HP";
  lang: string;
  paddingTop: "Small" | "Medium";
  paddingBottom: "Small" | "Medium";
}

const HeroModule = (props: IHeroProps) => {
  const {
    headline,
    lang,
    backgroundColor,
    content,
    cta,
    heroImage,
    tag,
    variant,
    video,
    paddingTop,
    paddingBottom,
    eyebrow,
  } = props;
  return (
    <Styles.ModuleHero
      $backgroundColor={backgroundColor}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $isProduct={eyebrow?.includes("<sup>") ? true : false}
    >
      <div className="text-section">
        {eyebrow && (
          <Markdown
            className="eyebrow"
            options={{
              wrapper: "span",
              forceWrapper: true,
            }}
          >
            {eyebrow}
          </Markdown>
        )}
        <div className="headline-content-wrapper">
          {tag && (
            <Markdown
              className="tag"
              options={{
                wrapper: "h2",
                forceWrapper: true,
              }}
            >
              {tag}
            </Markdown>
          )}
          <Markdown
            options={{
              wrapper: "h1",
              forceWrapper: true,
            }}
          >
            {headline}
          </Markdown>
          {content && (
            <Markdown
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {content}
            </Markdown>
          )}
        </div>
        {cta && cta.length ? (
          <div className="ctas-wrapper">
            {cta.map((c, i) => (
              <CTA
                type="buttonLink"
                lang={lang}
                index={i}
                ctaLength={cta.length}
                cta={c}
                key={i}
                backgroundColor={backgroundColor}
              />
            ))}
          </div>
        ) : null}
      </div>
      {variant === "Default" && (
        <div className="media-section">
          {heroImage && (
            <ContentfulImage
              alt={heroImage.fields?.description || ""}
              width={heroImage.fields?.file?.details.image.width || 1000}
              height={heroImage.fields?.file?.details.image.height || 1000}
              quality={100}
              priority={true}
              src={heroImage.fields?.file?.url}
            />
          )}
          {video && (
            <React.Suspense
              fallback={
                <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                  <div
                    className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                    style={{
                      backgroundSize: "200% 100%",
                      animation: "shimmer 1.5s infinite",
                    }}
                  />
                </div>
              }
            >
              <LazyReactPlayerLoader
                playerId={video.fields.playerId}
                baseUrl="https://players.brightcove.net"
                accountId="1160438696001"
                videoId={video.fields.video["video_id"]}
              />
            </React.Suspense>
          )}
        </div>
      )}
    </Styles.ModuleHero>
  );
};

export default HeroModule;
