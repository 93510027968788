import React from "react";
import Markdown from "markdown-to-jsx";

import { Container, Row, Col } from "react-grid-system";
import * as Styles from "./QuoteBlockStyles";
import ContentfulImage from "@/src/lib/contentful-image";

const QuoteBlock = props => {
  const {
    highlightColor,
    backgroundColor,
    textColor,
    quoteAuthor,
    quoteAuthorTitle,
    quote,
    customer,
    translations,
    logoOverride,
    ctaText,
    lang,
  } = props;
  return (
    <>
      {/* {logoOverride || (customer && customer.fields.story) ? ( */}
      {logoOverride ? (
        <Styles.QuoteBlockWithCustomer
          $highlightColor={highlightColor}
          $backgroundColor={backgroundColor}
          $textColor={textColor}
        >
          <Container>
            <Row align="stretch">
              {/* <Col lg={4} md={4} sm={12}>
                {customer.story[0].mainImage && customer.story[0].mainImage !== null  ? (
                  <Img fadeIn={false} fixed={customer.story[0].mainImage.fixed} />
                ) : (
                  <ImgixWebp src={imgData(customer.story[0])} maxWidth="400" />
                )}
              </Col> */}
              <Col
                lg={3}
                md={3}
                sm={12}
                style={{
                  verticalAlign: "middle",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {logoOverride && (
                  <ContentfulImage
                    alt={logoOverride.fields.title || ""}
                    width={
                      logoOverride.fields.file?.details.image.width || 1000
                    }
                    height={
                      logoOverride.fields.file?.details.image.height || 1000
                    }
                    quality={100}
                    priority={true}
                    src={logoOverride.fields.file?.url}
                  />
                )}
                {/* {!logoOverride ? (
									customer.fields.logo ? (
										<ContentfulImage
											alt={customer.fields.logo.fields.title || ""}
											width={
												customer.fields.logo.fields.file?.details
													.image.width || 1000
											}
											height={
												customer.fields.logo.fields.file?.details
													.image.height || 1000
											}
											quality={100}
											priority={true}
											src={customer.fields.logo.fields.file?.url}
										/>
									) : (
										<img
											src={customer.fields.logoUrl}
											alt={customer.fields.name}
										/>
									)
								) : null} */}
              </Col>
              <Col lg={9} md={9} sm={12}>
                <blockquote>
                  {quote && <Markdown>{quote}</Markdown>}
                  <cite>
                    {quoteAuthor} <span>{quoteAuthorTitle}</span>
                  </cite>
                </blockquote>
                {/* {customer && customer.fields.story[0].slug && (
									<CallToAction
										linkPath={`https://www.brightcove.com/${
											lang.slice(0, 2) || "en"
										}/customers/${customer.story[0].slug}/`}
										iconColor="#000"
										textColor="#000"
										lang={lang.slice(0, 2) || "en"}
										callToActionCopy={
											ctaText || translations["Read story"]
										}
									/>
								)} */}
              </Col>
            </Row>
          </Container>
        </Styles.QuoteBlockWithCustomer>
      ) : (
        <Styles.QuoteBlock
          $highlightColor={highlightColor}
          $backgroundColor={backgroundColor}
          $textColor={textColor}
        >
          <Container>
            <Row>
              <Col lg={12}>
                <blockquote>
                  {quote && <Markdown>{quote}</Markdown>}
                  <cite>
                    {quoteAuthor} <span>{quoteAuthorTitle}</span>
                  </cite>
                </blockquote>
              </Col>
            </Row>
          </Container>
        </Styles.QuoteBlock>
      )}
    </>
  );
};

export default QuoteBlock;
