import React from "react";
import ModelA from "./Model/ModelA";
import ModelB from "./Model/ModelB";

interface ISecondaryNavbar {
  title: string;
  backgroundColor: string;
  height?: string;
  displayLinks?: any;
  type: string;
  lang: string;
}

const SecondaryNavbar = (props: ISecondaryNavbar) => {
  const { type } = props;
  return (
    <>
      {type === "model-A" && <ModelA {...props} />}
      {type === "model-B" && <ModelB {...props} />}
    </>
  );
};

export default SecondaryNavbar;
