import React from "react";
import CTA, { ICTA } from "../../Topics/CTA";
import * as Styles from "./styles";

interface ILinkSectionProps {
  internalName: string;
  title?: string;
  links: ICTA[];
  backgroundColor?: "Black" | "White" | "Grey";
  paddingTop?: "Medium" | "Small";
  paddingBottom?: "Medium" | "Small";
  lang: string;
}

const LinkSectionModule = (props: ILinkSectionProps) => {
  const { lang, backgroundColor, links, paddingBottom, paddingTop, title } =
    props;
  return (
    <Styles.ModuleLinkSection
      $backgroundColor={backgroundColor}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $linkCount={links.length}
    >
      <p className="title">{title}</p>
      <div className="link-list">
        {links.map((link, i) => (
          <div className="link" key={i}>
            <CTA
              cta={link}
              ctaLength={links.length}
              lang={lang}
              index={i}
              backgroundColor={backgroundColor}
              type="hoverBackgroundLink"
            />
          </div>
        ))}
      </div>
    </Styles.ModuleLinkSection>
  );
};

export default LinkSectionModule;
