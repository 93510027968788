import {
  smallHeaderFontStyles,
  textContentFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const TopicColumn = styled("div")<{
  $numOfColumns: number;
}>`
  display: flex;
  flex-direction: ${({ $numOfColumns }) =>
    $numOfColumns === 1 ? "row" : "column"};
  gap: 30px;
  width: ${({ $numOfColumns }) =>
    $numOfColumns === 1
      ? "100%"
      : $numOfColumns === 2 || $numOfColumns === 4
        ? "calc((1280px - 55px) / 2)"
        : "calc((1280px - 95px) / 3)"};

  img {
    align-self: ${({ $numOfColumns }) =>
      $numOfColumns === 1 ? "flex-start" : "center"};
  }
  .content-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: ${({ $numOfColumns }) =>
      $numOfColumns === 1 ? "calc(100% - 30px - 150px)" : "100%"};

    h6 {
      ${smallHeaderFontStyles()};
      text-align: ${({ $numOfColumns }) =>
        $numOfColumns === 1 ? "left" : "center"};
    }

    p {
      ${textContentFontStyles()};
      word-wrap: break-word;
      :not(:last-child) {
        padding-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    width: ${({ $numOfColumns }) =>
      $numOfColumns === 1
        ? "100%"
        : $numOfColumns === 2 || $numOfColumns === 4
          ? "calc((100% - 40px) / 2)"
          : "calc((100% - 80px) / 3)"};
    gap: 20px;
    img {
      scale: 0.8;
      ${({ $numOfColumns }) =>
        $numOfColumns === 1 && "transform-origin: top right"};
    }
    .content-section {
      gap: 20px;
      width: ${({ $numOfColumns }) =>
        $numOfColumns === 1 ? "calc(100% - 20px - (150px * 0.8))" : "100%"};
      h6 {
        ${smallHeaderFontStyles("Tablet")}
      }

      p {
        ${textContentFontStyles("Tablet")}
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    gap: 10px;

    img {
      scale: 0.9;
      align-self: center;
      transform-origin: unset;
    }
    ${({ $numOfColumns }) => $numOfColumns === 1 && "flex-direction: column"};
    .content-section {
      gap: 10px;
      width: 100%;
      h6 {
        ${smallHeaderFontStyles("Mobile")}
      }

      p {
        ${textContentFontStyles("Mobile")}
      }
    }
  }
`;
