import styled from "styled-components";

export const VideoTextSplit = styled("div")<{ $orientation?: string }>`
  /************************************** Model A **********************************************/
  /*********************************************************************************************/
  #model-A {
    max-width: 1280px;
    padding: 20px 0px;
    .parent-wrapper {
      display: flex;
      flex-direction: ${({ $orientation }) =>
        $orientation === "video-left" ? "row" : "row-reverse"};
      height: 100%;
      align-items: center;
      .wrapper-text {
        width: 49.95%;
        flex: 0 0 49.95%;
        max-width: 49.95%;
        padding: 0 5%;
        /* HEADER */
        .header {
          font-size: 21px;
          font-family: "articulat-cf";
          margin-bottom: 20px;
          color: #000000;
          font-weight: 600;
        }
        /* BODY */
        .body {
          font-size: 18px;
          font-family: "articulat-cf";
          margin-bottom: 20px;
          color: #000000;

          ul {
            list-style: disc;
          }
        }
      }
      .wrapper-video {
        width: 49.95%;
        flex: 0 0 49.95%;
        max-width: 49.95%;
        border-radius: 8px;
        .video-js {
          aspect-ratio: 16 / 9;
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  /* Tablet: max-width: 1280px */
  @media only screen and (max-width: 1280px) {
    #model-A {
      padding: 20px 30px;
    }
  }
  @media only screen and (max-width: 1006px) {
    #model-A {
      .wrapper-video {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0px;
      }
    }
  }
  /* Mobile: max-width:  769px */
  @media only screen and (max-width: 769px) {
    #model-A {
      .parent-wrapper {
        display: flex;
        flex-direction: ${({ $orientation }) =>
          $orientation === "video-left" ? "column" : "column-reverse"};
        /* Text */
        .wrapper-text {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        /* Video */
        .wrapper-video {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0px;
        }
      }
    }
  }
`;
