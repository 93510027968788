import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const ContentCardBlock = styled("div")<{
  $fit?: string;
  $backgroundColor?: string;
  $itemLength?: number;
  $darkImage?: boolean;
}>`
  padding-bottom: 40px;
  font-family: "articulat-cf", sans-serif;
  .lazyload-wrapper > picture {
    height: 240px;
    display: flex;
    margin-bottom: 10px;
    &:nth-of-type(2) {
      height: 80px;
    }
    img {
      border-radius: 4px;
      object-fit: ${({ $fit }) => $fit || "cover"};
      max-width: 100%;
      position: relative !important;
    }
  }
  .lazyload-wrapper {
    &:nth-of-type(2) > picture {
      height: 50px;
      img {
        object-position: left;
      }
    }
  }
  .img {
    width: 100%;
    height: 240px;
    max-width: 100%;
    max-height: 240px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    object-fit: ${({ $fit }) => $fit || "cover"};
    max-width: 100%;
  }

  picture + picture {
    display: none;
  }

  h5 {
    font-size: 1.2rem;
    font-family: var(--primary-font-family), "articulat-cf", Helvetica,
      sans-serif;
    display: block;
  }
  h4 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 300;
  }
  p {
    margin: 10px 0;
  }
  .topic-tag {
    transform: translate(-35px, 40px);
    position: absolute;
    background-color: #00fae3;
    padding: 5px 12px;
    font-family: "Barlow Semi Condensed", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 55px;
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
    margin-bottom: 10px;
  }
  picture + span,
  img + span {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
    font-size: 0.8rem;
  }
  &.color-block {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    color: #fff;
    padding-bottom: 20px !important;
    margin-bottom: 20px;
    .card-text-container {
      padding: 10px 20px;
      min-height: 158px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h5,
      p,
      span {
        color: #fff;
      }
      h5 {
        font-size: 1.5rem;
      }
    }
    img {
      height: 480px;
      max-height: 480px;
    }
  }
  &.product-image-block {
    color: #fff;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
    height: ${({ $itemLength }) => ($itemLength === 4 ? "240px" : "360px;")};
    overflow: hidden;
    .card-label {
      position: absolute;
      top: 100%;
      left: 0;
      font-family: "Agrandir";
      font-weight: 700;
      text-transform: uppercase;
      padding: 10px 30px;
      opacity: 1;
      transition: opacity 150ms ease-in-out;
      font-size: 1.4rem;
      color: black;
      text-align: bottom;
      margin-bottom: 100px;
      overflow: hidden;
    }
    .card-text-container {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 80%;
      transition: top 300ms ease-in-out;
      padding: 0 10px;
      pointer-events: none;
      opacity: 1;
      h5,
      p,
      span {
        color: #fff;
      }
      .header-wrapper {
        min-height: 45px;
        max-width: 90%;
        display: flex;
        align-items: end;
        h5 {
          font-size: ${({ $itemLength }) =>
            $itemLength === 4 ? "1.1rem" : "1.5rem"};
        }
      }

      p {
        font-size: ${({ $itemLength }) =>
          $itemLength === 4 ? "1.0rem" : "1.2rem"};
        max-width: 90%;
      }
      .link-container {
        display: none;
      }
    }
    img {
      max-height: unset;
      margin-bottom: 0;
      height: 100%;
      transform: scale(1);
      transition: transform 300ms ease-in-out;
      background: #000;
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: #000;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
      }
      ${({ $darkImage }) => ($darkImage ? `filter: brightness(50%);` : ``)}
    }
    @media only screen and (min-width: 1279px) {
      &:hover {
        .card-text-container {
          top: 45%;
          opacity: 1;
        }
        img {
          transform: scale(1.05);
          &:after {
            opacity: 0;
          }
        }
        .card-label {
          opacity: 0;
        }
      }
    }

    @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
      margin-bottom: 20px;
      padding: 0 !important;
      .card-text-container {
        opacity: 1;
        top: 5%;
        p {
          font-size: 1rem;
          max-width: 88%;
        }
        .link-container {
          display: block;
        }
      }
      .card-label {
        display: none;
      }
    }
  }
  &.featured-content-block {
    padding-bottom: 0;
    padding: 0;
    border-radius: 7px;
    overflow: hidden;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    margin-bottom: 10px;
    &:hover {
      box-shadow:
        rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    .featured-content-img {
      margin-bottom: 0;
      border-radius: none;
      img {
        border-radius: none;
      }
    }
    .card-text-container {
      padding: 20px;
      background-color: #fff;
      .link-container {
        display: flex;
        align-items: center;
        span {
          display: block;
          font-weight: 900;
          &.icon-arrow {
            top: 0;
          }
        }
      }
    }
  }
  &.square-color-block {
    background-color: #08088c;
    color: #fff;
    padding-bottom: unset;
    height: 100%;
    .card-text-container {
      padding: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h5 {
        text-transform: uppercase;
      }
      h5,
      p,
      p > span {
        color: #fff !important;
      }
    }
  }
`;
