import styled from "styled-components";

export const Breadcrumbs = styled("div")<{
  $containerSize?: "100%" | "1280px" | "1440px";
}>`
  max-width: ${({ $containerSize }) =>
    $containerSize ? $containerSize : "100%"};
  margin: 14px auto;
  @media screen and (max-width: 1279px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  font-size: 18px;
  font-family: "articulat-cf", sans-serif;
  color: #000;
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 14px;
    font-size: 14px;
  }
  a {
    color: #000;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
      color: #0051af;
    }
  }
  .breadcrumbs {
    &-chevron {
      margin-bottom: 4px;
      @media screen and (max-width: 768px) {
        margin-bottom: 2px;
      }
    }
    &-title {
      font-weight: 700;
    }
  }
`;
