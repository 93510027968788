"use client";
import React, { lazy, useEffect, useState } from "react";
// Video
import ReactPlayerLoader from "@brightcove/react-player-loader";

import * as Styles from "../FullWidthVideoBlockStyles";
import Cookies from "js-cookie";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";
import CallToAction from "../../CallToAction/CallToAction";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);

export const FullWidthVideoBlock = ({
  video,
  eyebrow,
  body,
  header,
  verboseCta,
  verboseCtaSecond,
  lightboxVideo,
  videoBodyCta,
  playerId,
  logo,
  lang,
}) => {
  const options = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
    nativeTextTracks: false,
    preloadTextTracks: false,
    nativeVideoTracks: false,
    nativeControlsForTouch: false,
    userActions: {
      click: false,
      doubleClick: false,
    },
  };
  // Function for parallax
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Styles.FullWidthVideoBlock>
      <ContainerStyle
        id="Model-B"
        fluid="maxFluid"
        style={{
          transform: `translateY(${offset * 0.5}px)`, // style effect for parallax
        }}
      >
        <div className="video-wrapper">
          {/* Video Player */}
          <React.Suspense
            fallback={
              <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                  style={{
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
              </div>
            }
          >
            <ReactPlayerLoader
              playerId={playerId || "RUsPQ8qzl"}
              baseUrl="https://players.brightcove.net"
              refNode="video-wrap"
              accountId={video.account_id || "1160438696001"}
              videoId={video.video_id}
              options={options}
              onEmbedCreated={el => {
                sfmcCookie();
                el.setAttribute("data-bc-known-user", 1);
                el.setAttribute(
                  "data-bc-custom-guid",
                  Cookies.get("_sfmc_id_")
                );
              }}
            />
          </React.Suspense>
        </div>
        {/* Text  */}
        <div className="container-text">
          <div className="wrapper-text">
            {/* Logo */}
            {logo && (
              <div className="logo">
                <ContentfulImage
                  alt={logo.fields.title || ""}
                  width={logo.fields.file?.details.image.width || 1000}
                  height={logo.fields.file?.details.image.height || 1000}
                  quality={100}
                  priority={true}
                  src={logo.fields.file?.url}
                />
              </div>
            )}
            {/* Header Eyebrow Body */}
            {eyebrow && <Markdown>{eyebrow}</Markdown>}
            {header && <Markdown>{header}</Markdown>}
            {body && <Markdown>{body}</Markdown>}
            <div className="wrapper-button">
              {/* Verbose CTA */}
              {verboseCta ? (
                <VerboseCTA
                  {...verboseCta.fields}
                  lang={lang}
                  iconColor={verboseCta.fields.iconColor}
                />
              ) : (
                ""
              )}
              {verboseCtaSecond ? (
                <VerboseCTA
                  {...verboseCtaSecond.fields}
                  lang={lang}
                  iconColor={verboseCtaSecond.fields.iconColor}
                />
              ) : (
                ""
              )}
              {/* Link lightbox */}
              {lightboxVideo && (
                <CallToAction
                  id="hero-cta"
                  callToActionCopy={
                    <Markdown>{videoBodyCta}</Markdown> || "Watch this"
                  }
                  linkPath="#"
                  iconImage="play"
                  iconColor="#fff"
                  lightboxData={{
                    type: "video",
                    content: lightboxVideo.video_id,
                    playerId,
                  }}
                  textColor="#fff"
                  playerId={playerId || "RUsPQ8qzl"}
                  lang={lang}
                />
              )}
            </div>
          </div>
        </div>
      </ContainerStyle>
    </Styles.FullWidthVideoBlock>
  );
};

export default FullWidthVideoBlock;
