import React from "react";
import Markdown from "markdown-to-jsx";
import CallToAction from "../CallToAction/CallToAction";
import * as Styles from "./styles";
import { MainImageType } from "@/src/types/types";
import ContentfulImage from "@/src/lib/contentful-image";

interface ICardProps {
  body: string;
  highlightColor?: string;
  label?: string;
  link?: string;
  linkType?: any;
  title?: string;
  backgroundImage?: MainImageType;
  lang: string;
}

const Card = (props: ICardProps) => {
  return (
    <Styles.Card $highlightColor={props.highlightColor} className={`card`}>
      {props.backgroundImage && (
        <ContentfulImage
          alt={props.backgroundImage?.fields?.title || ""}
          width={
            props.backgroundImage?.fields?.file?.details.image.width || 1000
          }
          height={
            props.backgroundImage?.fields?.file?.details.image.height || 1000
          }
          quality={80}
          priority={true}
          src={props.backgroundImage?.fields?.file?.url}
        />
      )}
      {props.title && <h3 className="card-title">{props.title}</h3>}
      {props.body && <Markdown>{props.body}</Markdown>}
      {props.label && props.link && (
        <CallToAction
          iconImage={props.linkType || "arrow"}
          iconColor={props.highlightColor || "#000"}
          callToActionCopy={props.label}
          linkPath={props.link}
          lang={props.lang}
        />
      )}
    </Styles.Card>
  );
};

export default Card;
