import React, { lazy } from "react";
// Style
import * as Styles from "../VideoTextSplitStyles";
// Video
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);
/* Model A - VideoTextSplit */
import Cookies from "js-cookie";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import Markdown from "markdown-to-jsx";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";

const ModelA = props => {
  const { header, body, video, orientation, verboseCta, playerId, lang } =
    props;

  return (
    <Styles.VideoTextSplit $orientation={orientation}>
      <ContainerStyle id="model-A" fluid={"true"}>
        <div className="parent-wrapper">
          <div className="wrapper-video">
            {video && (
              <React.Suspense
                fallback={
                  <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                    <div
                      className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                      style={{
                        backgroundSize: "200% 100%",
                        animation: "shimmer 1.5s infinite",
                      }}
                    />
                  </div>
                }
              >
                <LazyReactPlayerLoader
                  playerId={playerId || "RUsPQ8qzl"}
                  baseUrl="https://players.brightcove.net"
                  refNode="video-wrap"
                  accountId={video.account_id || "1160438696001"}
                  videoId={video.video_id}
                  onEmbedCreated={el => {
                    sfmcCookie();
                    el.setAttribute("data-bc-known-user", 1);
                    el.setAttribute(
                      "data-bc-custom-guid",
                      Cookies.get("_sfmc_id_")
                    );
                  }}
                />
              </React.Suspense>
            )}
          </div>
          <div className="wrapper-text">
            {header && (
              <div className="header">
                <Markdown>{header}</Markdown>
              </div>
            )}
            {body && (
              <div className="body">
                <Markdown>{body}</Markdown>
              </div>
            )}
            {/* Verbose CTA */}
            {verboseCta ? (
              <VerboseCTA {...verboseCta.fields} lang={lang} />
            ) : (
              ""
            )}
          </div>
        </div>
      </ContainerStyle>
    </Styles.VideoTextSplit>
  );
};

export default ModelA;
