"use client";
import React, { useState, useEffect, lazy } from "react";

const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);
import Cookies from "js-cookie";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import Icon from "../../Global_Components/Icon/Icon";

const HeadlinePlayer = props => {
  // @ts-ignore
  const { loop, setLoop } = useState([10, 15]);
  // @ts-ignore
  const { isPlaying, setIsPlaying } = useState(false);
  const account = props.video.account_id || "1160438696001";
  const [looping, setLooping] = useState(true);
  const [muted, setMuted] = useState(true);
  const [player, setPlayer] = useState(false);
  const [playerInit, setPlayerInit] = useState(false);
  const [overlayElement, setOverlayElement] = useState(false);
  const [navOverlay, setNavOverlay] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);

  let headline;
  let nav;
  let isLooping;
  let reset;
  let screen;
  const onEmbed = embed => {
    embed.setAttribute("data-player", "RUsPQ8qzl");
    embed.setAttribute("data-account", account);
    if (window && window.location) {
      sfmcCookie();
      embed.setAttribute("data-bc-known-user", 1);
      embed.setAttribute("data-bc-custom-guid", Cookies.get("_sfmc_id_"));
      // el.setAttribute('data-bc-known-user', 1)
      // el.setAttribute('data-bc-custom-guid', Cookies.get('_sfmc_id_'))
    }
  };
  const playbackHandler = val => {
    if (
      isLooping &&
      player &&
      // @ts-ignore
      player.currentTime &&
      // @ts-ignore
      player.currentTime() > loop[1]
    ) {
      // @ts-ignore
      player.currentTime(loop[0]);
    }
    // @ts-ignore
    if (isLooping && player && player.textTracks().length > 0) {
      // @ts-ignore
      for (let i = 0; i < player.textTracks().length; i++) {
        // @ts-ignore
        player.textTracks()[i].mode = "hidden";
      }
    }
    if (player && val == true) {
      // @ts-ignore
      player.muted(true);
    }
  };
  const onSuccess = success => {
    const thisPlayer = success.ref;
    setPlayer(thisPlayer);
  };
  const restartVid = () => {
    // setLoop(false)
    // setMuted(false)
    // isLooping = false
    setIsPlaying(true);
    if (player) {
      headline = headline || overlayElement;
      nav = nav || navOverlay;
      reset = reset || resetBtn;
      if (headline) {
        headline.classList.add("offscreen");
      }
      if (nav) {
        nav.classList.add("offscreen");
      }
      if (reset) {
        reset.classList.add("visible");
      }
      if (screen) {
        screen.classList.add("hidden");
      }
      // @ts-ignore
      player.controlBar.show();
      // @ts-ignore
      player.currentTime(0);
      // @ts-ignore
      player.play();
      // @ts-ignore
      player.muted(false);
    }
  };
  const autoPlay = () => {
    // setLoop(false)
    setMuted(false);
    // isLooping = false
    if (player) {
      headline = headline || overlayElement;
      nav = nav || navOverlay;
      reset = reset || resetBtn;
      // if (headline) {
      // 	headline.classList.add("offscreen");
      // }
      // if (nav) {
      // 	nav.classList.add("offscreen");
      // }
      // if (reset) {
      // 	reset.classList.add("visible");
      // }
      // if (screen) {
      // 	screen.classList.add("hidden");
      // }
      // @ts-ignore
      player.controlBar.show();
      // @ts-ignore
      player.currentTime(0);
      // @ts-ignore
      player.play();
      // @ts-ignore
      player.muted(false);
      // @ts-ignore
      setMuted(player.muted());
    }
  };
  const resumeLoop = () => {
    setLoop(true);
    setMuted(true);
    setIsPlaying(false);
    isLooping = true;
    // @ts-ignore
    player.muted(true);
    playbackHandler(isLooping);
    headline = headline || overlayElement;
    nav = nav || navOverlay;
    reset = reset || resetBtn;
    screen = screen || document.querySelector("#screen");
    if (headline) {
      headline.classList.remove("offscreen");
    }
    if (nav) {
      nav.classList.remove("offscreen");
    }
    if (reset) {
      reset.classList.remove("visible");
    }
    if (screen) {
      screen.classList.remove("hidden");
    }
    // @ts-ignore
    player.controlBar.hide();
    // @ts-ignore
    player.currentTime(loop[0]);
    // @ts-ignore
    player.muted(true);
    // @ts-ignore
    player.play();
  };
  const pauseHandler = () => {
    nav.classList.remove("offscreen");
    headline.classList.remove("offscreen");
    reset.classList.remove("visible");
    // @ts-ignore
    player.controlBar.show();
    setIsPlaying(false);
  };
  const playHandler = () => {
    if (!isLooping || looping == false) {
      nav.classList.add("offscreen");
      headline.classList.add("offscreen");
      reset.classList.add("visible");
      setMuted(false);
      setIsPlaying(true);
    }
  };
  const initPlayerListeners = () => {
    const handleEscape = e => {
      if (e.keyCode === 27) {
        e.preventDefault();
        resumeLoop();
      }
    };
    document.addEventListener("keydown", handleEscape, false);

    // @ts-ignore
    player.on("loadedmetadata", () => {
      // @ts-ignore
      const playPromise = player.play();
      if (playPromise !== "undefined") {
        playPromise
          .then(() => {
            // @ts-ignore
            player.controlBar.hide();
            // @ts-ignore
            player.currentTime(loop[0]);
            // @ts-ignore
            // @ts-ignore
            player.muted(true);
          })
          .catch(e => {
            // @ts-ignore
            if (autoPlay) {
              // @ts-ignore
              player.controlBar.show();
            } else {
              // @ts-ignore
              player.controlBar.hide();
            }

            // @ts-ignore
            player.muted(true);
            // @ts-ignore
            player.play();
            // @ts-ignore
            player.muted(true);
            console.info(
              "PLAY from loaded, autoplay err",
              e,
              // @ts-ignore
              player.muted()
            );
          });
      }
      setLoop(true);
      // fade up
      const playerWrap = document.querySelector(
        "#headline-player-wrap > .brightcove-react-player-loader"
      );
      if (playerWrap) {
        playerWrap.classList.add("active");
      }
    });
    // @ts-ignore
    player.on("timeupdate", playbackHandler);
    // @ts-ignore
    player.on("ended", resumeLoop);
    // @ts-ignore
    player.on("pause", pauseHandler);
    // @ts-ignore
    player.on("play", playHandler);
    headline = document.querySelector("#headline-wrap");
    nav = document.querySelector("#main-nav");
    reset = document.querySelector("#reset-loop");
    screen = document.querySelector("#screen");
    isLooping = true;
    const button = document.querySelector("#hero-cta");
    if (button) {
      button.addEventListener("click", () => {
        restartVid();
      });
    }

    setOverlayElement(headline);
    setNavOverlay(nav);
    setResetBtn(reset);
    setPlayerInit(true);

    if (window) {
      const params = new URLSearchParams(window.location.search);
      const autoplay = params.get("autoplay");
      // setAutoplay(params.get('autoplay'))
      // if (autoplay) {
      autoPlay();
      // @ts-ignore
      const playerPromise = player.play();
      if (playerPromise !== undefined) {
        playerPromise
          .then(function () {
            // Automatic playback started!
            setMuted(false);
          })
          .catch(function () {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          });
        // }
      }
    }
  };

  useEffect(() => {
    if (player && !playerInit && !props.mobile) {
      initPlayerListeners();
    }
    if (player && looping == false) {
      // @ts-ignore
      player.muted(false);
      playbackHandler(looping);
    }
    if (player && looping == true) {
      // @ts-ignore
      player.muted(true);
    }
    if (
      playerInit &&
      player !== null &&
      typeof player === "object" &&
      // @ts-ignore
      typeof player.muted === "function"
    ) {
      // @ts-ignore
      player.muted(muted);
    }
    //eslint-disable-next-line
  }, [player, overlayElement, looping, muted, loop]);
  // useEffect(() => {
  // }, [isPlaying])
  return (
    <div
      id="headline-player-wrap"
      style={{
        background: props.video.poster
          ? `url(${props.video.poster})`
          : "transparent",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        opacity: props.mobile ? ".8" : "1",
      }}
    >
      {!props.mobile && (
        <>
          <button
            type="button"
            id="reset-loop"
            onClick={() => {
              resumeLoop();
            }}
          >
            <Icon className={""} iconImage="close" iconColor="#fff" />
          </button>

          {/* <button
            type="button"
            id="toggle-mute"
            className={muted ? 'muted' : 'un-muted'}
            onClick={(e) =>{ setMuted(!muted) }}>
            <Icon iconImage="soundOn" iconColor="#fff" />
          </button> */}
          <div id="screen" />
          <React.Suspense
            fallback={
              <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                  style={{
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
              </div>
            }
          >
            <LazyReactPlayerLoader
              accountId={props.video.account_id || "1160438696001"}
              playerId="RUsPQ8qzl"
              refNode="video-wrap"
              onSuccess={onSuccess}
              onEmbedCreated={onEmbed}
              videoId={props.video.video_id}
            />
          </React.Suspense>
        </>
      )}
    </div>
  );
};
export default HeadlinePlayer;
