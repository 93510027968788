import React from "react";
import * as Styles from "./styles";
import Accordion from "../Accordion";

interface IAccordionListProps {
  accordionItems: any;
  topAccordionFontSize?: string[];
}

const AccordionList = ({
  accordionItems,
  topAccordionFontSize,
}: IAccordionListProps) => {
  return (
    <Styles.MainStyles>
      <ul>
        {accordionItems.map((item, i) => (
          <Accordion
            name={item.fields.name}
            label={item.fields.label}
            textContent={item.fields.textContent}
            subAccordionList={item.fields.subAccordionList}
            topAccordionFontSize={topAccordionFontSize}
            key={i}
          />
        ))}
      </ul>
    </Styles.MainStyles>
  );
};

export default AccordionList;
