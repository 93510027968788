import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import PaginationStyle from "./PaginationStyles";
import { ResourceCard } from "../ResourceCard/ResourceCard";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import Spinner from "@/src/components/Global_Components/Spinner/Spinner";

export const Pagination = ({
  translations,
  items,
  topCard,
  tagOnClick,
  lang,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [isLoding, setIsLoading] = useState(true);

  const itemsPerPage = 12;
  const pagesVisited = pageNumber * itemsPerPage;

  useEffect(() => {
    // init to the first page on new pagination
    setPageNumber(0);
    // Fetch items from another resources.
    setPageCount(
      // @ts-ignore
      Math.ceil(items.length > itemsPerPage && items.length / itemsPerPage)
    );
  }, [pageCount, items]);

  // Loading Pagination on load
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // function Scroll to top element on click
  const scrollToBottom = () => {
    topCard.current?.scrollIntoView({ behavior: "smooth" });
  };

  // On change Page
  const changePage = ({ selected }) => {
    setPageNumber(selected);
    scrollToBottom();
  };

  // Render List
  const ContentList = ({ translations }) => {
    return (
      <RowStyle style={{ paddingTop: "20px" }}>
        {items && items.length > 0 ? (
          items
            .slice(pagesVisited, pagesVisited + itemsPerPage)
            .map((data, index) => {
              return (
                <ColStyle
                  key={index}
                  style={{ padding: 0 }}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <ResourceCard
                    lang={lang}
                    card={data}
                    tagOnClick={tagOnClick}
                  />
                </ColStyle>
              );
            })
        ) : (
          <ColStyle style={{ textAlign: "center", padding: "30px" }}>
            <p style={{ textAlign: "center" }}>
              {translations["No Resources found"]}
            </p>
          </ColStyle>
        )}
      </RowStyle>
    );
  };
  // Translation number for Japan.
  const pageLabelBuilder = page => translations[page] || page;

  return (
    <>
      {isLoding ? (
        <Spinner />
      ) : (
        <ContainerStyle>
          <PaginationStyle>
            <ContentList translations={translations} />
            {items && items.length > 0 && (
              <ReactPaginate
                previousLabel={translations.Previous}
                nextLabel={translations.Next}
                pageCount={pageCount}
                onPageChange={changePage}
                pageLabelBuilder={pageLabelBuilder}
                containerClassName="paginationBttns"
                previousLinkClassName={
                  items.length < 12 ? "no-previous-next" : "previousBttn"
                }
                nextLinkClassName={
                  items.length < 12 ? "no-previous-next" : "nextBttn"
                }
                disabledClassName="paginationDisabled"
                activeClassName="paginationActive"
              />
            )}
          </PaginationStyle>
        </ContainerStyle>
      )}
    </>
  );
};
