"use client";
import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-grid-system";

import Markdown from "markdown-to-jsx";

import * as Styles from "./HeroStyles";
import ContentfulImage from "@/src/lib/contentful-image";
import CallToAction from "../CallToAction/CallToAction";
import BackgroundPlayer from "../BackgroundPlayer/BackgroundPlayer";
import HeadlinePlayer from "../HeadlinePlayer/HeadlinePlayer";

const Hero = props => {
  const {
    slug,
    playerId,
    headline,
    headlineMultiline,
    ctaText,
    ctaLink,
    ctaType,
    secondaryCtaText,
    secondaryCtaLink,
    secondaryCtaType,
    textColor,
    highlightColor,
    backgroundColor,
    backgroundImage,
    backgroundVideo,
    brightcoveVideo,
    brightcoveVideoOptions,
    brightcoveVideoType,
    body,
    formConfig,
    index,
    gradientOverlay,
    gradientColor,
    teaser,
    imagePosition,
    recklessMarkdownOverride,
    layout,
    lang,
  } = props;
  const options = {
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, i) => {
        return [...children, i > 0 && <br />, textSegment];
      }, []);
    },
  };
  // video behavior; looping hero or lightbox toggle?
  const [isMobile, setMobile] = useState(true);
  const toggleMobile = val => {
    setMobile(val);
  };
  useEffect(() => {
    if (window && window.innerWidth > 769 && isMobile === true) {
      toggleMobile(false);
    }
    //eslint-disable-next-line
  }, []);
  const hasHeadlineVid =
    brightcoveVideo &&
    Object.keys(brightcoveVideo).length >= 2 &&
    brightcoveVideoType !== "Lightbox";
  const hasLightboxVid =
    brightcoveVideo &&
    Object.keys(brightcoveVideo).length >= 2 &&
    brightcoveVideoType &&
    brightcoveVideoType === "Lightbox";
  const hasForm = typeof formConfig !== "undefined";
  const gradientColorHex = gradientColorStr => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      gradientColorStr
    );
    return result
      ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
      : null;
  };
  const parsePos = imagePositionStr => {
    switch (imagePositionStr) {
      case "Top":
        return "center bottom";
      case "Bottom":
        return "center top";
      default:
        return "center center";
    }
  };
  return (
    <>
      <Styles.HeroWrapper
        id={slug}
        $textColor={textColor}
        $layout={layout ? "100%" : ""}
        $backgroundColor={backgroundColor || "#08088C"}
        $highlightColor={highlightColor || "#000"}
        $gradientOverlay={gradientOverlay}
        $gradientColor={gradientColorHex(gradientColor)}
        $backgroundPosition={parsePos(imagePosition)}
      >
        {!!backgroundVideo &&
        !hasHeadlineVid &&
        !isMobile &&
        Object.keys(backgroundVideo).length >= 1 &&
        typeof backgroundVideo.file !== "undefined" &&
        (backgroundVideo.file.contentType === "video/mp4" ||
          backgroundVideo.file.contentType === "video/quicktime") ? (
          // @ts-ignore
          <BackgroundPlayer source={backgroundVideo.fields.file.url} />
        ) : null}
        {backgroundImage && layout !== "centered" && !isMobile && (
          <ContentfulImage
            className="background-image"
            alt={backgroundImage?.fields?.title || ""}
            width={backgroundImage?.fields?.file?.details.image.width || 1000}
            height={backgroundImage?.fields?.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={backgroundImage?.fields?.file?.url}
          />
        )}
        {/* if we're specifying a bg image where we have a lightbox video and background video (homepage), use it for mobile */}
        {isMobile &&
          !!backgroundVideo &&
          layout !== "centered" &&
          Object.keys(backgroundVideo).length &&
          brightcoveVideoType === "Lightbox" &&
          backgroundImage && (
            <ContentfulImage
              alt={backgroundImage?.fields?.title || ""}
              width={backgroundImage?.fields?.file?.details.image.width || 1000}
              height={
                backgroundImage?.fields?.file?.details.image.height || 1000
              }
              quality={100}
              priority={true}
              src={backgroundImage?.fields?.file?.url}
            />
          )}
        {hasHeadlineVid && (
          <HeadlinePlayer
            video={brightcoveVideo}
            options={brightcoveVideoOptions}
            mobile={isMobile}
          />
        )}
        <div>
          <Container>
            <Row>
              <Col
                lg={8}
                id={`${layout === "centered" ? "centered" : "headline-wrap"}`}
              >
                {!!headlineMultiline ? (
                  <Markdown className="headline-multiline">
                    {headlineMultiline}
                  </Markdown>
                ) : headline ? (
                  <h1>{headline}</h1>
                ) : (
                  <></>
                )}{" "}
                {/* Sorry to your linter! We were previously returning empty H1s */}
                {body && !recklessMarkdownOverride && (
                  <Markdown>{body.content[0].content[0].value}</Markdown>
                )}
                {!body && <p />}
                {recklessMarkdownOverride && (
                  <Markdown>{body.content[0].content[0].value}</Markdown>
                )}
                {/* Background */}
                {backgroundImage &&
                  backgroundImage.gatsbyImageData &&
                  layout === "centered" &&
                  !isMobile && (
                    <ContentfulImage
                      alt={backgroundImage?.fields?.title || ""}
                      width={
                        backgroundImage?.fields?.file?.details.image.width ||
                        1000
                      }
                      height={
                        backgroundImage?.fields?.file?.details.image.height ||
                        1000
                      }
                      quality={100}
                      priority={true}
                      src={backgroundImage?.fields?.file?.url}
                    />
                  )}
                {ctaText &&
                  ctaLink &&
                  !hasLightboxVid &&
                  hasHeadlineVid &&
                  !isMobile &&
                  ctaType !== "button-solid" &&
                  ctaType !== "button-outline" && (
                    // this will play the headline video, not in lightbox mode (because not on mobile)
                    <CallToAction
                      id="hero-cta"
                      callToActionCopy={ctaText}
                      iconColor={highlightColor}
                      linkPath={ctaLink}
                      iconImage={ctaType}
                      lang={lang}
                      textColor={textColor}
                      lightboxData={{
                        type: "video",
                        content: brightcoveVideo.video_id,
                        playerId,
                      }}
                      // this was causing an issue if the page had a form at the bottom
                      // lightboxData={ ctaLink == '#' && hasForm ? {type:'form', content:formConfig } : {} }
                    />
                  )}
                {ctaText &&
                  ctaLink &&
                  !hasLightboxVid &&
                  !hasHeadlineVid &&
                  ctaType !== "button-solid" && (
                    // CTA info is set + coming back from Contentful
                    <CallToAction
                      id="hero-cta"
                      callToActionCopy={ctaText}
                      iconColor={highlightColor}
                      linkPath={ctaLink}
                      iconImage={ctaType || "arrow"}
                      textColor={textColor}
                      lang={lang}
                      className={
                        ctaType === "button-outline" ||
                        ctaType === "button-solid"
                          ? ctaType
                          : ""
                      }
                      lightboxData={
                        ctaLink === "#" && hasForm
                          ? {
                              type: "form",
                              content: formConfig,
                            }
                          : {}
                      }
                    />
                  )}
                {hasHeadlineVid && isMobile && (
                  // Slightly different CTA for mobile headline video
                  <CallToAction
                    id="hero-cta"
                    callToActionCopy={ctaText}
                    iconColor={highlightColor}
                    linkPath={ctaLink}
                    lang={lang}
                    iconImage={ctaType || "play"}
                    lightboxData={{
                      type: "video",
                      content: brightcoveVideo.video_id,
                      playerId,
                    }}
                    textColor={textColor}
                    playerId={playerId}
                  />
                )}
                {ctaText &&
                  hasLightboxVid &&
                  ctaType !== "button-outline" &&
                  ctaType !== "button-solid" && (
                    //
                    <CallToAction
                      id="hero-cta"
                      callToActionCopy={ctaText}
                      iconColor={highlightColor}
                      linkPath={ctaLink}
                      lang={lang}
                      iconImage={ctaType || "play"}
                      playerId={playerId}
                      lightboxData={{
                        type: "video",
                        content: brightcoveVideo.video_id,
                        playerId,
                      }}
                      textColor={textColor}
                      className={
                        ctaType === "button-outline" ||
                        ctaType === "button-solid"
                          ? ctaType
                          : ""
                      }
                    />
                  )}
                {secondaryCtaText && secondaryCtaLink && (
                  <CallToAction
                    id="secondary-hero-cta"
                    callToActionCopy={secondaryCtaText}
                    iconColor={highlightColor}
                    linkPath={secondaryCtaLink}
                    iconImage={secondaryCtaType}
                    textColor={textColor}
                    lang={lang}
                    className={
                      secondaryCtaType === "button-outline" ||
                      secondaryCtaType === "button-solid"
                        ? secondaryCtaType
                        : ""
                    }
                  />
                )}
              </Col>
            </Row>
            {ctaText &&
              ctaLink &&
              ctaType === "button-solid" &&
              !hasHeadlineVid && (
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    {ctaText &&
                      ctaLink &&
                      ctaType === "button-solid" &&
                      !hasHeadlineVid && (
                        // CTA info is set + coming back from Contentful
                        <CallToAction
                          id="hero-cta"
                          callToActionCopy={ctaText}
                          iconColor={highlightColor}
                          linkPath={ctaLink}
                          iconImage={ctaType}
                          lang={lang}
                          textColor={textColor}
                          className={
                            ctaType === "button-outline" ||
                            ctaType === "button-solid"
                              ? ctaType
                              : ""
                          }
                          lightboxData={
                            ctaLink === "#" && hasForm
                              ? {
                                  type: "form",
                                  content: formConfig,
                                }
                              : {}
                          }
                          queryString={`hpb=${index + 1}-${slug}`}
                        />
                      )}
                    {ctaText &&
                      ctaLink &&
                      !hasLightboxVid &&
                      hasHeadlineVid &&
                      !isMobile &&
                      ctaType === "button-solid" && (
                        // this will play the headline video, not in lightbox mode (because not on mobile)
                        <CallToAction
                          id="hero-cta"
                          callToActionCopy={ctaText}
                          iconColor={highlightColor}
                          lang={lang}
                          linkPath={ctaLink}
                          iconImage={ctaType}
                          textColor={textColor}
                          queryString={`hpb=${index + 1}-${slug}`}
                          // this was causing an issue if the page had a form at the bottom
                          // lightboxData={ ctaLink == '#' && hasForm ? {type:'form', content:formConfig } : {} }
                        />
                      )}
                  </Col>
                </Row>
              )}
          </Container>
        </div>
      </Styles.HeroWrapper>
      {teaser && (
        <Styles.Teaser>
          <div className="teaser-container">
            <div className="teaser-text-wrapper">
              {teaser.title && (
                <strong className="teaser-title">{teaser.title}&nbsp;</strong>
              )}
              {teaser.body && (
                <p className="teaser-body">
                  <Markdown>{teaser.body.body}</Markdown>
                </p>
              )}
            </div>
            {teaser.link && (
              <CallToAction
                id="teaser-cta"
                callToActionCopy={teaser.label || "Learn more"}
                iconColor="#00eae4"
                lang={lang}
                linkPath={teaser.link}
                iconImage={teaser.linkType || "arrow"}
                textColor="#00eae4"
              />
            )}
          </div>
        </Styles.Teaser>
      )}
    </>
  );
};

export default Hero;
