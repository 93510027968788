import React, { useEffect, useState, useRef } from "react";
import Markdown from "markdown-to-jsx";
import { Container, Row, Col } from "react-grid-system";
import * as Styles from "./ProductFeatureGridStyles";
import Link from "next/link";
import Image from "next/image";
import Icon from "../../Global_Components/Icon/Icon";
import getLinkFromContent from "@/src/lib/getLinkFromContent";
import CallToAction from "../CallToAction/CallToAction";
import { ContentCTA } from "../ContentCTA/ContentCTA";
import ContentfulImage from "@/src/lib/contentful-image";

// TODO: This needs to be updated for the markdown error still.
const ProductFeatureGrid = props => {
  const {
    slug,
    logo,
    headline,
    textColor,
    highlightColor,
    backgroundColor,
    featureBlocks,
    animatedCta,
    blockTextAlign,
    numberOfColumns,
    infoBlockStyle,
    blockHeaderTextTransform,
    paddingTopBottom,
    bottomCtAs,
    lang,
  } = props;

  const sixthElement = useRef();
  const lastElement = useRef();
  const wrapper = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [minHeight, setMinHeight] = useState(false);
  const [maxHeight, setMaxHeight] = useState(false);
  const sanitizeLink = path => {
    if (
      path &&
      path.indexOf("http") == -1 &&
      path.indexOf("#") == -1 &&
      path[path.length - 1] !== "/"
    ) {
      path = `${path}/`;
    }
    return path;
  };
  useEffect(() => {
    if (
      typeof sixthElement.current !== "undefined" &&
      featureBlocks.length > 9
    ) {
      const wrapperTop =
        // @ts-ignore
        wrapper.current.getBoundingClientRect().top;
      const sixthTop =
        // @ts-ignore
        sixthElement.current.getBoundingClientRect().top;
      const lastTop =
        // @ts-ignore
        lastElement.current.getBoundingClientRect().top;
      // @ts-ignore
      setMinHeight(sixthTop - wrapperTop);
      // @ts-ignore
      setMaxHeight(lastTop - wrapperTop);
    }
  }, [featureBlocks.length, sixthElement, lastElement, wrapper]);

  const parseColumns = numberOfColumns => {
    switch (numberOfColumns) {
      case 3:
        return 4;
      case 4:
        return 3;
      case 2:
        return 6;
      default:
        return 4;
    }
  };
  const noCTA = block =>
    block.ctaText && block.ctaText !== "" ? "cta" : "no-cta";
  const topLogo = headline => {
    return headline === "Gear" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176.712"
        height="164.338"
        viewBox="0 0 176.712 164.338"
      >
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(-20.092 -239.659)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M117.111,329.032V313.868c0-14.591-10.944-22.812-25.357-25.736L78.845,299.907l-12.9-11.761c-14.419,2.955-25.37,11.231-25.37,25.723v15.164"
            transform="translate(29.821 74.965)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M68.342,268.523A21.255,21.255,0,1,0,89.6,289.78,21.254,21.254,0,0,0,68.342,268.523Z"
            transform="translate(40.324 43.334)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M85.255,302.275a2.234,2.234,0,0,1-1.947-1.139l-3.525-4.808a25.057,25.057,0,0,1-10.4-.052l-3.643,4.876a2.184,2.184,0,0,1-1.868,1,2.208,2.208,0,0,1-1.126-.3l-8.179-4.782A2.244,2.244,0,0,1,53.757,294l2.4-5.448a25.011,25.011,0,0,1-5.135-8.976l-6.073-.706a2.135,2.135,0,0,1-1.445-.658,2.213,2.213,0,0,1-.643-1.6l.021-9.449a2.242,2.242,0,0,1,2.245-2.239l5.94-.666a24.942,24.942,0,0,1,2.153-4.855,24.558,24.558,0,0,1,3.125-4.21l-2.4-5.608a2.218,2.218,0,0,1,.894-2.945l8.231-4.685a2.189,2.189,0,0,1,1.105-.3,2.26,2.26,0,0,1,1.957,1.134l3.52,4.816a24.905,24.905,0,0,1,10.408.05l3.645-4.881a2.162,2.162,0,0,1,1.861-1,2.291,2.291,0,0,1,1.131.3l8.176,4.782a2.253,2.253,0,0,1,.807,3.073l-2.407,5.451a24.9,24.9,0,0,1,5.14,8.976l6.07.7a2.114,2.114,0,0,1,1.442.664,2.212,2.212,0,0,1,.645,1.594l-.018,9.451a2.243,2.243,0,0,1-2.245,2.239l-5.942.669a24.029,24.029,0,0,1-5.278,9.06l2.4,5.61a2.131,2.131,0,0,1,.152,1.607,2.193,2.193,0,0,1-1.053,1.335l-8.221,4.69A2.3,2.3,0,0,1,85.255,302.275ZM74.732,261.908a10.109,10.109,0,0,0-8.7,4.986,10.062,10.062,0,0,0,8.673,15.132,10.064,10.064,0,0,0,9.739-12.611,9.981,9.981,0,0,0-4.651-6.13A10.089,10.089,0,0,0,74.732,261.908Z"
            transform="translate(33.508 0)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M76.742,294.179a36.788,36.788,0,0,0-10.9-3.93L54.8,300.317,43.781,290.265c-12.329,2.527-21.689,9.6-21.689,21.986V325.6"
            transform="translate(0 78.38)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M45.823,273.487A18.169,18.169,0,1,0,64,291.656,18.172,18.172,0,0,0,45.823,273.487Z"
            transform="translate(8.977 51.341)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M121.662,325.6V312.251c0-12.475-9.357-19.5-21.676-22L88.951,300.317,77.927,290.265a36.882,36.882,0,0,0-10.492,3.721"
            transform="translate(73.142 78.38)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M86.886,273.487a18.169,18.169,0,1,0,18.174,18.169A18.174,18.174,0,0,0,86.886,273.487Z"
            transform="translate(75.207 51.341)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_1"
            data-name="Line 1"
            y1="11.562"
            transform="translate(38.711 392.435)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_2"
            data-name="Line 2"
            y1="11.562"
            transform="translate(178.185 392.435)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            y1="11.562"
            transform="translate(89.286 392.435)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            y1="11.562"
            transform="translate(127.61 392.435)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </svg>
    ) : headline === "High five" ? (
      <svg
        className="high-five"
        xmlns="http://www.w3.org/2000/svg"
        width="260.21"
        height="136.01"
        viewBox="0 0 260.21 136.01"
      >
        <g
          id="Group_2"
          data-name="Group 2"
          transform="translate(-448.162 -359.507)"
        >
          <path
            id="Path_8"
            data-name="Path 8"
            d="M490.271,378.049c6.755,7.363,23.807,25.209,41.821,32.923V459.5"
            transform="translate(86.857 35.821)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M612.508,473.263c-.867-18.6-12.2-50.355-48.437-64.711l-15.6,8.316-18.752-17.8c-18.578-7.236-32.3-20.515-36.212-27.377"
            transform="translate(93.866 22.062)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M519.566,426.172v-32.73a54.423,54.423,0,0,1,15.53,32.73"
            transform="translate(150.292 69.153)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M456.79,426.168a54.432,54.432,0,0,1,15.533-32.724v32.724"
            transform="translate(14.357 69.157)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M537.576,480.736V428.789c36.193-12.56,48.538-44.889,49.1-46.348a10.447,10.447,0,0,0-19.455-7.622c-.481,1.206-12.247,29.5-48.089,35.548-52.682,8.895-67.964,48.63-68.974,70.37"
            transform="translate(0 14.589)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M507.421,386.71a22.376,22.376,0,1,1-22.373-22.373A22.377,22.377,0,0,1,507.421,386.71Z"
            transform="translate(27.085 6.129)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            d="M549.209,387.254a22.376,22.376,0,1,1-22.383-22.373A22.379,22.379,0,0,1,549.209,387.254Z"
            transform="translate(117.573 7.307)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            y2="7.033"
            transform="translate(577.135 361.507)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            x1="3.555"
            y2="6.065"
            transform="translate(595.064 366.883)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_7"
            data-name="Line 7"
            x2="3.555"
            y2="6.065"
            transform="translate(555.642 366.883)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </svg>
    ) : headline === "Table" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="238.893"
        height="194.404"
        viewBox="0 0 238.893 194.404"
      >
        <g
          id="Group_3"
          data-name="Group 3"
          transform="translate(-236.311 -461.042)"
        >
          <path
            id="Path_15"
            data-name="Path 15"
            d="M253.52,481.566a18.519,18.519,0,1,0,18.521-18.524A18.516,18.516,0,0,0,253.52,481.566Z"
            transform="translate(37.757)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M311.093,506.905v-9.079c0-10.208-7.585-18.67-20.363-21.338l-10.145,8-10.159-8c-13.269,2.758-20.349,11.559-20.349,21.338v9.079"
            transform="translate(29.212 33.381)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M279.914,481.566a18.519,18.519,0,1,0,18.517-18.524A18.515,18.515,0,0,0,279.914,481.566Z"
            transform="translate(103.283)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M337.484,506.905v-9.079c0-10.208-7.585-18.67-20.363-21.338l-10.145,8-10.159-8c-13.265,2.758-20.345,11.559-20.345,21.338v9.079"
            transform="translate(94.738 33.381)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_19"
            data-name="Path 19"
            d="M247.1,526.714v-2.79c0-9.932,7.223-19.865,26.642-19.865h17.009c19.113,0,26.443,9.257,26.443,19.837v2.817"
            transform="translate(21.826 101.832)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M271.435,529.8a20.006,20.006,0,1,0-20.015-20A20,20,0,0,0,271.435,529.8Z"
            transform="translate(32.543 66.407)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M245.75,537.889V519.818a9.429,9.429,0,0,1,9.427-9.424h60.657a9.427,9.427,0,0,1,9.427,9.424v18.071"
            transform="translate(18.467 117.557)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_22"
            data-name="Path 22"
            d="M276.842,526.714v-2.79c0-9.932,7.219-19.865,26.638-19.865h17.006c19.109,0,26.444,9.257,26.444,19.837v2.817"
            transform="translate(95.656 101.832)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M301.168,529.8a20.006,20.006,0,1,0-20.011-20A20,20,0,0,0,301.168,529.8Z"
            transform="translate(106.369 66.407)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M275.489,537.889V519.818a9.428,9.428,0,0,1,9.424-9.424h60.66A9.429,9.429,0,0,1,355,519.818v18.071"
            transform="translate(92.297 117.557)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M273.847,558.128H238.311l25.026-72.907H448.179L473.2,558.128H437.929"
            transform="translate(0 55.064)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            x2="42.254"
            transform="translate(334.764 613.192)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="242.622"
        height="167.606"
        viewBox="0 0 242.622 167.606"
      >
        <g
          id="Group_4"
          data-name="Group 4"
          transform="translate(-230.794 -243.211)"
        >
          <path
            id="Path_26"
            data-name="Path 26"
            d="M292.785,260.236a11.991,11.991,0,1,1-11.99-11.993A11.987,11.987,0,0,1,292.785,260.236Z"
            transform="translate(72.315 6.086)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M308.16,273.327c0-7.575-5.536-12.663-13.364-14.654l-7.843,6.953-7.852-6.953c-7.825,1.991-13.364,7.079-13.364,14.654"
            transform="translate(66.162 27.022)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M366.812,327.754l-4.375-31.4c-2.2-13.942-10.044-20.058-20.44-20.058-7.572,0-14.543,4.24-18.516,14.071l-9.677,22.9s-18.116,1.5-24.076,2.529c-3.242.556-4.83,3.028-4.83,7.091,0,4.863,3.13,7.485,8.477,7.485H320.97c4.015,0,7.088-2.261,9.049-6.273l9.139-19.8"
            transform="translate(104.623 62.397)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M330.589,280.221A18.792,18.792,0,1,1,311.8,261.33,18.839,18.839,0,0,1,330.589,280.221Z"
            transform="translate(120.894 32.355)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M305.774,287.056a15.547,15.547,0,1,1,11.918-15.126"
            transform="translate(108.389 22.441)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_31"
            data-name="Path 31"
            d="M319.622,269.344c-3.167,1.813-5.888,4.953-7.78,9.638l-7.96,18.843s-14.9,1.23-19.806,2.075c-2.664.457-3.97,2.493-3.97,5.834,0,4,2.577,6.156,6.974,6.156h22.7c3.3,0,5.831-1.859,7.443-5.16l5.35-11.581"
            transform="translate(95.006 48.441)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_9"
            data-name="Line 9"
            x1="1.624"
            y2="8.945"
            transform="translate(393.54 369.445)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_10"
            data-name="Line 10"
            x1="1.301"
            y2="6.443"
            transform="translate(379.082 341.898)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M232.775,327.754l4.376-31.4c2.2-13.942,10.044-20.058,20.44-20.058,7.572,0,14.543,4.24,18.516,14.071l9.677,22.9s18.116,1.5,24.076,2.529c3.242.556,4.83,3.028,4.83,7.091,0,4.863-3.131,7.485-8.477,7.485H278.617c-4.015,0-7.088-2.261-9.049-6.273l-9.139-19.8"
            transform="translate(0 62.397)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M239.41,280.221A18.792,18.792,0,1,0,258.2,261.33,18.839,18.839,0,0,0,239.41,280.221Z"
            transform="translate(13.318 32.355)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M259.775,287.056a15.547,15.547,0,1,0-11.918-15.126"
            transform="translate(30.273 22.441)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_35"
            data-name="Path 35"
            d="M253.817,269.344c3.167,1.813,5.888,4.953,7.78,9.638l7.96,18.843s14.9,1.23,19.806,2.075c2.664.457,3.97,2.493,3.97,5.834,0,4-2.577,6.156-6.974,6.156h-22.7c-3.3,0-5.831-1.859-7.443-5.16l-5.627-12.182"
            transform="translate(35.765 48.441)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_11"
            data-name="Line 11"
            x2="1.624"
            y2="8.945"
            transform="translate(309.047 369.445)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_12"
            data-name="Line 12"
            x2="1.301"
            y2="6.443"
            transform="translate(323.827 341.898)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_36"
            data-name="Path 36"
            d="M254.364,279.386V245.211H363.177v34.031"
            transform="translate(43.335 0)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_13"
            data-name="Line 13"
            x2="99.347"
            transform="translate(302.445 307.094)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_37"
            data-name="Path 37"
            d="M408.762,293.865l8.925,17.292H243.493l8.934-17.292"
            transform="translate(21.514 97.66)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Path_38"
            data-name="Path 38"
            d="M255.718,282.815l5.139-10.185H351.25l5.219,10"
            transform="translate(46.052 55.038)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_14"
            data-name="Line 14"
            x1="8.182"
            y2="15.999"
            transform="translate(284.181 355.88)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_15"
            data-name="Line 15"
            x2="8.197"
            y2="15.926"
            transform="translate(411.891 355.809)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </svg>
    );
  };
  if (slug === "our-differences-are-what-makes-us-strong") {
    return (
      <Styles.ProductFeatureGrid
        id={slug}
        $textColor={textColor}
        $backgroundColor={backgroundColor}
        $paddingTopBottom={paddingTopBottom}
      >
        <Container>
          <Row>
            <Col className="feature-grid-header">
              {logo && logo.file && logo.file.url && (
                <Image
                  src={logo.file.url}
                  alt={logo.title}
                  width={20}
                  height={20}
                />
              )}
              {headline && headline.length > 0 && (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: headline,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row align="stretch">
            <Styles.ProductFeatureGridWrapper
              isOpen
              minHeight={minHeight}
              maxHeight={maxHeight}
              className="our-differences-are-what-makes-us-strong-wrapper"
              // @ts-ignore
              ref={wrapper}
            >
              {featureBlocks &&
                featureBlocks.map((key, index, arr) => {
                  return (
                    <Col
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: `10px` }}
                      className={`feature-grid-block svgs ${noCTA(
                        key.fields
                      )} ${
                        (key.fields.body && key.fields.body.length > 135) ||
                        (key.fields.headline && key.fields.headline.length > 40)
                          ? "tall"
                          : ""
                      }`}
                      key={index}
                    >
                      <Link
                        prefetch
                        href={
                          key.fields.ctaLink
                            ? sanitizeLink(key.fields.ctaLink)
                            : ""
                        }
                        aria-disabled={true}
                        style={{
                          // @ts-ignore
                          textAlign: `${blockTextAlign || "left"}`,
                          pointerEvents: "none",
                        }}
                      >
                        <div className="svg-header">
                          {topLogo(key.fields.headline)}

                          {/* <TopLogo
														headline={key.fields.headline}
													/> */}
                        </div>
                        {key.fields.body && (
                          <div className="blurb-wrapper">
                            <Markdown>{key.fields.body}</Markdown>
                          </div>
                        )}

                        {key.fields.ctaText && key.fields.ctaText !== " " && (
                          <>
                            {/* <Link prefetch href={key.fields.ctaLink}> */}
                            <p className="cta-link">
                              <hr />
                              <Icon
                                className={""}
                                iconImage="arrow"
                                iconColor={highlightColor}
                              />
                              <Icon
                                className={""}
                                iconImage="arrow"
                                iconColor={backgroundColor}
                              />
                              {/* {key.fields.ctaText} */}
                              {key.fields.headline}
                            </p>
                            {/* </Link> */}
                          </>
                        )}
                      </Link>
                      {/* {index === 5 && <div ref={sixthElement} />}
                {index === arr.length - 1 && <div ref={lastElement} />} */}
                    </Col>
                  );
                })}
            </Styles.ProductFeatureGridWrapper>
          </Row>
          {/* {featureBlocks && featureBlocks.length > 9 && (
          <Row>
            <Col className="show-more">
              <CallToAction
                callToActionCopy={isOpen ? t('Show less') : t('Show all')}
                iconImage={isOpen ? 'minus' : 'plus'}
                iconColor={highlightColor}
                linkFunction={() => setIsOpen(!isOpen)}
                textColor={textColor}
              />
            </Col>
          </Row>
        )} */}
        </Container>
      </Styles.ProductFeatureGrid>
    );
  }
  const reverseColorLookup = {
    "#ffffff": "White",
    "#08088c": "Navy",
  };
  const AnimCTAFeatureBlock = props => {
    const { block, key, index, arr } = props;
    const { contentCta } = block;
    return (
      <Col
        lg={3}
        md={4}
        sm={6}
        xs={12}
        style={{ padding: `10px` }}
        className={`feature-grid-block hover ${noCTA(block)} ${
          (block.body && block.body.length > 135) ||
          (block.headline && block.headline.length > 40)
            ? "tall"
            : ""
        }`}
        key={`feature-block-${key}`}
      >
        {contentCta ? (
          <Link
            prefetch
            to={getLinkFromContent({
              ...contentCta.fields,
              lang,
            })}
            style={{
              // @ts-ignore
              textAlign: `${blockTextAlign || "left"}`,
            }}
          >
            <h3>
              <Markdown>
                {block.ctaText ? block.ctaText : block.headline}
              </Markdown>
            </h3>
            {block.body && <Markdown>{block.body}</Markdown>}
            {block.ctaText && block.ctaText !== " " && (
              <>
                {/* <Link prefetch href={block.ctaLink}> */}
                <p className="cta-link">
                  <hr />
                  <Icon
                    className={""}
                    iconImage="arrow"
                    iconColor={highlightColor}
                  />
                  <Icon
                    className={""}
                    iconImage="arrow"
                    iconColor={backgroundColor}
                  />
                  {/* {block.ctaText} */}
                  {block.headline}
                </p>
                {/* </Link> */}
              </>
            )}
          </Link>
        ) : (
          <a
            href={sanitizeLink(block.ctaLink)}
            style={{
              // @ts-ignore
              textAlign: `${blockTextAlign || "left"}`,
            }}
          >
            <h3>
              <Markdown>
                {block.ctaText ? block.ctaText : block.headline}
              </Markdown>
            </h3>
            {block.body && <Markdown>{block.body}</Markdown>}
            {block.ctaText && block.ctaText !== " " && (
              <>
                {/* <Link prefetch href={block.ctaLink}> */}
                <p className="cta-link">
                  <hr />
                  <Icon
                    className={""}
                    iconImage="arrow"
                    iconColor={highlightColor}
                  />
                  <Icon
                    className={""}
                    iconImage="arrow"
                    iconColor={backgroundColor}
                  />
                  {/* {block.ctaText} */}
                  {block.headline}
                </p>
                {/* </Link> */}
              </>
            )}
          </a>
        )}

        {/* {index === 5 && <div ref={sixthElement} />}
                {index === arr.length - 1 && <div ref={lastElement} />} */}
      </Col>
    );
  };
  const StyledFeatureBlock = props => {
    const { block, key, index, arr, blockStyle, blockHeaderTextTransform } =
      props;
    const { headline, subHeadline } = block;
    return (
      <>
        {" "}
        {block.image && (
          <div
            className={`block-image-wrapper ${
              block.squareImage ? "squareImage" : ""
            }`}
          >
            <Image
              width={150}
              height={150}
              src={block.image.fields.file.url}
              className={
                infoBlockStyle === "Image/header/body/cta"
                  ? "block-image"
                  : "block-svg"
              }
              alt="image-product"
            />
          </div>
        )}
        <div
          className="block-text-container"
          style={{
            // @ts-ignore
            textAlign: `${blockTextAlign || "left"}`,
            color: `${textColor || "unset"}`,
          }}
        >
          <h4
            className={block.boldTitle ? "boldTitle" : ""}
            style={{
              textTransform: blockHeaderTextTransform || null,
            }}
          >
            <Markdown>{headline}</Markdown>
          </h4>
          {subHeadline && (
            <h5>
              <Markdown>{subHeadline}</Markdown>
            </h5>
          )}
          {block.body && (
            <Markdown
              className={`block-body ${block.boldBody ? "boldBody" : ""}`}
            >
              {block.body}
            </Markdown>
          )}
          {!block.contentCta && block.ctaText && block.ctaText !== " " && (
            <CallToAction
              linkPath={block.ctaLink}
              callToActionCopy={block.ctaText}
              className="cta"
              lang={lang || "en"}
              iconColor={textColor || null}
              textColor={textColor || null}
            />
          )}
          {block.contentCta && (
            <ContentCTA
              contentCta={{
                ...block.contentCta,
                ctaColor: reverseColorLookup[textColor],
                lang,
              }}
            />
          )}
        </div>
      </>
    );
  };
  const StatsFeatureBlock = props => {
    const { block, key, index, arr, blockStyle, blockHeaderTextTransform } =
      props;
    const { headline, subHeadline } = block;

    return (
      <>
        {" "}
        {block.image && (
          <div
            className={`block-image-wrapper ${
              block.squareImage ? "squareImage" : ""
            }`}
          >
            <Image
              width={150}
              height={150}
              src={block.image.file.url}
              className={
                infoBlockStyle === "Image/header/body/cta"
                  ? "block-image"
                  : "block-svg"
              }
              alt="image-product"
            />
          </div>
        )}
        <div
          className="block-text-container percentage"
          style={{
            // @ts-ignore
            textAlign: `${blockTextAlign || "left"}`,
            color: `${textColor || "unset"}`,
          }}
        >
          <span
            className={block.boldTitle ? "boldTitle" : ""}
            style={{
              textTransform: blockHeaderTextTransform || null,
            }}
          >
            <Markdown>{headline}</Markdown>
          </span>
          {!block.contentCta && block.ctaText && block.ctaText !== " " && (
            <CallToAction
              linkPath={block.ctaLink}
              callToActionCopy={block.ctaText}
              className="cta"
              lang={lang || "en"}
              iconColor={textColor || null}
              textColor={textColor || null}
            />
          )}
          {block.contentCta && (
            <ContentCTA
              contentCta={{
                ...block.contentCta,
                ctaColor: reverseColorLookup[textColor],
                lang,
              }}
            />
          )}
        </div>
      </>
    );
  };
  const DefaultFeatureBlock = props => {
    const { block, key, index, arr } = props;
    return (
      <div
        // @ts-ignore
        style={{ textAlign: `${blockTextAlign || "left"}` }}
      >
        {/* {block.image && <img src={block.image.file.url} />} */}
        <h3>
          <Markdown>{block.headline}</Markdown>
        </h3>
        {block.body && <Markdown>{block.body}</Markdown>}
        {block.ctaText && block.ctaText !== " " && (
          <p>
            <Icon className={""} iconImage="arrow" iconColor={highlightColor} />
            <Icon
              className={""}
              iconImage="arrow"
              iconColor={backgroundColor}
            />
            {block.ctaText}
          </p>
        )}
        {/* @ts-ignore */}
        {index === 5 && <div ref={sixthElement} />}
        {index === arr.length - 1 && (
          // @ts-ignore
          <div ref={lastElement} />
        )}
      </div>
    );
  };

  const CardFeatureBlock = props => {
    const { block } = props;
    const { subHeadline, contentCta } = block;
    return (
      <>
        {block.image && (
          <div className="image-block">
            <Image
              src={block.image.fields.file.url}
              alt="image-product"
              width={110}
              height={110}
            />
          </div>
        )}
        {/* Logo */}
        {block.logo && (
          <div className="block-logo">
            <ContentfulImage
              alt={block.logo.fields.title || ""}
              width={block.logo.fields.file?.details.image.width || 1000}
              height={block.logo.fields.file?.details.image.height || 1000}
              quality={100}
              priority={true}
              src={block.logo.fields.file?.url}
            />
            {/* <img src={block.logo.file.url} alt="image-product" /> */}
          </div>
        )}
        {/* Wrapper text with cta */}
        <div
          className="block-text-container"
          style={{
            // @ts-ignore
            textAlign: `${blockTextAlign || "left"}`,
            color: `${textColor || "unset"}`,
          }}
        >
          {subHeadline && (
            <h5>
              <Markdown>{subHeadline}</Markdown>
            </h5>
          )}
          {block.body && (
            <Markdown
              className={`block-body ${block.boldBody ? "boldBody" : ""}`}
            >
              {block.body}
            </Markdown>
          )}
          {!contentCta && block.ctaText && block.ctaText !== " " && (
            <CallToAction
              linkPath={block.ctaLink}
              callToActionCopy={block.ctaText}
              className="cta"
              iconColor={textColor || null}
              textColor={textColor || null}
              lang={lang}
            />
          )}
          {contentCta && (
            <ContentCTA
              contentCta={{
                ...contentCta.fields,
                ctaColor: reverseColorLookup[textColor],
                lang,
              }}
            />
          )}
        </div>
      </>
    );
  };
  return (
    <Styles.ProductFeatureGrid
      id={slug}
      // logo={logo}
      // $headline={headline}
      $textColor={textColor}
      // $highlightColor={highlightColor}
      $backgroundColor={backgroundColor}
      // $featureBlocks={featureBlocks}
      $paddingTopBottom={paddingTopBottom}
      className={`${infoBlockStyle ? "styled-feature-blocks" : ""}`}
    >
      <Container>
        {(headline || logo) && (
          <Row>
            <Col className="feature-grid-header">
              {logo && logo.fields.file && logo.fields.file.url && (
                <Image
                  src={logo.fields.file.url}
                  alt={logo.fields.title}
                  width={20}
                  height={20}
                />
              )}
              {headline && headline.length > 0 && (
                <h2 className="default-feature-headline">{headline}</h2>
              )}
            </Col>
          </Row>
        )}
        <Row align="stretch">
          <Styles.ProductFeatureGridWrapper
            isOpen
            minHeight={minHeight}
            maxHeight={maxHeight}
            // @ts-ignore
            ref={wrapper}
          >
            {animatedCta &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => (
                <AnimCTAFeatureBlock
                  block={key.fields}
                  arr={arr}
                  key={key}
                  index={index}
                />
              ))}
            {infoBlockStyle &&
              infoBlockStyle === "Percentage/headline" &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => {
                const { blockStyle, ctaLink, contentCta } = key.fields;
                const blockStyleClass = blockStyle
                  ? blockStyle.split("/").join("-").toLowerCase()
                  : false;
                return (
                  <Col
                    lg={parseColumns(numberOfColumns)}
                    md={6}
                    sm={6}
                    key={key}
                    xs={12}
                    className={`styled-feature-block ${blockStyleClass}`}
                  >
                    {!contentCta && (
                      <a href={ctaLink}>
                        <StatsFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </a>
                    )}
                    {contentCta && (
                      <Link
                        prefetch
                        href={getLinkFromContent({
                          ...contentCta.fields,
                          lang,
                        })}
                      >
                        <StatsFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </Link>
                    )}
                  </Col>
                );
              })}
            {!animatedCta &&
              infoBlockStyle &&
              infoBlockStyle !== "Image/logo/body/cta" &&
              infoBlockStyle !== "Percentage/headline" &&
              infoBlockStyle !== "headline/body" &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => {
                const { blockStyle, ctaLink, contentCta } = key.fields;
                const blockStyleClass = blockStyle
                  ? blockStyle.split("/").join("-").toLowerCase()
                  : false;
                return (
                  <Col
                    lg={parseColumns(numberOfColumns)}
                    md={6}
                    sm={6}
                    key={key}
                    xs={12}
                    className={`styled-feature-block ${blockStyleClass}`}
                  >
                    {!contentCta && (
                      <a href={ctaLink}>
                        <StyledFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </a>
                    )}
                    {contentCta && (
                      <Link
                        prefetch
                        href={getLinkFromContent({
                          ...contentCta.fields,
                          lang,
                        })}
                      >
                        <StyledFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </Link>
                    )}
                  </Col>
                );
              })}

            {!animatedCta &&
              !infoBlockStyle &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => {
                const { contentCta } = key.fields;
                return (
                  <Col
                    key={key}
                    lg={parseColumns(numberOfColumns)}
                    md={6}
                    sm={6}
                    xs={12}
                    className={`feature-grid-block ${noCTA(key.fields)}`}
                  >
                    {!contentCta && (
                      <a href={key.fields.ctaLink}>
                        <DefaultFeatureBlock
                          block={key.fields}
                          arr={arr}
                          key={key}
                          index={index}
                        />
                      </a>
                    )}
                    {contentCta && (
                      <Link
                        prefetch
                        href={getLinkFromContent({
                          ...contentCta.fields,
                          lang,
                        })}
                      >
                        <DefaultFeatureBlock
                          block={key.fields}
                          arr={arr}
                          key={key}
                          index={index}
                        />
                      </Link>
                    )}
                  </Col>
                );
              })}
            {!animatedCta &&
              infoBlockStyle &&
              infoBlockStyle === "Image/logo/body/cta" &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => {
                const { contentCta, ctaLink } = key.fields;
                const blockStyleClass = infoBlockStyle
                  .split("/")
                  .join("-")
                  .toLowerCase();

                return (
                  <div key={index} className={`${blockStyleClass}`}>
                    {!contentCta && (
                      <a href={ctaLink}>
                        <CardFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </a>
                    )}
                    {contentCta && (
                      <Link
                        prefetch
                        href={getLinkFromContent({
                          ...contentCta.fields,
                          lang,
                        })}
                      >
                        <CardFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                        />
                      </Link>
                    )}
                  </div>
                );
              })}
            {!animatedCta &&
              infoBlockStyle &&
              infoBlockStyle !== "Image/logo/body/cta" &&
              infoBlockStyle !== "Percentage/headline" &&
              infoBlockStyle === "headline/body" &&
              featureBlocks &&
              featureBlocks.map((key, index, arr) => {
                const { blockStyle, ctaLink, contentCta } = key.fields;
                const blockStyleClass = blockStyle
                  ? blockStyle.split("/").join("-").toLowerCase()
                  : false;
                return (
                  <Col
                    lg={parseColumns(numberOfColumns)}
                    md={6}
                    sm={6}
                    key={key}
                    xs={12}
                    className="styled-feature-block headline-body"
                  >
                    {!contentCta && (
                      <a href={ctaLink}>
                        <StyledFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </a>
                    )}
                    {contentCta && (
                      <Link
                        prefetch
                        href={getLinkFromContent({
                          ...contentCta.fields,
                          lang,
                        })}
                      >
                        <StyledFeatureBlock
                          block={key.fields}
                          arr={arr}
                          index={index}
                          blockStyle={infoBlockStyle}
                          blockHeaderTextTransform={blockHeaderTextTransform}
                        />
                      </Link>
                    )}
                  </Col>
                );
              })}
            {bottomCtAs && (
              <Row>
                {bottomCtAs &&
                  bottomCtAs.map(cta => {
                    const colSize =
                      bottomCtAs.length < 4 ? 12 / bottomCtAs.length : 4;
                    return (
                      <Col
                        lg={colSize}
                        md={6}
                        sm={12}
                        key={cta.id}
                        className="cta-wrapper"
                      >
                        <ContentCTA contentCta={cta} />
                      </Col>
                    );
                  })}
              </Row>
            )}
          </Styles.ProductFeatureGridWrapper>
        </Row>
      </Container>
    </Styles.ProductFeatureGrid>
  );
};
export default ProductFeatureGrid;
