import {
  defaultStyles,
  textContentFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleMultiColumn = styled("section")<{
  $backgroundColor?: "Black" | "White" | "Grey";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $variant?: "Asset";
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "row",
      gap: "40px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })};
  flex-wrap: wrap;
  row-gap: 50px;
  justify-content: center;
  .content-section {
    p {
      ${textContentFontStyles()}
    }
  }

  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "30px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
  }
  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "20px",
        direction: "column",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
  }
`;
