import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

const ImageTextSplitSection = styled("section")<{
  $fitImageToTextFullWidth: string | undefined;
  $float: string | undefined;
  $verticalMargin: string | undefined;
  $layout: string | undefined;
  $overFlowDirection: string | null;
  $image: string | undefined;
}>`
	margin: ${({ $verticalMargin }) => ($verticalMargin ? $verticalMargin : "30px")}
		auto;
	display: flex;
	max-width: 1280px;
	@media only screen and (max-width: 1280px) {
		padding: 0 30px;
	}
	flex-direction: ${props => {
    switch (props.$layout) {
      case "Text left, image right":
        return "row-reverse;";
      case "Text right, image left":
        return "row;";
      default:
        return "unset";
    }
  }};
	@media only screen and (${BREAK_POINTS.MOBILE}) {
		flex-direction: ${props => {
      switch (props.$layout) {
        case "Text left, image right":
          return "column;";
        case "Text right, image left":
          return "column;";
        default:
          return "unset";
      }
    }};
		padding: 30px;
		> .image-wrapper {
			padding: 0px !important;
		}
	}
	> .image-wrapper,
	> .text-wrapper {
		flex-basis: 50%;
	}
	> .text-wrapper {
		line-height: 1.3;
		flex-direction: column;
		justify-content: center;
		padding: 0 30px;
		display: flex;
		@media only screen and (${BREAK_POINTS.MOBILE}) {
      padding: 20px 0 0 0;
    }
			> .header,
			.body {
				margin-bottom: 20px;
				ul {
					margin: 20px 0px 20px 20px;
					list-style: disc;
					font-weight: 300;
					line-height: 1.3;
					li {
						font-weight: 300;
						strong {
							font-weight: 700;
						}
					}
				}
			}
			> .header {
				font-size: 21px;
				font-family: "articulat-cf";
				font-weight: 700;
			}
			.body {
				font-size: 18px;
				line-height: 1.3;
			}
			#hero-cta {
				p {
					font-size: 16px;
					font-weight: 500 !important;
					line-height: 1.3;
					font-family: "articulat-cf";
					text-align: left;
				}

				&:hover {
					text-decoration: none;
					p:after {
						width: 0%;
					}
				}
			}
		}
		> .image-wrapper {
			transform: ${props => {
        switch (props.$overFlowDirection) {
          case "right":
            return "translateX(125px);";
          case "left":
            return "translateX(-125px);;";
          default:
            return "unset";
        }
      }};
			@media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
				transform: ${props => {
          switch (props.$overFlowDirection) {
            case "right":
              return "translateX(100px);";
            case "left":
              return "translateX(-100px);;";
            default:
              return "unset";
          }
        }};
			}
			@media only screen and (${BREAK_POINTS.MOBILE}) {
				transform: unset;
				.text-wrapper {
					margin-top: 20px;
				}
				img {
					width: 100%;
					margin: 0;
					max-width: 100%;
				}
			}
		}
	}
	/* FLOAT STYLES */
	/* If float is set to true, override display: flex and float the image to the left 
  or right, based off the $ value. At 1150px, the text column becomes too narrow,
  so we move it below the image at this point and center the image. */
	${({ $float, $layout }) =>
    $float
      ? `
      display: block;
    overflow: auto;
    > .text-wrapper {
      display: block;
    }  
    > .text-wrapper > .header {
      text-align: left !important;
    }  

    > .image-wrapper {
      float: ${$layout === "Text left, image right" ? "right" : "left"};
      width: 50%;
      padding-${$layout === "Text left, image right" ? "left" : "right"}: 30px;
    }

    > .text-wrapper .body ul {
      margin-left: 1em;
    }
    
    > .text-wrapper .body li {
      position: relative;
      left: 1em;
      padding-right: 1em; 
    }

    @media only screen and (max-width: 1150px) {
    > .text-wrapper {
      word-wrap: normal;
    }

    > .image-wrapper {
      width: 100%;
      display: flex;
      padding: 0;
    }
  }`
      : null}

	/* Mobile: max-width:  769px */
  @media only screen and (max-width: 769px) {
		> .text-wrapper {
			margin-top: 20px;
		}
	}

	.image-fit-wrapper {
		width: 50%;
		background-size: cover;
		background-position: center center;
		background-image: url(${({ $image }) => ($image ? $image : "")});
		@media only screen and (${BREAK_POINTS.MOBILE}) {
			aspect-ratio: 1/1;
			width: 100%;
			margin: 0;
		}
	}

	${({ $fitImageToTextFullWidth, $layout }) =>
    $fitImageToTextFullWidth
      ? `
      max-width: 100%;
    > .image-fit-to-text-wrapper {
    }

    > .image-fit-to-text-image-wrapper {
      margin-right: 0;
    }
  }`
      : null}
`;
export default ImageTextSplitSection;
