import React from "react";

import { ImageCard } from "./ResourceImage";

import * as Styles from "./ResourceCardStyles";
import Markdown from "markdown-to-jsx";
import getLinkFromContent from "@/src/lib/getLinkFromContent";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";
import Icon from "@/src/components/Global_Components/Icon/Icon";

export const ResourceCard = props => {
  const {
    header,
    backgroundColor,
    type,
    downloadType,
    tags,
    textColor,
    image,
    body,
    publishDate,
    ctaLabel,
    slug,
    videoProducer,
    verboseCta,
    playTv,
  } = props.card.fields;
  const { lang } = props;
  const setLink = cardType => {
    if (cardType === "videoLandingPage") {
      return `/${lang}/resources/resource-center/videos/${slug}`;
    } else if (cardType === "productPage") {
      return `/${lang}/${slug}/`;
    } else if (cardType === "downloadPage") {
      return `/${lang}/resources/downloads/${downloadType.toLowerCase()}/${slug}/`;
    } else if (cardType === "resourceCard") {
      if (verboseCta.fields.externalLink) {
        return verboseCta.fields.externalLink;
      } else {
        return getLinkFromContent({
          linkTo: verboseCta.fields.linkTo,
          staticPage: verboseCta.fields.staticPage,
          lang: lang,
        });
      }
    }
  };

  const LinkWrapper = () => {
    if ((verboseCta && !verboseCta.fields.externalLink) || !verboseCta) {
      return (
        <a
          target="_blank"
          className="link-wrapper"
          href={setLink(props.card.sys.contentType.sys.id)}
        ></a>
      );
    } else {
      return (
        <a
          target={
            verboseCta && verboseCta.fields.noExternalLink ? "_blank" : "_blank"
          }
          className="link-wrapper"
          href={verboseCta.fields.externalLink}
        ></a>
      );
    }
  };
  return (
    <Styles.ResourceCard
      $backgroundColor={backgroundColor}
      $textColor={textColor}
    >
      <div className="wrapper-card">
        {playTv ||
          (videoProducer === "PLAYTV" && (
            // eslint-disable-next-line
            <img
              className="playTv"
              src="https://images.ctfassets.net/ov6oufbwq1qf/5pQ8BqmkOm5QSM7pJDrA2a/501eb182793c314a5832df3796392fee/PLAY_TV_by_Brightcove_Logo_White.png"
              alt="PlayTv"
            />
          ))}
        {image ? (
          // eslint-disable-next-line
          <img
            src={image.fields.file.url}
            alt={image.fields.description}
            className="resourceImage"
          />
        ) : (
          ImageCard(type)
        )}
        <LinkWrapper />
        <div className="wrapper-text">
          {type && <div className="type">{type}</div>}
          {header && (
            <div className="header-card">
              <Markdown>{header}</Markdown>
            </div>
          )}
          {body && (
            <div className="body">
              <Markdown>{body}</Markdown>
            </div>
          )}
        </div>
        <div className="wrapper-tag">
          {/* Uncomment if date */}
          {/* {publishDate && <div className="date">{moment(publishDate).format(`l`)}</div>}  */}
          {verboseCta && <VerboseCTA {...verboseCta.fields} lang={lang} />}
          {ctaLabel && slug && (
            <a
              target={"_blank"}
              href={setLink(props.card.sys.contentType.sys.id)}
            >
              <div className="cta-wrapper">
                <div className="cta-icon-wrapper">
                  <Icon className={""} iconColor={""} iconImage={""} />
                </div>
                <div className="cta-text-wrapper">
                  <Markdown>{ctaLabel}</Markdown>
                </div>
              </div>
            </a>
          )}
          {tags && (
            <div className="tag-wrapper">
              {tags.map((tag, i) => {
                return (
                  <button
                    key={i}
                    onClick={() => props.tagOnClick(tag.fields.name)}
                    className="tag"
                  >
                    {tag.fields.name}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Styles.ResourceCard>
  );
};
