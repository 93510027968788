import React from "react";
import Slider from "react-slick";
import Image from "next/image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Container } from "react-grid-system";
import Link from "next/link";
import ContentfulImage from "@/src/lib/contentful-image";

const LogoCarousel = styled.div`
  /* Arrow Before */
  .slick-next:before {
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  /* Arrow after  */
  .slick-prev:before {
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
`;
const CarouselSlick = ({ items, lang }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <LogoCarousel>
        <Slider {...settings}>
          {items &&
            items.map(element => {
              let elementLink = "";
              if (element.fields.slug) {
                elementLink = `/${lang}/partners/${element.fields.slug}/`;
              } else {
                elementLink =
                  element.fields.story &&
                  element.fields.story[0] &&
                  element.fields.story[0].slug
                    ? `/${lang}/customers/${element.fields.story[0].slug}/`
                    : "";
              }
              return (
                <div key={`element-grid-${Math.random()}`}>
                  {elementLink !== "" && (
                    <Link prefetch href={elementLink}>
                      {element.fields.logo ? (
                        <ContentfulImage
                          alt={element.fields.logo.fields.title || ""}
                          width={
                            element.fields.logo.fields.file?.details.image
                              .width || 1000
                          }
                          height={
                            element.fields.logo.fields.file?.details.image
                              .height || 1000
                          }
                          quality={100}
                          priority={true}
                          src={element.fields.fields.logo.fields.file?.url}
                        />
                      ) : (
                        <Image
                          width={100}
                          height={100}
                          key={`img-grid-${Math.random()}`}
                          src={element.fields.logoUrl}
                          alt={element.fields.name}
                          title={`Read ${element.fields.name} customer story`}
                        />
                      )}
                    </Link>
                  )}
                  {elementLink === "" &&
                    (element.fields.logo ? (
                      <ContentfulImage
                        alt={element.fields.logo.fields.title || ""}
                        width={
                          element.fields.logo.fields.file?.details.image
                            .width || 1000
                        }
                        height={
                          element.fields.logo.fields.file?.details.image
                            .height || 1000
                        }
                        quality={100}
                        priority={true}
                        src={element.fields.logo.fields.file?.url}
                      />
                    ) : (
                      <Image
                        width={100}
                        height={100}
                        key={`img-grid-${Math.random()}`}
                        src={element.fields.logoUrl}
                        alt={element.fields.name}
                      />
                    ))}
                </div>
              );
            })}
        </Slider>
      </LogoCarousel>
    </Container>
  );
};

export default CarouselSlick;
