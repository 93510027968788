"use client";
import React, { useState, useRef } from "react";
import * as Styles from "./ContentFilterStyles";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const FilterHolder = ({ obj, arr, callback }) => {
  const [showFilter, setShowFilter] = useState(false);
  const filterContainer = useRef(null);

  return (
    <Styles.CheckBoxHolder
      className={`${showFilter ? "open" : ""}`}
      tabIndex={10}
      ref={filterContainer}
      onFocus={() => {
        setTimeout(() => {
          if (!showFilter) {
            setShowFilter(true);
          }
        }, 250);
      }}
      onBlur={() => {
        setTimeout(() => {
          if (showFilter) {
            setShowFilter(false);
          }
        }, 250);
      }}
    >
      <ul className="inner" role="listbox">
        <Styles.CloseButton onClick={() => setShowFilter(false)} />
        {/* <Styles.FilterHeader>FILTER BY</Styles.FilterHeader> */}
        {/* <hr/>
        <Styles.OverlayLabel>{obj.label}</Styles.OverlayLabel> */}
        {obj.values.map((value, index) => {
          return (
            arr && (
              <li key={`${obj.label} ${value}`}>
                <ToggleSwitch
                  id={`${obj.label} ${value}`}
                  checked={arr.includes(value)}
                  onChange={() => callback(value, index)}
                  value={value}
                  name={value}
                  tabindex="-1"
                  role="option"
                  type="checkbox"
                  text={value}
                />
              </li>
            )
          );
        })}
      </ul>
      <h5
        className={`${showFilter ? "active" : ""}`}
        onClick={() => {
          if (showFilter) {
            filterContainer.current.blur();
          } else {
            filterContainer.current.focus();
          }
        }}
      >
        {obj.label}
        <div className={`dropdown-arrow ${showFilter ? "active" : ""}`} />
      </h5>
      <Styles.Blackbar className={`${showFilter ? "active" : ""}`} />
    </Styles.CheckBoxHolder>
  );
};
export default FilterHolder;
