import React from "react";

import * as Styles from "./TableStyles";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import Markdown from "markdown-to-jsx";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import { ContentCTA } from "../ContentCTA/ContentCTA";
import Column from "../Column/Column";

const Table = props => {
  const {
    backgroundColor,
    header,
    subHeader,
    columnTable,
    customTable,
    contentCta,
    linkText,
    lang,
  } = props;

  // Function Column
  const parseColumns = numberOfItems => {
    switch (numberOfItems) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      case 5:
        return 2;
      case 6:
        return 2;
      default:
        return 12;
    }
  };

  return (
    <Styles.Table $backgroundColor={backgroundColor}>
      <ContainerStyle fluid="true">
        {/* Title And SubTitle Main Table */}
        <RowStyle>
          {header && (
            <div className="header">
              <Markdown>{header}</Markdown>
            </div>
          )}
          {subHeader && (
            <div className="subHeader">
              <Markdown>{subHeader}</Markdown>
            </div>
          )}
        </RowStyle>
        <RowStyle>
          {columnTable &&
            columnTable.map((item, i) => {
              return (
                <ColStyle
                  lg={parseColumns(columnTable.length)}
                  md={6}
                  sm={12}
                  key={i}
                >
                  <Column key={i} item={{ lang, ...item.fields }} />
                </ColStyle>
              );
            })}
        </RowStyle>
        {/* Custom Table */}
        <RowStyle>
          {customTable && (
            <div>
              <Markdown>{customTable}</Markdown>
            </div>
          )}
        </RowStyle>
        <RowStyle>
          {linkText && (
            <div className="linkText">
              <Markdown>{linkText}</Markdown>
            </div>
          )}
          {contentCta && (
            <div className="cta">
              <ContentCTA contentCta={{ ...contentCta, lang }} />
            </div>
          )}
        </RowStyle>
      </ContainerStyle>
    </Styles.Table>
  );
};

export default Table;
