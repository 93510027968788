import styled from "styled-components";

export const MainStyles = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  border-top: 1px solid #9d9d9d;

  ul {
    list-style-type: none;
  }

  @media only screen and (max-width: 1279px) {
    margin: 0 80px;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 16px;
  }
`;
