import styled, { css } from "styled-components";

const TextStyles = css`
  h1 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 30px;
    padding: 12px 0;
  }
  h2 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 28px;
    padding: 12px 0;
  }
  h3 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 26px;
    padding: 12px 0;
  }
  h4 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 24px;
    padding: 12px 0;
  }
  h5 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 22px;
    padding: 12px 0;
  }
  h5 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding: 12px 0;
  }
  ul {
    padding-left: 40px;
    list-style-type: disc;
  }
  ol {
    padding-left: 40px;
  }
  p,
  li {
    /* font-family: 'articulat-cf', sans-serif; */
    font-size: 20px;
    margin-bottom: 0.725rem;
  }
  u,
  ins {
    text-underline-offset: 3px;
  }
  a {
    color: blue;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  table,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 5px;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 26px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 22px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 18px;
    }
    p,
    li,
    h6 {
      font-size: 16px;
    }
  }
`;

export const MainStyles = styled("li")<{
  $topAccordionFontSize?: string[] | null;
}>`
  border-bottom: 1px solid #9d9d9d;
  padding: 20px 0;
  margin-bottom: 0;
  .top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: pointer;
    span {
      top: 0;
      margin-right: 0;
      font-family: "articulat-cf", sans-serif;
      font-size: ${({ $topAccordionFontSize }) =>
        $topAccordionFontSize ? $topAccordionFontSize[0] : "22px"};
      text-align: left;
      @media only screen and (max-width: 1280px) {
        font-size: ${({ $topAccordionFontSize }) =>
          $topAccordionFontSize ? $topAccordionFontSize[1] : "22px"};
      }
      @media only screen and (max-width: 768px) {
        font-size: ${({ $topAccordionFontSize }) =>
          $topAccordionFontSize ? $topAccordionFontSize[2] : "18px"};
      }
    }
    @media only screen and (max-width: 768px) {
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  .text-content-container-top-level {
    padding-bottom: 10px;
    ${TextStyles}
  }

  .sub-accordion-top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    span {
      top: 0;
      margin-right: 0;
      font-family: "articulat-cf", sans-serif;
      font-size: 16px;
      font-weight: 600;
    }
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      @media only screen and (max-width: 768px) {
        margin-right: 3px;
        width: 14px;
        height: 14px;
      }
    }
    button {
      height: 16px;
      span {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const TopLevelAccordion = styled("div")<{ $accordionHeight?: number }>`
  padding-left: 60px;
  max-height: ${({ $accordionHeight }) => `${$accordionHeight}px`};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
  }
  &[aria-expanded="true"] {
    max-height: ${({ $accordionHeight }) => `${$accordionHeight}px`};
    transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  &[aria-expanded="false"] {
    transition: max-height 0.5s ease-in-out;
    margin-top: 12px;
  }
`;

export const InnerAccordion = styled("div")<{ $subAccordionHeight?: number }>`
  overflow: hidden;
  max-height: ${({ $subAccordionHeight }) => `${$subAccordionHeight}px`};
  transition: max-height 0.5s ease-in-out;
  &-top-level {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ${TextStyles}
  &[aria-expanded='true'] {
    max-height: ${({ $subAccordionHeight }) => `${$subAccordionHeight}px`};
    transition: max-height 0.5s ease-in-out;
  }
  &[aria-expanded="false"] {
    transition: max-height 0.5s ease-in-out;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;
