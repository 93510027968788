import React from "react";
import Markdown from "markdown-to-jsx";
import { Container, Row, Col } from "react-grid-system";
import * as Styles from "./ResourcesBlockStyles";
import RelatedCards from "../RelatedCards/RelatedCards";
import ResourcesList from "../ResourcesList/ResourcesList";

const cleanObj = (obj, itemType, lang) => {
  const type = itemType || obj.sys.contentType.sys.id;
  if (type === "brightcoveVideo") {
    obj = { ...obj.videoData, ...obj };
  }
  let item: any = {
    imageDescription: "",
    blogPostType: "",
    title: "",
    previewCopy: "",
  };
  if (type == "card") {
  }
  item = { ...item, ...obj };
  switch (type) {
    case "story":
    case "stories":
      item.title = obj.customer ? obj.customer.name : "";
      item.imageDescription = obj.title;
      item.previewCopy = obj.title;
      item.image = obj.mainImage ? obj.mainImage : {};
      item.imageSource = obj.featuredImageUrl ? obj.featuredImageUrl : "";
      item.blogPostType = "Customer Story";
      item.link = `/${obj.node_locale.slice(0, 2)}/customers/${obj.slug}/`;
      item.date = "";
      item.lang = lang;
      item.tags = obj.tags;
      break;
    case "post":
    case "posts":
      item.title = obj.title;
      item.imageDescription = obj.title;
      item.previewCopy = obj.description
        ? obj.description.childMarkdownRemark.html
        : "";
      item.image = obj.mainImage ? obj.mainImage : {};
      item.imageSource = obj.mainImageUrl ? obj.mainImageUrl : "";
      item.blogPostType = "Blog Post";
      item.link =
        obj.publishedAt && obj.slug
          ? `/${obj.node_locale.slice(0, 2)}/blog/${obj.slug}/`
          : "";
      item.date = new Date(item.publishedAt).toLocaleDateString(obj.lang, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      item.lang = lang;
      item.tags = obj.tags;
      item.category = obj.category;
      break;
    case "webinar":
    case "webinars":
    case "brightcoveVideo":
      item.title = obj.title ? obj.title : obj.name;
      item.imageDescription = obj.title;
      item.previewCopy = obj.description
        ? obj.description.childMarkdownRemark.html
        : obj.long_description || "";
      item.imageSource = obj.poster;
      item.blogPostType =
        type === "brightcoveVideo" ? obj.type || "video" : "Webinar";
      item.link =
        type !== "brightcoveVideo"
          ? `/${obj.lang}/webinars/${obj.id || obj.video_id}`
          : "";
      item.video_id = obj.video_id || obj.id;
      item.tags = obj.tags;
      item.type = obj.type ? obj.type : type;
      break;
    case "card":
      item.title = obj.fields.title;
      item.link = obj.fields.link;
      item.previewCopy = obj.fields.body ? obj.fields.body : "";
      item.mainImage = obj.fields.backgroundImage
        ? obj.fields.backgroundImage
        : {};
      item.image = obj.fields.backgroundImage ? obj.fields.backgroundImage : {};
      item.date = obj.fields.label;
      item.link = obj.fields.link;
      item.type = type;
      item.highlightColor = obj.fields.highlightColor || null;
      item.darkImage = obj.fields.darkImage || null;
      break;
    default:
      item = obj;
      item.type = type;
  }

  return item;
};
const decorateData = obj => {
  const returnObj = {
    items: [],
    headline: obj.headline,
    text: obj.ctaText,
    link: obj.ctaLink,
  };
  if (obj.items) {
    const items = obj.items.map(item => {
      return cleanObj(item, null, obj.lang);
    });
    returnObj.items = items;
  }
  return returnObj;
};

const ResourcesBlock = props => {
  if (props.layout && props.layout === "3 Column") {
    if (props.items) {
      return (
        <Styles.ResourcesBlockWrapper
          id={props.slug}
          $textColor={props.textColor}
          $backgroundColor={props.backgroundColor}
        >
          <Container>
            <Row>
              <Col lg={12}>
                <h3>{props.headline}</h3>
              </Col>
            </Row>
            <RelatedCards
              lang={props.lang}
              style={{ borderTop: "1px solid #d9d9d6" }}
              items={decorateData(props).items.slice(0, 3)}
              cardType={props.cardType || ""}
            />
          </Container>
        </Styles.ResourcesBlockWrapper>
      );
    }
    return <></>;
  }
  if (props.cardType === "Go-to-market card") {
    return (
      <Styles.ResourcesBlockWrapper
        id={props.slug}
        $textColor={props.textColor}
        $backgroundColor={props.backgroundColor}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h4>{props.headline}</h4>
            </Col>
          </Row>
          <RelatedCards
            lang={props.lang}
            style={{ borderTop: "1px solid #d9d9d6" }}
            items={decorateData(props).items.slice(
              0,
              decorateData(props).items.length
            )}
            cardType={props.cardType || ""}
          />
        </Container>
      </Styles.ResourcesBlockWrapper>
    );
  }
  if (props.cardType === "Product image block") {
    return (
      <Styles.ResourcesBlockWrapper
        id={props.slug}
        $textColor={props.textColor}
        $backgroundColor={props.backgroundColor}
      >
        <Container className="image-block-list">
          <Row>
            <Col lg={12}>
              <h4>{props.headline}</h4>
            </Col>
          </Row>
          <RelatedCards
            lang={props.lang}
            style={{ borderTop: "1px solid #d9d9d6" }}
            items={decorateData(props).items.slice(
              0,
              decorateData(props).items.length
            )}
            cardType={props.cardType || ""}
          />
        </Container>
      </Styles.ResourcesBlockWrapper>
    );
  }
  if (props.cardType === "Featured content") {
    return (
      <Styles.ResourcesBlockWrapper
        id={props.slug}
        $textColor={props.textColor}
        $backgroundColor={props.backgroundColor}
        className="featured-content"
      >
        <Container>
          <Row>
            <Col lg={12} className="header-wrapper">
              {props.preHeaderText && <h5>{props.preHeaderText}</h5>}
              {props.headline && (
                <h4>
                  <Markdown>{props.headline}</Markdown>
                </h4>
              )}
              {props.subheadline && (
                <Markdown>{`<strong>${props.subheadline}</strong>`}</Markdown>
              )}
            </Col>
          </Row>
          <RelatedCards
            lang={props.lang}
            style={{ borderTop: "1px solid #d9d9d6" }}
            items={decorateData(props).items.slice(
              0,
              decorateData(props).items.length
            )}
            cardType={props.cardType || ""}
          />
          <Row>
            <Col lg={12} className="bottom-wrapper">
              {props.bottomText && (
                <p style={{ paddingBottom: "20px" }}>{props.bottomText}</p>
              )}
            </Col>
          </Row>
        </Container>
      </Styles.ResourcesBlockWrapper>
    );
  }
  if (props.cardType === "Square color blocks") {
    return (
      <Styles.ResourcesBlockWrapper
        id={props.slug}
        $textColor={props.textColor}
        $backgroundColor={props.backgroundColor}
        className="square-color-blocks"
      >
        <Container>
          <Row>
            <Col lg={12} className="header-wrapper">
              {props.headline && <h4>{props.headline}</h4>}
              {props.body && (
                <Markdown>{props.body.childMarkdownRemark.html}</Markdown>
              )}
            </Col>
          </Row>
          <RelatedCards
            lang={props.lang}
            style={{ borderTop: "1px solid #d9d9d6" }}
            items={props.items}
            cardType="Square color blocks"
          />
          <Row>
            <Col lg={12} className="bottom-wrapper">
              {props.bottomText && <p>{props.bottomText}</p>}
            </Col>
          </Row>
        </Container>
      </Styles.ResourcesBlockWrapper>
    );
  }
  if (props.items) {
    return (
      <ResourcesList
        slug={props.slug}
        title={decorateData(props).headline}
        textColor={props.textColor}
        backgroundColor={props.backgroundColor}
        blogPosts={decorateData(props).items}
        ctaText={props.ctaText}
        ctaLink={props.ctaLink}
        lang={props.lang}
      />
    );
  }
  return <></>;
};

export default ResourcesBlock;
