import styled from "styled-components";

export const MainStyles = styled("div")<{
  $textColor?: string;
}>`
  color: ${({ $textColor }) => $textColor ?? "#000"};
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;

    > div > p,
    > div > span {
      width: 90%;
      color: ${({ $textColor }) => $textColor ?? "#000"};
    }

    > h4 {
      font-family: var(--primary-font-family), sans-serif;
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 0.9;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      color: ${({ $textColor }) => $textColor ?? "#000"};
      .icon-arrow {
        top: 0 !important;
        width: 30px;
        margin: 0 10px 0 0;
      }

      @media only screen and (max-width: 640px) {
        font-size: 1.6rem;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    margin-bottom: 20px;
  }
`;
