import React, { useEffect, useState } from "react";

import * as Styles from "./styles";
import CallToAction from "../../../CallToAction/CallToAction";
import Spinner from "@/src/components/Global_Components/Spinner/Spinner";
import Link from "next/link";
import { getAllPressReleases } from "@/src/lib/pressRelease";

const BoxLink = (index, object, nodate, lang) => {
  const linkPath = `/${lang}/company/press/${object.slug}`;

  const InnerContainer = () => {
    return (
      <Styles.PressTextWrapper>
        <Styles.PressListSmall>
          {new Date(object.releasedAt).toLocaleDateString(lang, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Styles.PressListSmall>
        <p className="press-list-title">{object.title}</p>
      </Styles.PressTextWrapper>
    );
  };
  return (
    <Styles.LinkWrapperYikes>
      {object.externalLink ? (
        <a target={"_blank"} href={object.externalLink}>
          <InnerContainer />
        </a>
      ) : (
        <Link prefetch key={`content_item_${index}`} href={linkPath}>
          <InnerContainer />
        </Link>
      )}
    </Styles.LinkWrapperYikes>
  );
};

const PressReleaseList = props => {
  const [pressReleases, setPressReleases] = useState<
    {
      body: string;
      byline: string;
      releasedAt: string;
      slug: string;
      title: string;
      isNewsArticle: boolean;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`/api/get-press-releases?lang=${props.lang}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => {
        setPressReleases(data);
        setIsLoading(false);
      });
    //eslint-disable-next-line
  }, []);
  const newsArticles = pressReleases?.filter(item => item.isNewsArticle);
  const prArticles = pressReleases?.filter(item => !item.isNewsArticle);
  const combinedList = [
    ...prArticles.slice(0, 6 - newsArticles.length),
    ...newsArticles,
  ];
  if (isLoading) return <Spinner color="#000" width="100px" height="100px" />;
  return (
    <Styles.MainStyles>
      <h3 className="press-release-header">NEWS</h3>
      <div className="press-release-list">
        {/* @ts-ignore */}
        {combinedList && combinedList.length ? (
          combinedList.map((data, index) => {
            return BoxLink(index, data, null, props.lang);
          })
        ) : (
          <Spinner color="#000" width="100px" height="100px" />
        )}
      </div>
      <div className="press-release-cta-wrapper">
        <CallToAction
          linkPath={`${props.lang}/company/press`}
          callToActionCopy="See all news"
          lang={props.lang}
          iconImage="button-solid"
          buttonStyle="rounded"
          backgroundColor="#000"
          textColor="#fff"
          colorTextButton="#fff"
          backgroundButton="#000"
        />
      </div>
    </Styles.MainStyles>
  );
};

export default PressReleaseList;
