"use client";
import React, { useRef, useEffect, useState } from "react";
import * as Styles from "./CareersFilterStyles";
import { FilterStyles } from "./Filter/FilterStyles";
import axios from "axios";
const url = `https://api.greenhouse.io/v1/boards/brightcove/jobs?content=true`;
import FilterBar from "./Filter/FilterBar";
import { useOutsideClick } from "./Filter/useOutsideClick";
import { Pagination } from "./Pagination/Pagination";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import Icon from "../../Global_Components/Icon/Icon";

const CareersFilter = props => {
  const { translations } = props;

  const [filterContent, setFilterContent] = useState([]); // list of filter per type
  const [Allcards, setAllCards] = useState<undefined | any[]>();
  // Before Loading Page
  useEffect(() => {
    // Call API
    axios
      .get(url, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        setAllCards(response.data.jobs);
      });
    // Function URl
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const { search } = window.location;
      const re = /\[(.*?)\]/g;
      const str = search;
      const myArray: RegExpMatchArray | null = str.match(re);
      const preFilter = [
        { type: "departments", value: [] },
        { type: "offices", value: [] },
      ];
      // // for each categories add to papafilter
      if (myArray) {
        for (let i = 0; i < myArray.length; i++) {
          preFilter.some(({ type, value }) => {
            // check whether the value we want to filter by is an arry like tags
            if (myArray[i].includes(type)) {
              // Remove all characters before the '=' and remove '%20'  and push to prefilter Array
              // @ts-ignore
              const match = myArray[i]
                .split("=")
                .pop()
                .trim()
                .replace(/%20/g, " ");
              const newMatch = match.replace("]", "").replace("[", "");
              // @ts-ignore
              value.push(newMatch);
            }
          });
        }
        setPapaFilter(preFilter);
      }
    }
  }, []);

  //  PapaFilter
  const [papaFilter, setPapaFilter] = useState([
    { type: "departments", value: [] },
    { type: "offices", value: [] },
  ]);
  // Search
  const [search, setSearch] = useState("");

  //  scroll to top filter fter click on pagination
  const topCard = useRef(null);

  // Function to get rid off the dropdown-content if open outside filter.
  const ref = useRef();
  useOutsideClick(ref, () => {
    // Remove Content-dropdown
    const allShowClass = document.getElementsByClassName("show");
    while (allShowClass.length) allShowClass[0].classList.remove("show");
    // check all class arrow-up and remove it.
    const allArrowUp = document.getElementsByClassName("arrow-up");
    while (allArrowUp.length) allArrowUp[0].classList.remove("arrow-up");
  });

  /// Method to filter card with papaFilter and return finalArray ///
  const filterCard = (items, papaFilter, translations) => {
    // If Search Filter
    if (search.length > 0) {
      items = Allcards!.filter(item => {
        return (
          JSON.stringify(
            item.departments.length ? item.departments[0].name : ""
          )
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          JSON.stringify(item.offices.length ? item.offices[0].name : "")
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          JSON.stringify(item.title ? item.title : "")
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      });
    }
    const filteredArr = papaFilter.filter(obj => obj.value != "");
    // return the result of ALL card based on the filter

    const result = items.filter(item =>
      filteredArr.every(({ type, value }) => {
        // check whether the value we want to filter by is an arry like tags
        if (Array.isArray(item[type])) {
          // yes, we are dealing with an array => check if any of the filter criteria are matched
          // if you want to check for all criteria use "every()" instead of "some"
          return item[type].some(valueInArray =>
            value.includes(valueInArray.name)
          );
        }
        // if we are not dealing with an array we need to just check one value like type
        return value.includes(item[type]);
      })
    );
    // return Method
    return (
      <>
        <br />
        <ContainerStyle>
          <RowStyle>
            <ColStyle lg={12}>
              <div ref={topCard} className="result">
                <h3>
                  {translations.Result}:{" "}
                  <span className="value-result">{result.length}</span>
                </h3>
                {papaFilter &&
                  papaFilter.map((select, i) => {
                    return (
                      <h3 key={i}>
                        {select.type == "offices"
                          ? translations["Locations"]
                          : select.type}
                        :{" "}
                        <span className="value-result">
                          {select.value.length > 0
                            ? select.value.join(", ")
                            : "All"}
                        </span>
                      </h3>
                    );
                  })}
              </div>
            </ColStyle>
          </RowStyle>
        </ContainerStyle>
        <Pagination
          topCard={topCard}
          translations={translations}
          items={result}
          tagOnClick={tagOnClick}
        />
      </>
    );
  };
  // function Scroll to top element on click
  const scrollToBottom = () => {
    // @ts-ignore
    topCard.current?.scrollIntoView({ behavior: "smooth" });
  };

  const tagOnClick = name => {
    // Set papaFilter and filterContent
    const data = papaFilter.map(item => {
      // @ts-ignore
      if (item.type === "offices" && !item.value.includes(name)) {
        // @ts-ignore
        if (!filterContent.includes(name)) setFilterContent([name]);
        const updateFilter = [name]; // add new category Selected
        // @ts-ignore
        item.value = updateFilter;
        return item;
      }
      if (item.type === "departments") {
        item.value = [];
        return item;
      }
      return item;
    });
    setPapaFilter(data);
    // @ts-ignore
    setFilterContent([name]);
    scrollToBottom();
  };

  return (
    <Styles.CareersFilter>
      <ContainerStyle>
        {/* Filter and Card Display IF Allcards */}
        {Allcards ? (
          <>
            <FilterStyles>
              <div className="filter-wrapper">
                {/* @ts-ignore */}
                <div ref={ref} className="filter-type">
                  <FilterBar
                    Allcards={Allcards}
                    types={["departments", "offices"]}
                    setPapaFilter={setPapaFilter}
                    papaFilter={papaFilter}
                    translations={translations}
                    filterContent={filterContent}
                    setFilterContent={setFilterContent}
                  />
                </div>
                {/* Search */}
                <div className="filter-search">
                  <label htmlFor="contentsearch">{translations.Search}</label>
                  <input
                    type="text"
                    className="textbox"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    name="contentsearch"
                  />
                  {search && search.length > 0 && (
                    <button onClick={() => setSearch("")}>
                      <Icon iconImage="close" className={""} iconColor={""} />
                    </button>
                  )}
                </div>
              </div>
            </FilterStyles>
            <RowStyle>
              <ContainerStyle>
                {filterCard(Allcards, papaFilter, translations)}
              </ContainerStyle>
            </RowStyle>
          </>
        ) : (
          <div className="loading-space" />
        )}
      </ContainerStyle>
    </Styles.CareersFilter>
  );
};

export default CareersFilter;
