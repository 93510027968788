import styled, { css } from "styled-components";

const TextStyles = css`
  h1 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 700;
    font-size: 30px;
    padding: 12px 0;
  }
  h2 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 28px;
    padding: 12px 0;
  }
  h3 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 700;
    font-size: 26px;
    padding: 12px 0;
    line-height: 1.1;
  }
  h4 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 700;
    font-size: 24px;
    padding: 12px 0;
    line-height: 1.1;
  }
  h5 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 600;
    font-size: 22px;
    padding: 12px 0;
  }
  h5 {
    font-family: "articulat-cf", sans-serif;
    font-weight: 700;
    font-size: 20px;
    padding: 12px 0;
  }
  ul {
    padding-left: 40px;
    list-style-type: disc;
  }
  ol {
    padding-left: 40px;
  }
  li,
  p {
    /* font-family: 'articulat-cf', sans-serif; */
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 0.725rem;
  }
  u,
  ins {
    text-underline-offset: 3px;
  }
  a {
    color: blue;
    text-decoration: underline;
    text-underline-offset: 3px;
    margin-bottom: 0px;
  }
  /* Phone:  max-width: 768px */
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 26px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 22px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 18px;
    }
    p,
    li,
    h6 {
      font-size: 16px;
    }
  }
`;

// Style for  TextBlock Styles.
export const TextBlockStyles = styled("div")<{
  $backgroundColor: string | undefined;
  $containerGrid: string | undefined;
  $padding: string | undefined;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  .container-text-block {
    max-width: ${({ $containerGrid }) => $containerGrid};
    padding: ${({ $padding }) => $padding || "5%"};
    margin-left: auto;
    margin-right: auto;
  }
  /* Header */
  .header-text-block {
    font-family: "articulat-cf", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    ${TextStyles};
  }
  /* Body */
  .body-text-block {
    font-family: "articulat-cf", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    ${TextStyles};
    /* for Cta brighcove -*/
    a p {
      margin-bottom: 0px;
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
  }
`;
