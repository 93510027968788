import React from "react";

import ModelA from "./Model/ModelA";
import ModelB from "./Model/ModelB";
import ModelC from "./Model/ModelC";
import ModelD from "./Model/ModelD";
import ModelE from "./Model/ModelE";
import ModelF from "./Model/ModelF";

const FullWidthVideoBlock = props => {
  const { type } = props;

  return (
    <>
      {type === "Model-A" && <ModelA {...props} />}
      {type === "Model-B" && <ModelB {...props} />}
      {type === "Model-C" && <ModelC {...props} />}
      {type === "Model-D" && <ModelD {...props} />}
      {type === "Model-E" && <ModelE {...props} />}
      {type === "Model-F" && <ModelF {...props} />}
    </>
  );
};

export default FullWidthVideoBlock;
