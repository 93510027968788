import React from "react";
import PlaySeasonStyles from "../PlaySeasonStyles";
import { MainImageType } from "@/src/types/types";
import ContentfulImage from "@/src/lib/contentful-image";
import Link from "next/link";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

interface ModelAProps {
  header?: string;
  body?: string;
  bodyRight?: string;
  type?: string;
  backgroundColor?: string;
  image?: MainImageType;
  contentCta?: any;
  blockLogo?: any;
  lang: string;
}

const ModelA = ({
  body,
  backgroundColor,
  image,
  contentCta,
  bodyRight,
  blockLogo,
  lang,
}: ModelAProps) => {
  // Logo
  const PlayLogo = ({ card }) => {
    return (
      <div className="logo">
        {image && (
          <ContentfulImage
            alt={image.fields.title || ""}
            width={image.fields.file?.details.image.width || 1000}
            height={image.fields.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image.fields.file?.url}
          />
        )}
      </div>
    );
  };

  // Function Check if internal or external Link
  const DisplayLink = card => {
    if (!card.fields.clickableItem) {
      return <PlayLogo card={card.fields} />;
    } else if (card.fields.clickableItem?.includes("https")) {
      return (
        <a href={card.fields.clickableItem} target="_blank">
          <PlayLogo card={card.fields} />
        </a>
      );
    } else {
      return (
        <Link prefetch href={`/${lang}/${card.fields.clickableItem}`}>
          <PlayLogo card={card.fields} />
        </Link>
      );
    }
  };
  return (
    <PlaySeasonStyles $backgroundColor={backgroundColor}>
      <ContainerStyle fluid="maxFluid">
        <RowStyle className="row-model-A">
          <ColStyle lg={6} md={12} sm={12} gutter={"60"} className="col-left">
            <div className="wrapper-text">
              {image && (
                <div className="logo">
                  <ContentfulImage
                    alt={image.fields.title || ""}
                    width={image.fields.file?.details.image.width || 1000}
                    height={image.fields.file?.details.image.height || 1000}
                    quality={100}
                    priority={true}
                    src={image.fields.file?.url}
                  />
                </div>
              )}
              {body && (
                <div className="body">
                  <Markdown>{body}</Markdown>
                </div>
              )}
              {contentCta && (
                <div className="button-play">
                  <ContentCTA contentCta={{ ...contentCta, lang }} />
                </div>
              )}
            </div>
          </ColStyle>
          <ColStyle lg={6} md={12} sm={12} gutter={"0"}>
            <div className="block-right">
              {bodyRight && (
                <div className="bodyRight">
                  <Markdown>{bodyRight}</Markdown>
                </div>
              )}
              <RowStyle>
                {blockLogo &&
                  blockLogo.map((card, i) => {
                    return (
                      <ColStyle key={i} lg={4} md={4} sm={6} gutter={"0"}>
                        <div className="wrapper-logo-play-season">
                          {DisplayLink(card)}
                        </div>
                      </ColStyle>
                    );
                  })}
              </RowStyle>
            </div>
          </ColStyle>
        </RowStyle>
      </ContainerStyle>
    </PlaySeasonStyles>
  );
};
export default ModelA;
