import React from "react";
import ImageTextSplitSection from "./ImageTextSplitStyles";
import Markdown from "markdown-to-jsx";
import VerboseCTA from "../VerboseCTA/VerboseCTA";
import ContentfulImage from "@/src/lib/contentful-image";
import CallToAction from "../CallToAction/CallToAction";

function ImageTextSplit({
  header,
  body,
  layout,
  float,
  image,
  imageStyle,
  verboseCta,
  videoBodyCta,
  lightboxVideo,
  fitImageToText,
  fitImageToTextFullWidth,
  verticalMargin,
  lang,
}) {
  const overFlowDirection =
    imageStyle === "Overflow"
      ? layout.includes("Text left")
        ? "right"
        : "left"
      : null;
  // Add lang to verbose CTA if verbose CTA
  verboseCta = verboseCta && { ...verboseCta.fields, lang };

  return (
    <ImageTextSplitSection
      $fitImageToTextFullWidth={fitImageToTextFullWidth}
      $float={float}
      $layout={layout}
      $verticalMargin={verticalMargin}
      $overFlowDirection={overFlowDirection}
      $image={image.fields.file?.url}
    >
      {fitImageToText || fitImageToTextFullWidth ? (
        <div
          className={`image-fit-wrapper${
            fitImageToTextFullWidth ? " image-fit-to-text-image-wrapper" : ""
          }`}
          style={{ background: `url(${image.fields.file?.url})` }}
        />
      ) : (
        <div className="image-wrapper">
          <ContentfulImage
            alt={image.fields.name || ""}
            width={image.fields.file?.details.image.width || 1000}
            height={image.fields.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image.fields.file?.url}
          />
        </div>
      )}
      <div
        className={`text-wrapper${
          fitImageToTextFullWidth ? " image-fit-to-text-wrapper" : ""
        }`}
      >
        {header && (
          <div className="header">
            <Markdown>{header}</Markdown>
          </div>
        )}
        {body && <Markdown className="body">{body}</Markdown>}
        {verboseCta ? <VerboseCTA {...verboseCta} /> : ""}
        {lightboxVideo && (
          <CallToAction
            id="hero-cta"
            callToActionCopy={
              <Markdown>{videoBodyCta}</Markdown> || "Watch this"
            }
            linkPath="#"
            iconImage="arrow"
            iconColor="#000"
            lightboxData={{
              type: "video",
              content: lightboxVideo.video_id,
            }}
            textColor="#000"
            lang={lang}
          />
        )}
      </div>
    </ImageTextSplitSection>
  );
}

export default ImageTextSplit;
