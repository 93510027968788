"use client";
import React, { createElement } from "react";
import { TextBlock } from "../../Contentful_Components/TextBlock/TextBlock";
import CtaBrightcove from "../../Contentful_Components/CTABrightcove";
import Space from "../../Contentful_Components/Space/Space";
import ImageTextSplit from "../../Contentful_Components/ImageTextSplit/ImageTextSplit";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import CardGrid from "../../Contentful_Components/CardGrid/CardGrid";
import CallOutCard from "../../Contentful_Components/CallOutCard/CallOutCard";
import CustomersBlock from "../../Contentful_Components/CustomersBlock/CustomersBlock";
import HeroBanner from "../../Contentful_Components/HeroBanner/HeroBanner";
import FullWidthVideoBlock from "../../Contentful_Components/FullWidthVideoBlock/FullWidthVideoBlock";
import Carousel from "../../Contentful_Components/Carousel/Carousel";
import VideoTextSplit from "../../Contentful_Components/VideoTextSplit/VideoTextSplit";
import PlaySeason from "../../Contentful_Components/PlaySeason/PlaySeason";
import AccordionList from "../../Contentful_Components/AccordionList";
import Ribbon from "../../Contentful_Components/Ribbon/Ribbon";
import QuoteBlock from "../../Contentful_Components/QuoteBlock/QuoteBlock";
import Breadcrumbs from "../../Contentful_Components/Breadcrumbs";
import CenteredCopyContainer from "../../Contentful_Components/CenteredCopyContainer/CenteredCopyContainer";
import CenteredTextBlock from "../../Contentful_Components/CenteredTextBlock/CenteredTextBlock";
import ImageWithTextContainer from "../../Contentful_Components/ImageWithTextContainer/ImageWithTextContainer";
import CopyBlock from "../../Contentful_Components/CopyBlock/CopyBlock";
import DescriptionBlock from "../../Contentful_Components/DescriptionBlock/DescriptionBlock";
import FullWidthFeatureBlock from "../../Contentful_Components/FullWidthFeature/FullWidthFeatureBlock";
import Hero from "../../Contentful_Components/Hero/Hero";
import IFrameComponent from "../../Contentful_Components/IFrameComponent";
import LogoGrid from "../../Contentful_Components/LogoGrid/LogoGrid";
import PlainText from "../../Contentful_Components/PlainText/PlainText";
import ProductFeatureGrid from "../../Contentful_Components/ProductFeature/ProductFeatureGrid";
import Card from "../../Contentful_Components/Card";
import CTA from "../../Contentful_Components/CTA";
import SpecialComponent from "../../Contentful_Components/SpecialComponent";
import MultiColumnBlock from "../../Contentful_Components/MultiColumnBlock";
import Form from "../../Contentful_Components/Form/Form";
import Table from "../../Contentful_Components/Table/Table";
import CareersFilter from "../../Contentful_Components/CareersFilter/CareersFilter";
import ResourcesBlock from "../../Contentful_Components/ResourcesBlock/ResourcesBlock";
import ResourceContainer from "../../Contentful_Components/ResourceContainer/ResourceContainer";
import HeroModule from "../../Contentful_Components/Modules/Hero";
import HeadlineModule from "../../Contentful_Components/Modules/Headline";
import JumpNavigationModule from "../../Contentful_Components/Modules/JumpNavigation";
import CalloutModule from "../../Contentful_Components/Modules/Callout";
import LinkSectionModule from "../../Contentful_Components/Modules/LinkSection";
import PreFooterModule from "../../Contentful_Components/Modules/PreFooter";
import MultiColumnModule from "../../Contentful_Components/Modules/MultiColumn";
import CardGridModule from "../../Contentful_Components/Modules/CardGrid";
import TabComponent from "../../Contentful_Components/TabComponent";

const Components = {
  contentBlock: ImageWithTextContainer,
  form: Form,
  hero: Hero,
  heroBanner: HeroBanner,
  fullWidthVideoBlock: FullWidthVideoBlock,
  centeredTextBlock: CenteredTextBlock,
  // parallaxHeadline: ParallaxHeadline,
  // tabCarousel: TabCarousel,
  fullWidthFeatureBlock: FullWidthFeatureBlock,
  copyBlock: CopyBlock,
  centeredCopyBlock: CenteredCopyContainer,
  featureGrid: ProductFeatureGrid,
  logoGrid: LogoGrid,
  relatedContentBlock: ResourcesBlock,
  plaintext: PlainText,
  quote: QuoteBlock,
  multiColumnBlock: MultiColumnBlock,
  // brightcoveVideo: VideoBlock,
  cardGrid: CardGrid,
  imageTextSplit: ImageTextSplit,
  callOutCard: CallOutCard,
  customersBlock: CustomersBlock,
  carousel: Carousel,
  table: Table,
  resourceContainer: ResourceContainer,
  ribbon: Ribbon,
  space: Space,
  descriptionBlock: DescriptionBlock,
  iFrameComponent: IFrameComponent,
  card: Card,
  careersFilter: CareersFilter,
  cta: CTA,
  videoTextSplit: VideoTextSplit,
  specialComponent: SpecialComponent,
  textBlock: TextBlock,
  // bubbleLink: BubbleLink,
  playSeason: PlaySeason,
  accordionList: AccordionList,
  ctaBrightcove: CtaBrightcove,
  secondaryNavbar: SecondaryNavbar,
  breadcrumbs: Breadcrumbs,
  moduleHero: HeroModule,
  moduleHeadlineSection: HeadlineModule,
  moduleJumpNavigation: JumpNavigationModule,
  moduleCallout: CalloutModule,
  moduleLinkSection: LinkSectionModule,
  modulePreFooter: PreFooterModule,
  moduleMultiColumn: MultiColumnModule,
  moduleCardGrid: CardGridModule,
  tabComponent: TabComponent
};

const ComponentLookup = block => {
  return typeof Components[block.block.sys.contentType?.sys.id] !== "undefined"
    ? createElement(Components[block.block.sys.contentType?.sys.id], {
        ...block.block.fields,
        lang: block.block.lang,
        translations: block.block.translations,
      })
    : createElement(
        () => (
          <div>
            the {block.block.sys.contentType?.sys.id} component has not been
            created yet
          </div>
        ),
        {
          key: block.block.sys.id,
        }
      );
};

export default ComponentLookup;
