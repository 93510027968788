import styled from "styled-components";

export const Table = styled("div")<{
  $backgroundColor?: string;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  padding: 40px 0px;
  /* Header */
  .header {
    width: 100%;
    padding: 0px 0px 10px 0px;
    text-align: center;
    font-family: "articulat-cf";
    font-size: 35px;
    letter-spacing: 0px;
    font-weight: bold;
    color: #000;
  }
  /* Sub Header */
  .subHeader {
    width: 100%;
    padding: 0px 0px 10px 0px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
    color: #000;
  }
  .linkText {
    display: block;
    color: #000;
    font-size: 30px;
    margin: 20px auto;
  }
  .cta {
    display: block;
    width: 100%;
    text-align: center;
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .subHeader {
      padding: 0px 30px 10px 30px;
    }
  }
`;
