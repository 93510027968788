import React from "react";
import * as Styles from "./styles";
import ColumnTopic, { IColumnTopic } from "../../Topics/Column";

interface IMultiColumnProps {
  internalName: string;
  columns: {
    fields: IColumnTopic;
  }[];
  backgroundColor?: "Black" | "White" | "Grey";
  paddingTop?: "Small" | "Medium";
  paddingBottom?: "Small" | "Medium";
  variant?: "Asset";
  lang: string;
}

const MultiColumnModule = (props: IMultiColumnProps) => {
  const { columns, lang, backgroundColor, paddingBottom, paddingTop, variant } =
    props;
  return (
    <Styles.ModuleMultiColumn
      $backgroundColor={backgroundColor}
      $paddingBottom={paddingBottom}
      $paddingTop={paddingTop}
      $variant={variant}
    >
      {columns.map((column, i) => {
        return (
          <ColumnTopic
            key={i}
            content={column.fields.content}
            cta={column.fields.cta}
            title={column.fields.title}
            imageAsset={column.fields.imageAsset}
            backgroundColor={backgroundColor}
            lang={lang}
            numOfColumns={columns.length}
          />
        );
      })}
    </Styles.ModuleMultiColumn>
  );
};

export default MultiColumnModule;
