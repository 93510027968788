import React from "react";

import * as Styles from "./ColumnStyles";
import Link from "next/link";
import Markdown from "markdown-to-jsx";
import VerboseCTA from "../VerboseCTA/VerboseCTA";

const Column = props => {
  const {
    fullCardLink,
    backgroundColor,
    body,
    header,
    headerColor,
    verboseCta,
    lang,
  } = props.item;

  const linkTo = fullCardLink || "#";
  return (
    <Styles.Column
      $backgroundColor={backgroundColor}
      $headerColor={headerColor}
    >
      <Link
        prefetch
        href={linkTo}
        className={`wrapper-column ${fullCardLink ? "" : "disabled-link"}`}
      >
        <div className="header-block">
          {header && (
            <div className="header-column">
              <Markdown>{header}</Markdown>
            </div>
          )}
        </div>
        {body && (
          <div className="body">
            <Markdown>{body}</Markdown>
          </div>
        )}
        <div className="cta-link">
          {verboseCta && <VerboseCTA {...verboseCta.fields} lang={lang} />}
        </div>
      </Link>
    </Styles.Column>
  );
};

export default Column;
