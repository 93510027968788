import React from "react";

import * as Styles from "../RibbonStyles";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

const ModelA = props => {
  const { contentCta, image, description, withHeroBanner, background, lang } =
    props;

  return (
    <Styles.Ribbon $withHeroBanner={withHeroBanner} $background={background}>
      <ContainerStyle id="model-A" fluid="maxFluid">
        {image && (
          <ContentfulImage
            alt={image.fields.title || ""}
            width={image.fields.file?.details.image.width || 1000}
            height={image.fields.file?.details.image.height || 1000}
            quality={100}
            priority={true}
            src={image.fields.file?.url}
          />
        )}
        {description && (
          <div className="description">
            <Markdown>{description}</Markdown>
          </div>
        )}
        <ContentCTA contentCta={{ ...contentCta, lang }} />
      </ContainerStyle>
    </Styles.Ribbon>
  );
};

export default ModelA;
