import styled from "styled-components";

export const DescriptionBlock = styled("div")<{
  $backgroundColor?: string;
  $backgroundColorDescription?: string;
  $colorBar: string;
}>`
  /***************************** ModelA **********************************************/
  /***********************************************************************************/
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  /* titleDescription */
  padding: 40px;
  .titleDescription {
    text-align: center;
    padding: 20px;
  }
  /* wrapper-description */
  .wrapper-description {
    background: ${({ $backgroundColorDescription }) =>
      $backgroundColorDescription || "#fff"};
    padding: 20px;
    display: flex;
    flex-direction: row;
    .line-vertical {
      border-left: ${({ $colorBar }) =>
        `2px solid ${$colorBar}` || "2px solid black"};
    }
    .label {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      align-self: center;
      width: 30%;
    }
    .description {
      padding: 20px;
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .wrapper-description {
      flex-direction: column;
      .line-vertical {
        border-left: none;
      }
      .label {
        display: none;
      }
    }
  }
`;
