import React, { lazy } from "react";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);
import { useInView } from "react-intersection-observer";
import * as Styles from "./ImageWithTextContainerStyles";
import Cookies from "js-cookie";
import { BREAK_POINTS } from "@/src/lib/breakPoints";
import CallToAction from "../CallToAction/CallToAction";
import { Col, Container, Row } from "react-grid-system";
import { ContentCTA } from "../ContentCTA/ContentCTA";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import ContentfulImage from "@/src/lib/contentful-image";
import Icon from "../../Global_Components/Icon/Icon";
const AdjacentLists = props => {
  const AdjacentListsWrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    ul {
      list-style: inside;
    }
    @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
      display: block;
    }
  `;
  return (
    <AdjacentListsWrapper>
      {props.children &&
        props.children.map((ul, i) => {
          return (
            <ul key={i}>
              {ul.props &&
                ul.props.children &&
                ul.props.children.map((li, index) => {
                  return <li key={index}>{li.props.children[0]}</li>;
                })}
            </ul>
          );
        })}
    </AdjacentListsWrapper>
  );
};

// Style for Wrapper CTA
const CTAWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
// Style for Body CTA
const CTABody = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 432px;
  padding-left: 5px;
`;
const ImageWithTextContainer = props => {
  const {
    title,
    slug,
    body,
    preHeaderText,
    ctaText,
    ctaLink,
    align,
    backgroundColor,
    textColor,
    highlightColor,
    videoId,
    video,
    image,
    ctaType,
    animation,
    transitionDuration,
    fadeInImage,
    adjacentLists,
    form,
    callToAction,
    ctaStyle,
    maxWidthSize,
    maxHeightSize,
    splitStyle,
    borderStyle,
    imageStyle,
    displayImage,
    node_locale,
    contentCta,
    lang,
    containerColor,
  } = props;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.4,
    triggerOnce: true,
  });
  const visibleClass = inView ? "visible" : "not-visible";
  const animationClass = animation
    ? animation.toLowerCase().replace(" ", "-")
    : "";
  const fadeInClass = fadeInImage ? "fade-in" : "";
  const lightbox = ctaLink === "#";
  const getCTA = (callToAction, ctaStyle) => {
    if (ctaStyle === "Side icon") {
      // Style HR
      const styleHr = {
        width: "150px",
        height: "0px",
        border: `1px solid ${highlightColor || "#00EAE4"}`,
        opacity: 1,
      };
      return (
        <>
          <hr style={styleHr} />
          <CTAWrapper>
            <Icon
              iconColor={callToAction.fields.textColor}
              className="ctaIcon"
              iconImage={callToAction.fields.icon}
            />
            <CTABody className="ctaBody">
              <Markdown>
                {callToAction.fields.body.content[0].content[0].value}
              </Markdown>
              <CallToAction
                id={`${slug}-cta`}
                className="cta"
                linkPath={callToAction.fields.link}
                iconColor={highlightColor}
                textColor={textColor}
                callToActionCopy={callToAction.fields.headline}
                iconImage="arrow"
                blank={callToAction.fields.newTab}
                lang={lang}
              />
            </CTABody>
          </CTAWrapper>
        </>
      );
    }
    return (
      <CallToAction
        id={`${slug}-cta`}
        linkPath={ctaLink}
        iconColor={highlightColor}
        textColor={textColor}
        callToActionCopy={ctaText}
        iconImage={ctaType}
        lang={lang}
      />
    );
  };
  if (video && !lightbox) {
    return (
      // @ts-ignore
      <Styles.ImageWithTextContainerWrapper
        id={slug}
        $backgroundColor={backgroundColor}
        $textColor={textColor}
        $align={typeof align === "string" ? align : "right"}
        ref={ref}
        $transitionDuration={transitionDuration}
      >
        <Container className="animated-container">
          <Row className="animated-row">
            <Col
              lg={6}
              md={12}
              sm={12}
              className={`animated-blurb ${visibleClass} ${animationClass}`}
            >
              {preHeaderText && Object.keys(preHeaderText).length > 0 && (
                <strong>
                  <Markdown>{preHeaderText}</Markdown>
                </strong>
              )}
              {title && (
                <h3>
                  <Markdown>{title}</Markdown>
                </h3>
              )}
              {body && (
                <p>
                  {body.content.map((c, i) => {
                    return c.content.map((ind, index) => {
                      if (ind?.marks[0]?.type === "bold") {
                        return <strong key={index}>{ind.value}</strong>;
                      } else {
                        return <span key={index}>{ind.value}</span>;
                      }
                    });
                  })}
                </p>
              )}
              {/* {body && documentToReactComponents(JSON.parse(body.raw))} */}
              {adjacentLists && (
                <Markdown
                  options={{
                    overrides: {
                      AdjacentLists: {
                        component: AdjacentLists,
                      },
                    },
                  }}
                >
                  {adjacentLists}
                </Markdown>
              )}
              {ctaLink && !contentCta && (
                <CallToAction
                  id={`${slug}-cta`}
                  linkPath={ctaLink}
                  iconColor={highlightColor}
                  textColor={textColor}
                  callToActionCopy={ctaText}
                  iconImage={ctaType}
                  lang={lang}
                />
              )}
              {contentCta && (
                <ContentCTA contentCta={{ ...contentCta, lang }} />
              )}
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className={`animated-image ${visibleClass} ${fadeInClass}`}
            >
              {video && (
                <div className="video-wrap">
                  <React.Suspense
                    fallback={
                      <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                        <div
                          className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                          style={{
                            backgroundSize: "200% 100%",
                            animation: "shimmer 1.5s infinite",
                          }}
                        />
                      </div>
                    }
                  >
                    <LazyReactPlayerLoader
                      playerId="RUsPQ8qzl"
                      baseUrl="https://players.brightcove.net"
                      refNode="video-wrap"
                      accountId="1160438696001"
                      videoId={video.video_id}
                      onEmbedCreated={el => {
                        sfmcCookie();
                        el.setAttribute("data-bc-known-user", 1);
                        el.setAttribute(
                          "data-bc-custom-guid",
                          Cookies.get("_sfmc_id_")
                        );
                      }}
                    />
                  </React.Suspense>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Styles.ImageWithTextContainerWrapper>
    );
  }
  return (
    <Styles.ImageWithTextContainerWrapper
      id={slug}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $maxWidthSize={maxWidthSize}
      $maxHeightSize={maxHeightSize}
      $displayImage={displayImage}
      $containerColor={containerColor}
      $align={typeof align === "string" ? align : "right"}
      ref={ref}
      $transitionDuration={transitionDuration}
    >
      <Container
        className={`animated-container ${borderStyle ? "borderStyle" : ""}`}
      >
        <Row className={`animated-row ${displayImage ? "displayImage" : ""} `}>
          <Col
            lg={splitStyle === "6-6" ? 6 : 7}
            md={12}
            sm={12}
            className={`animated-blurb ${visibleClass} ${animationClass}`}
          >
            {preHeaderText && Object.keys(preHeaderText).length > 0 && (
              <strong>
                <Markdown>{preHeaderText}</Markdown>
              </strong>
            )}
            {title && (
              <h3>
                <Markdown>{title}</Markdown>
              </h3>
            )}
            {/* {body && documentToReactComponents(JSON.parse(body.raw))} */}
            {body && (
              <p>
                {body.content.map((c, i) => {
                  return c.content.map((ind, index) => {
                    if (ind?.marks[0]?.type === "bold") {
                      return <strong key={index}>{ind.value}</strong>;
                    } else {
                      return <span key={index}>{ind.value}</span>;
                    }
                  });
                })}
              </p>
            )}
            {adjacentLists && (
              <Markdown
                options={{
                  overrides: {
                    AdjacentLists: {
                      component: AdjacentLists,
                    },
                  },
                }}
              >
                {adjacentLists}
              </Markdown>
            )}
            {/* if callToAction => getCTA(callToAction, ctaStyle) */}
            {callToAction && !contentCta && getCTA(callToAction, ctaStyle)}
            {ctaLink && !contentCta && !callToAction && !lightbox && (
              <CallToAction
                id={`${slug}-cta`}
                linkPath={ctaLink}
                iconColor={highlightColor}
                textColor={textColor}
                callToActionCopy={ctaText}
                iconImage={ctaType}
                lang={lang}
              />
            )}{" "}
            {/* if !callToAction */}
            {ctaLink && !callToAction && lightbox && (
              <CallToAction
                id={`${slug}-cta`}
                linkPath={ctaLink}
                iconColor={highlightColor}
                textColor={textColor}
                callToActionCopy={ctaText}
                iconImage={ctaType}
                lang={lang}
                lightboxData={{
                  type: form ? "form" : "video",
                  content: form || video.video_id || videoId,
                }}
              />
            )}
            {contentCta && !lightbox && <ContentCTA contentCta={contentCta} />}
          </Col>
          <Col
            lg={splitStyle === "6-6" ? 6 : 5}
            md={12}
            sm={12}
            className={`animated-image ${visibleClass} ${fadeInClass} ${
              imageStyle === "Contained" ? "imageStyle" : ""
            } ${(maxWidthSize || maxHeightSize) && "image-resize"}`}
          >
            {/* If BorderStyle Img Fluid otherwise Img Fixed */}
            {borderStyle ? (
              <>
                {image && image.fields && (!video || lightbox) && (
                  // <Img fadeIn={false} fluid={image.fluid}  />
                  <ContentfulImage
                    alt={image.fields.title || ""}
                    width={image.fields.file?.details.image.width || 1000}
                    height={image.fields.file?.details.image.height || 1000}
                    quality={100}
                    priority={true}
                    src={image.fields.file?.url}
                  />
                )}
              </>
            ) : (
              <>
                {image && image.fields && (!video || lightbox) && (
                  // <Img fadeIn={false} fixed={image.fixed} />
                  <ContentfulImage
                    alt={image.fields.title || ""}
                    width={image.fields.file?.details.image.width || 1000}
                    height={image.fields.file?.details.image.height || 1000}
                    quality={100}
                    priority={true}
                    src={image.fields.file?.url}
                  />
                )}
              </>
            )}
            {video && !lightbox && (
              <div className="video-wrap" style={{ width: "41.6667%" }}>
                <React.Suspense
                  fallback={
                    <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                      <div
                        className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                        style={{
                          backgroundSize: "200% 100%",
                          animation: "shimmer 1.5s infinite",
                        }}
                      />
                    </div>
                  }
                >
                  <LazyReactPlayerLoader
                    playerId="RUsPQ8qzl"
                    baseUrl="https://players.brightcove.net"
                    refNode="video-wrap"
                    accountId="1160438696001"
                    videoId={video.video_id}
                  />
                </React.Suspense>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Styles.ImageWithTextContainerWrapper>
  );
};

export default ImageWithTextContainer;
