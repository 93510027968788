import React from "react";
import { MainImageType } from "@/src/types/types";
import * as Styles from "./styles";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";

export interface ICard {
  fields: {
    internalName: string;
    imageAsset?: MainImageType;
    content?: string;
    link?: string;
  };
}

interface ICardTopicProps {
  item: ICard;
  lang: string;
}

const CardTopic = ({ item, lang }: ICardTopicProps) => {
  const { imageAsset, content, link } = item.fields;
  return (
    <Styles.TopicCard href={`/${lang}/${link}`}>
      {imageAsset && (
        <ContentfulImage
          alt={imageAsset.fields.description || ""}
          width={imageAsset.fields.file?.details.image.width || 1000}
          height={imageAsset.fields.file?.details.image.height || 1000}
          quality={100}
          priority={true}
          src={imageAsset.fields.file?.url}
        />
      )}
      {content && (
        <Markdown
          options={{
            wrapper: "span",
            forceWrapper: true,
          }}
        >
          {content}
        </Markdown>
      )}
    </Styles.TopicCard>
  );
};

export default CardTopic;
