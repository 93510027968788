import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const CopyBlock = styled("section")<{
  $backgroundColor?: string;
  $textColor?: string;
  $highlightColor?: string;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#000"};
  color: ${({ $textColor }) => $textColor || "#FFF"};
  font-family: "articulat-cf", sans-serif;
  padding: 100px 0;

  h2 {
    font-family: var(--primary-font-family), "articulat-cf", sans-serif;
    color: ${({ $highlightColor }) => $highlightColor || ""};
    text-transform: uppercase;
    font-size: 3.5rem;
    line-height: 0.9;
    margin-bottom: 8px;
  }

  a {
    color: ${({ $textColor }) => $textColor || "#FFF"};
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    h2,
    p {
      padding-right: 100px;
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    h2,
    p {
      padding-right: 0;
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 60px 0;

    h2 {
      font-size: 2rem;
    }

    p:first-child {
      padding-top: 60px;
    }
  }
`;
