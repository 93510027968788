import React from "react";
import * as Styles from "./styles";
import { TfiArrowRight } from "react-icons/tfi";

const CTA = props => {
  const lang = props.lang.slice(0, 2);
  const sanitizeLink = path => {
    if (
      path &&
      path.indexOf("http") == -1 &&
      path.indexOf(lang) == -1 &&
      path.indexOf("#") == -1
    ) {
      path = `../${lang}/${path}`;
    }
    if (path && path.indexOf(lang) == 0) {
      path = `/${path}`;
    }
    return path;
  };
  return (
    <Styles.MainStyles $textColor={props.textColor || "#000"}>
      <a href={sanitizeLink(props.link)}>
        <h4>
          <TfiArrowRight
            color={props.textColor || "#000"}
            className="icon-arrow"
          />
          {props.headline}
        </h4>
        {props.body && (
          <p>
            {props.body.content.map((c, i) => {
              return c.content.map((ind, index) => {
                if (ind?.marks[0]?.type === "bold") {
                  return <strong key={index}>{ind.value}</strong>;
                } else {
                  return <span key={index}>{ind.value}</span>;
                }
              });
            })}
          </p>
        )}
      </a>
    </Styles.MainStyles>
  );
};

export default CTA;
