import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const FilterStyles = styled.div`
  position: relative;
  /* Parent Filter Div */
  .filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgb(217, 217, 214);
    background: rgb(255, 255, 255);
    /* Filter Type Dropdown */
    .filter-type {
      display: flex;
      flex-direction: row;
      /* Dropdown */
      .bc-dropdown {
        display: flex;
      }
    }
    /* Search */
    .filter-search {
      align-self: center;
      label {
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        margin-right: 10px;
        letter-spacing: 1px;
      }
      input {
        background: rgb(231, 231, 231);
        border: 1px solid rgb(231, 231, 231);
        padding: 5px 10px;
        margin-right: 10px;
      }
      .textbox {
        width: 150px;
        transition: 0.5s;
      }

      .textbox:focus {
        width: 200px;
        transition: 0.5s;
        outline: 0;
      }
      button {
        width: 15px;
        height: 15px;
        margin-top: -20px;
        margin-right: 10px;
        border: none;
        background-color: transparent;
        .icon-close {
          width: 15px;
          height: 15px;
          margin-top: -20px;
          display: block;
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    padding: 10px 25px;
  }

  /* Mobile Screen */
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    .filter-wrapper {
      flex-direction: column;
      .filter-search {
        align-self: baseline;
        padding: 10px 0px 10px 20px;
        input {
          width: 190px;
        }
      }
      .filter-type {
        flex-direction: column;
      }
    }
  }
`;

export const DropdownStyle = styled.div`
  .dropdown {
    position: relative;
    display: inline-block;
    /* Button */
    .button-gradient {
      background: #fff;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      padding: 20px 25px;
      transition: 0.3s;
      color: Black;
      border-right: 1px solid rgb(217, 217, 214);
      cursor: pointer;
      &:hover {
        opacity: 1;
        background: black;
        color: #fff;
      }
    }
    /* Arrow */
    .arrow-down {
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-left: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6.3%207.6a1%201%200%2001-1.6%200l-3.9-6A1%201%200%20011.7%200h7.6c.8%200%201.3.9.9%201.5L6.3%207.6z%22%20fill%3D%22%23000%22/%3E%3C/svg%3E);
    }
    .button-gradient:hover > .arrow-down {
      background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6.3%207.6a1%201%200%2001-1.6%200l-3.9-6A1%201%200%20011.7%200h7.6c.8%200%201.3.9.9%201.5L6.3%207.6z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E);
    }

    .arrow-up {
      transform: rotate(-180deg) !important;
      -webkit-transform: rotate(-180deg) !important;
    }
  }
  /* Content on Dropdown */
  .dropdown-content {
    display: none;
    position: absolute;
    width: 550px;
    max-height: 400px;
    overflow: auto;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 2;
  }
  .dropdown.show .dropdown-content {
    margin-top: 1px;
    display: block;
    line-height: 2;
  }
  /* Checkbox */
  .pretty {
    margin-top: 1em;
    width: 49%;
    margin-right: 0px;
  }
  .pretty .state label {
    font-weight: bold;
    font-size: 14px;
    margin-left: 8px;
  }
  .pretty.p-switch .state label:before,
  .pretty.p-switch .state label:after {
    margin-top: 3px;
  }
  .pretty.p-switch.p-fill input:checked ~ .state label:after {
    left: 1.2em;
  }
  .pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
    background-color: #000 !important;
  }
  .pretty.p-switch input:checked ~ .state.p-success:before {
    border-color: #000 !important;
  }
  /* Tablet Screen */
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    .dropdown {
      .arrow-down {
        margin-left: auto;
      }
    }
  }
  /* Mobile Screen */
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    .dropdown {
      width: 100%;
      .arrow-down {
        position: absolute;
        right: 15px;
        top: 25px;
        margin-left: auto;
      }
    }
    .dropdown-content {
      width: 100%;
    }
    .button-gradient {
      border-bottom: 1px solid rgb(217, 217, 214);
      border-right: none;
    }
    .pretty {
      width: 100%;
    }
  }
`;
