import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const BackgroundPlayer = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    display: none;
  }
`;
