import React from "react";
import * as Styles from "../DescriptionBlockStyles";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import Markdown from "markdown-to-jsx";

const ModelA = props => {
  const {
    backgroundColor,
    backgroundColorDescription,
    colorBar,
    description,
    label,
    titleDescription,
  } = props;

  return (
    <Styles.DescriptionBlock
      $backgroundColor={backgroundColor}
      $backgroundColorDescription={backgroundColorDescription}
      $colorBar={colorBar}
    >
      <ContainerStyle fluid="true">
        {titleDescription && (
          <div className="titleDescription">
            <Markdown>{titleDescription}</Markdown>
          </div>
        )}
        <div className="wrapper-description">
          {label && (
            <div className="label">
              <Markdown>{label}</Markdown>
            </div>
          )}
          <div className="line-vertical">
            {description && (
              <div className="description">
                <Markdown>{description}</Markdown>
              </div>
            )}
          </div>
        </div>
      </ContainerStyle>
    </Styles.DescriptionBlock>
  );
};

export default ModelA;
