import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const ImageWithTextContainerWrapper = styled("section")<{
  $backgroundColor: string;
  $textColor: string;
  $align: string;
  $displayImage: string;
  $containerColor: string;
  $maxHeightSize: string;
  $transitionDuration: string;
  $maxWidthSize: string;
}>`
  content-visibility: auto;
  contain-intrinsic-size: 1280px 500px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  color: ${({ $textColor }) => $textColor || "#000"};
  font-family: var(--primary-font-family), sans-serif;
  padding: 60px 0;
  max-width: 1280px;
  margin: 0 auto;
  > div > div {
    align-items: center !important;
    flex-flow: none !important;
    flex-direction: ${({ $align }) => ($align.includes("left") ? "row" : "row-reverse") || "row"};
    flex-flow: ${({ $align }) =>
      ($align.includes("left") ? "row" : "row-reverse") || "row"}!important;
    > div:first-child {
      padding: 0 60px;
    }
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    line-height: 0.9;
    padding: ${({ $align }) =>
      ($align.includes("left") ? "20px 0px 20px 0" : "20px 0 20px 0px") ||
      "20px 0px 20px 0"};
  }
  p {
    margin-bottom: 20px;
    font-size: 17px;
  }
  > a,
  > p {
    padding: ${({ $align }) =>
      ($align.includes("left") ? "0 100px 10px 0" : "0 0 10px 100px") ||
      "0 100px 10px 0"};
  }
  p:last-of-type {
    margin-bottom: 20px;
  }

  .ctaBody p {
    margin-bottom: 0px;
  }

  .animated-blurb ul {
    margin-left: 7%;
  }

  /* Display Image */
  .displayImage {
    flex-direction: ${({ $displayImage }) => $displayImage || ""}!important;
    }
  }

  /* BorderStyle */
  .borderStyle {
    box-shadow: 0px 10px 20px #00000029;
    border: 1px solid #707070;
    background-color: ${({ $containerColor }) => $containerColor || "#fff"};

    .animated-image.visible {
      padding: 0px !important;
    }
  }

  /* MaxWidthSize */
  .image-resize {
    text-align: center;
    display: flex;
  }

  .video-wrap {
    position: relative;
    left: 0;
    width: ${({ $align }) => ($align.includes("logo") ? "100% !important" : "640px")};
    img {
      object-fit: ${({ $align }) => ($align.includes("logo") ? "contain !important" : "cover")};
    }
  }
  .video-js {
    width: 540px;
    height: 304px;
    border-radius: 5px;
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    padding: 30px 0;
    overflow: hidden;

    .video-wrap {
      left: ${({ $align }) => ($align.includes("left") ? "-36px" : "0") || 0};
    }

    h3 {
      font-size: 2rem;
      padding: ${({ $align }) =>
        ($align.includes("left") ? "20px 0 20px 0" : "20px 0 20px 0") ||
        "20px 0 20px 0"};
    }

    > a,
    > p {
      padding: ${({ $align }) =>
        ($align.includes("left") ? "0 50px 10px 0" : "0 0 10px 50px") ||
        "0 50px 10px 0"};
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    .video-wrap,
    .video-js {
      width: 360px;
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    contain-intrinsic-size: 320px 500px;
    > div > div {
      display: flex;
      flex-direction: column-reverse;
    }
    .video-wrap {
      width: 100% !important;
      height: 177px;
      .video-js {
        width: 100%;
        height: 177px;
      }
    }

    h3 {
      font-size: 2rem;
      padding: 20px 0;
    }

    > a,
    > p {
      padding: 0 0 10px;
    }
  }
  .animated-container {
    .animated-row {
      gap: 40px;
      .animated-blurb,
      .animated-image {
        // Transition duration from Contentful
        -webkit-transition: ${({ $transitionDuration }) =>
          `all ${$transitionDuration || 300}ms cubic-bezier(0.645, 0.045, 0.355, 1)`};
        transition: ${({ $transitionDuration }) =>
          `all ${$transitionDuration || 300}ms cubic-bezier(0.645, 0.045, 0.355, 1)`};
        flex: unset;
      }
      .animated-blurb {
        // From side animation
        &.from-side {
          &.visible {
            transform: translateX(${({ $align }) => ($align.includes("left") ? "0" : "0") || 0});
          }
          &.not-visible {
            transform: translateX(
              ${({ $align }) => ($align.includes("left") ? "-100vw" : "100vw") || 0}
            );
          }
        }
        // From bottom animation
        &.from-bottom {
          &.not-visible {
            transform: translateY(200%);
            opacity: 0;
          }
          &.visible {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
      .animated-image {
        // Fade image in option in Contentful
        &.fade-in {
          &.visible {
            opacity: 1;
          }
          &.not-visible {
            opacity: 0;
          }
        }
      }
      @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
        padding: 0 30px;
        .animated-blurb,
        .animated-image {
          // Transition duration from Contentful
          -webkit-transition: none !important;
          transition: none !important;
          transform: translateX(0) translateY(0) !important;
          flex: unset !important;
        }
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column !important;
      }
    }
  }
`;
