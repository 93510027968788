import styled from "styled-components";

export const SecondaryNavbarStyles = styled("div")<{
  $backgroundColor?: string;
  $height?: string;
  $secondNavScroll?: boolean;
  $secondNavScrollTablet?: boolean;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  margin-bottom: ${({ $height }) => $height || "75px"};
  /* Model A */
  .Model-A {
    background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    top: ${({ $secondNavScroll }) => ($secondNavScroll ? "72px" : "130px")};
    height: ${({ $height }) => $height || "75px"};
    width: 100%;
    color: #fff;
    border-top: 1px solid #fff;
    position: fixed;
    z-index: 99;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    -webkit-animation: ${({ $secondNavScroll }) =>
      $secondNavScroll
        ? " fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both"
        : ""};
    animation: ${({ $secondNavScroll }) =>
      $secondNavScroll
        ? "fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both"
        : ""};
    border-top: #fff;
    > div {
      margin: 5px;
    }

    /* Tablet */
    @media only screen and (max-width: 899px) {
      height: auto;
      flex-wrap: wrap;
      display: ${({ $secondNavScroll }) =>
        $secondNavScroll ? "flex" : "none"};
      padding: 20px;
      top: ${({ $secondNavScrollTablet }) =>
        $secondNavScrollTablet ? "0px" : "67px "};
      -webkit-animation: ${({ $secondNavScrollTablet }) =>
        $secondNavScrollTablet
          ? " fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both"
          : ""};
      animation: ${({ $secondNavScrollTablet }) =>
        $secondNavScrollTablet
          ? "fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both"
          : ""};
    }

    /* Animation */
    @-webkit-keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  /* Model B */
  .Model-B {
    display: flex;
    height: ${({ $height }) => $height || "75px"};
    width: 100%;
    max-width: 1280px;
    margin: auto;
    color: #fff;
    border-top: 1px solid #fff;
    padding: 0px 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-top: #fff;
    > div {
      margin: 5px;
    }
    /* Tablet */
    @media only screen and (max-width: 899px) {
      height: auto;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 20px;
    }
  }
`;

export const CtaBrightcoveStyles = styled("div")<{ $textColor?: string }>`
  // Border line effect.
  .NoBorder {
    span {
      display: inline-block;
      position: relative;
      color: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    }
    span::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${({ $textColor }) =>
        $textColor ? $textColor : "#000"};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    span:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
