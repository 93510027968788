import styled, { css } from "styled-components";

const HeaderElement = css<{
  $textColor: string | undefined;
}>`
  font-family: "articulat-cf";
  font-size: 18px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${({ $textColor }) => ($textColor ? `${$textColor}` : "#000")};
`;

const SubheaderElement = css<{
  $textColor: string | undefined;
}>`
  font-family: "articulat-cf";
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: ${({ $textColor }) => ($textColor ? `${$textColor}` : "#000")};
`;

const BoxShadow = css`
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  :hover {
    cursor: pointer;
  }
`;

export const GridCardWrapper = styled("div")<{
  $textColor: string | undefined;
  $cardClass: string | undefined;
  $backgroundColor: string | undefined;
}>`
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  .card-device {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c9c9c9;
    padding: 0px;
    margin-top: 100px;
    margin-bottom: 40px;
    .text-container {
      padding: 30px;
      h5 {
        ${HeaderElement}
      }
      span,
      p {
        ${SubheaderElement}
      }
    }

    /* Link */
    .content-cta {
      display: block;
      margin-top: 30px;
      font-weight: bold;
    }
  }
  .sub-text {
    margin-bottom: 16px;
  }
  /*********************************** Card Square *************************************************/
  /***********************************************************************************************/
  .card-square {
    /* Container body */
    .text-container {
      padding: 30px 30px 30px 0px;
      h5 {
        ${HeaderElement}
      }
      span,
      p {
        ${SubheaderElement}
      }
      /* Link */
      .content-cta {
        display: block;
        margin-top: 30px;
        font-weight: bold;
      }
    }
  }

  /*********************************** Card Grid Icon *******************************************/
  /***********************************************************************************************/
  .card-icon {
    width: 100%;
    // Image Icon
    img {
      max-width: ${({ $cardClass }) => {
        switch ($cardClass) {
          case "icon":
            return "80px";
          default:
            return "unset";
        }
      }};
      margin: ${({ $cardClass }) => {
        switch ($cardClass) {
          case "icon":
            return "0 auto";
          default:
            return "unset";
        }
      }};
      display: ${({ $cardClass }) => {
        switch ($cardClass) {
          case "icon":
            return "block!important";
          default:
            return "unset";
        }
      }};
    }
    .svg-image {
      max-width: 150px;
      display: block;
      margin: 0 auto;
      @media only screen and (max-width: 1279px) {
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
    /* Container body */
    .text-container {
      padding: 30px 0;
      height: 100%;
      h5 {
        ${HeaderElement}
      }
      span,
      p {
        ${SubheaderElement}
      }
      /* Link */
      .content-cta {
        display: block;
        font-weight: bold;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .cta-text > div > * {
        margin-bottom: 0;
      }
    }
  }
  /*********************************** Card Grid SVG Icon *******************************************/
  /***********************************************************************************************/
  .card-svg-icon {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    img {
      max-width: 150px;
      display: block;
      margin: 0 auto;
      @media only screen and (max-width: 1279px) {
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
    /* Container body */
    .text-container {
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-weight: 400;
      h5 {
        ${HeaderElement}
      }
      span,
      p {
        ${SubheaderElement}
      }
      /* Link */
      .content-cta {
        display: block;
        font-weight: bold;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 350px;
      }

      .cta-text > div > * {
        margin-bottom: 0;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .card-icon {
      margin-bottom: 60px;
      .text-container {
        padding: 0px;
      }
      img {
        max-width: ${({ $cardClass }) => {
          switch ($cardClass) {
            case "icon":
              return "140px";
            default:
              return "unset";
          }
        }};
      }
    }
  }
  /*********************************** Card Grid Image Left **************************************/
  /***********************************************************************************************/
  .card-image-left {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    // Image Icon
    img {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 80px;
      object-fit: contain !important;
      width: 80px;
    }
    /* Container body */
    .text-container {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        ${HeaderElement}
      }
      span,
      p {
        ${SubheaderElement}
      }
      /* Link */
      .content-cta {
        display: block;
        margin-top: 30px;
        font-weight: bold;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .card-image-left {
      flex-direction: row;
      gap: 30px;
      .text-container {
        padding: 30px 0px;
        width: 100%;
        max-width: 100%;
      }
      .text-container {
        padding: 0px;
      }
    }
  }
  /*********************************** Card Grid Padded Container **************************************/
  /***********************************************************************************************/
  .card-padded-container {
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? `${$backgroundColor}` : "#fff"};
    padding: 20px;
    margin-bottom: 40px;
    ${BoxShadow}
    .text-container {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 160px);
      width: 100%;
      h5 {
        ${HeaderElement}
      }

      span,
      p {
        ${SubheaderElement}
      }
    }
    /* Image */
    img {
      display: flex !important;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background-color: #ffffff;
      object-fit: contain !important;
    }
    /* Link */
    .content-cta {
      display: block;
      margin-top: auto;
      justify-items: flex-end;
      font-weight: bold;
      > div {
        width: 100%;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 1279px) {
    .card-padded-container {
      width: 100%;
      .text-container {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .card-padded-container {
      .text-container {
        width: 100%;
      }
    }
  }
  /*********************************** Card Grid No Image **************************************/
  /***********************************************************************************************/
  .card-no-image {
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? `${$backgroundColor}` : "#fff"};
    padding: 20px;
    margin-bottom: 40px;
    min-height: 225px;
    ${BoxShadow}
    .text-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      h5 {
        ${HeaderElement}
        min-height: 50px;
      }

      span,
      p {
        ${SubheaderElement}
        margin-bottom: 20px;
      }
    }
    /* Link */
    .content-cta {
      display: block;
      margin-top: auto;
      justify-items: flex-end;
      font-weight: bold;
      > div {
        width: 100%;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 1279px) {
    .card-no-image {
      .text-container {
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .card-no-image {
      .text-container {
        width: 100%;
      }
    }
  }
`;
