import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const ProductFeatureGrid = styled("div")<{
  $textColor?: string;
  $backgroundColor?: string;
  $paddingTopBottom?: string;
}>`
  color: ${({ $textColor }) => $textColor || "#FFF"};
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#000000"};
  padding: ${({ $paddingTopBottom }) =>
    `${$paddingTopBottom ? `${$paddingTopBottom}px 0px` : "100px 0"}`};
  font-family: var(--primary-font-family), sans-serif;
  flex-wrap: wrap;
  /* new Template Headline and body */
  .headline-body {
    h4 {
      font-size: 80px !important;
    }
  }
  .cta-wrapper {
    margin: 40px 0px;
    .cta-text {
      &:hover {
        &:after {
          width: 0%;
        }
      }
    }
    &:hover {
      .cta-text {
        &:after {
          width: 0% !important;
        }
      }
      .icon-downloadCircular {
        transform: scale(1.1);
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .cta-wrapper {
      margin: 0px;
    }
    .cta-wrapper a {
      display: flex;
      justify-content: center;
      .cta-text {
        margin: 10px 0px;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .cta-wrapper a {
      display: none !important;
      padding-left: 30px;
      .cta-text {
        max-width: 320px;
      }
    }
  }

  /* New template Card for Image/logo/body/cta */
  .image-logo-body-cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c9c9c9;
    padding: 0px !important;
    margin-top: 20px;
    width: calc((1280px - 80px) / 3);

    @media only screen and (max-width: 1279px) {
      width: calc((100% - 60px) / 3);
    }
    /* Tablet  */
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    /* Image */
    .image-block {
      display: contents;
      width: 100%;
      height: 110px;
      object-fit: cover;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    /* Logo */
    .block-logo {
      display: flex;
      max-width: 100%;
      justify-content: center;
      height: 70px;
      margin: 10px 0px;
      img {
        object-fit: scale-down;
      }
    }
    /* Text  */
    .block-text-container {
      padding: 3% 10%;
    }
    .block-body {
      height: 60px;
      overflow: auto;
      display: block;
    }
    .cta {
      margin-top: 10px;
    }
  }
  /* END */

  .feature-grid-header {
    text-align: center;
    img {
      height: 20px;
    }

    h2 {
      font-size: 3.5rem;
      margin-bottom: 64px;
      font-family: var(--primary-font-family), sans-serif;
      margin: 20px 15%;
      text-align: center;
    }
  }

  /* BoldTitle and BoldBody */
  .boldTitle {
    font-weight: bold;
    font-size: 2.7rem !important;
  }
  .boldBody {
    font-weight: bold;
    font-size: 2rem !important;
  }

  /* Image Square */
  .squareImage {
    padding: 0px;
    margin-bottom: 15px;
    .block-image {
      width: 100% !important;
      height: auto !important;
      object-fit: initial !important;
    }
  }

  .feature-grid-block {
    width: calc((1280px - 80px) / 3) !important;
    flex: unset !important;
    max-width: unset !important;
    /* Tablet  */
    @media only screen and (max-width: 1279px) {
      width: calc((100% - 60px) / 3) !important;
      flex: unset !important;
      max-width: unset !important;
    }
    /* Tablet  */
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      flex: unset !important;
      max-width: unset !important;
    }
    a,
    .block {
      font-family: var(--primary-font-family), sans-serif;
      text-decoration: none;
      color: ${({ $textColor }) => $textColor || "#FFF"};
      display: block;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      padding: 32px 16px 0;
      margin: 0;
      transition:
        background-color 500ms ease,
        border 500ms ease;
      min-height: 275px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 1.5rem;
        margin-bottom: 18px;
        transition: 500ms ease;
        text-transform: uppercase;
        font-weight: 700;
        span,
        p {
          font-size: 1.5rem;
        }
      }

      > span:first-of-type {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
        padding: 10px 0;
        position: relative;
        hr {
          background-color: ${({ $textColor }) => $textColor || "#FFF"};
          transition: background-color 500ms ease;
          margin-bottom: 12px;
        }
      }
      a,
      a:visited {
        color: ${({ $textColor }) => $textColor || "#FFF"};
      }
      h3,
      p,
      span {
        transition:
          color 500ms ease,
          transform 500ms ease,
          opacity 500ms ease;
      }
      .icon-arrow {
        float: left;
        height: 34px;
        top: -4px;
      }
      .icon-arrow:last-of-type {
        position: absolute;
        left: 0px;
        top: 6px;
        opacity: 0;
        transition: opacity 500ms ease;
      }
      &:hover {
        background-color: ${({ $textColor }) => $textColor || "#FFF"};
        .icon-arrow:last-of-type {
          opacity: 1;
        }

        h3,
        p,
        span {
          color: ${({ $backgroundColor }) => $backgroundColor || "#FFF"};
          opacity: 1;
        }
        hr {
          background-color: ${({ $backgroundColor }) =>
            $backgroundColor || "#FFF"};
        }
      }
    }
    &.cta.hover {
      h3 {
        text-align: center;
        transform: translateY(calc(100% - 18px));
        transition: 500ms ease;
        text-transform: uppercase;
      }
      a > span:first-of-type,
      a > p:first-of-type {
        font-size: 1rem;
        opacity: 0;
        transition: 500ms ease;
        min-height: 90px;
      }
      a > p.cta-link {
        font-size: 0.8rem !important;
        opacity: 1 !important;
        min-height: 70px !important;
      }
      .icon-arrow {
        float: left;
        height: 34px;
        top: -6px;
      }
      .icon-arrow:last-of-type {
        position: absolute;
        left: 0px;
        top: 17px;
        opacity: 0;
        transition: opacity 500ms ease;
      }
      &:hover {
        a > span:first-of-type,
        a > p:first-of-type {
          opacity: 1;
        }
        h3 {
          transform: translateY(0);
        }
        .icon-arrow:last-of-type {
          opacity: 1;
        }
      }
      /* &.tall a{
        height:350px;
        h3{
          transform:translateY(calc(150% - 18px));
        }
        &:hover{
            h3{
            transform:translateY(0);
          }
        }
      } */
    }
    &.no-cta {
      a,
      .block {
        justify-content: flex-start;
        padding: 64px 32px 32px;
      }
    }

    &.svgs {
      a,
      .block {
        padding-top: 32px;
        display: flex;
        flex-basis: 50%;
        .svg-header {
          height: 200px;
          text-align: center;
          svg {
            min-height: 100%;
            max-height: 100%;
            &.high-five {
              transform: scale(1.2);
            }
          }
        }
        .blurb-wrapper {
          span {
            display: block;
            padding: 5px;
          }
        }
        &:hover {
          background-color: unset;
          color: unset;
          h3,
          p,
          span {
            color: unset;
          }
        }
      }
    }
  }

  .show-more {
    margin-top: 20px;
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    .feature-grid-header {
      h2 {
        font-size: 2.5rem;
      }
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    .feature-grid-header {
      h2 {
        font-size: 2rem;
        margin: 20px 0;
      }
    }
  }
  &.styled-feature-blocks {
    .feature-grid-header {
      text-transform: uppercase;
      h2 {
        font-size: 2.4rem;
        text-transform: initial;
      }
    }
    div > div {
      > div.styled-feature-block {
        margin-bottom: 20px;
        margin-top: 20px;
        .block-image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          .block-image {
            display: block;
            width: 230px;
            object-fit: contain;
          }
          .block-svg {
            display: block;
            width: 160px;
            object-fit: contain;
          }
        }
        .block-text-container {
          &.percentage {
            font-size: 1.8rem;
            font-weight: 700;
          }
          h4 {
            font-size: 1.8rem;
            margin: 10px 0;
          }
          h5 {
            font-size: 1.4rem;
            margin-bottom: 15px;
          }
          .block-body {
            font-size: 1.2rem;
            display: block;
            padding-right: 4%;
            margin-bottom: 15px;
          }
          .cta {
            p {
              font-weight: 500;
              font-size: 19.2px;
            }
            a {
              span {
                &.icon-arrow {
                  top: 5px;
                }
              }
            }
          }
        }
        @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
          margin-top: 15px;
        }
      }
    }
  }

  .default-feature-headline {
    font-weight: 700;
  }

  > div > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  > div {
    @media only screen and (max-width: 1279px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .our-differences-are-what-makes-us-strong-wrapper {
    flex-wrap: wrap;

    .svgs {
      width: calc((1280px - 40px) / 2) !important;
      a,
      .block {
        svg {
          margin: 0 auto;
        }
      }
      @media only screen and (max-width: 1279px) {
        width: calc((100% - 30px) / 2) !important;
      }
      @media only screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
`;

export const ProductFeatureGridWrapper = styled("div")<{
  $isOpen?: any;
  $maxHeight: string;
  $minHeight: string;
}>`
  display: flex;
  justify-content: center;
  max-width: 1280px;
  gap: 40px;
  margin: 0 auto;
  flex-wrap: wrap;
  /* Tablet  */
  @media only screen and (max-width: 1279px) {
    gap: 30px;
    max-width: 100%;
    padding: 0 30px;
  }
  @media only screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
  }
  > div {
    h3 {
      min-height: 64px;
    }
  }
`;
