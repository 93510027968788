import styled from "styled-components";
import { BREAK_POINTS } from "../../../lib/breakPoints";

export const QuoteBlock = styled("section")<{
  $backgroundColor?: string;
  $highlightColor?: string;
  $textColor?: string;
}>`
  padding: 100px 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#000"};
  color: ${({ $textColor }) => $textColor || "#fff"};
  font-family: "articulat-cf", sans-serif;

  blockquote {
    color: ${({ $textColor }) => $textColor || "#fff"};
    font-family: var(--primary-font-family), sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.005em;
    position: relative;
    margin: 100px auto;
    max-width: 800px;
    &::before {
      font-size: 128px;
      line-height: 100%;
      display: inline-block;
      position: absolute;
      color: #000;
    }
    &::before {
      content: "“";
      transform: translate(-10%, -66%);
      color: #000;
    }
  }

  cite {
    font-style: normal;
    font-family: var(--primary-font-family), sans-serif;
    text-transform: uppercase;
    display: block;
    margin-top: 20px;
    line-height: 1.2rem;
    font-size: 1rem;
    color: ${({ $highlightColor }) => $highlightColor || "#00EAE4"};
    span {
      font-weight: 500;
      display: block;
      color: ${({ $textColor }) => $textColor || "#fff"};
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 60px 0;
    blockquote {
      font-size: 1.4rem;
    }

    cite {
      span {
        display: block;
      }
    }
  }
`;

export const QuoteBlockWithCustomer = styled("section")<{
  $backgroundColor?: string;
  $textColor?: string;
  $highlightColor?: string;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#000"};
  color: ${({ $textColor }) => $textColor || "#fff"};
  font-family: "articulat-cf", sans-serif;
  max-width: 1280px;
  margin: 0 auto;

  > div > div > div {
    &:nth-child(1) {
      img {
        width: 260px;
        height: 366px;
      }

      img {
        object-fit: contain !important;
      }
    }
    &:nth-child(2) {
      padding: 60px 0;
      img {
        max-height: 60px;
        filter: grayscale(100%) contrast(100%) !important;
        background-color: ${({ $backgroundColor }) =>
          $backgroundColor || "transparent"};
      }
    }
    &:nth-child(3) {
      padding: 60px 0;
      p {
        color: ${({ $textColor }) => $textColor || "#fff"};
      }
    }
  }

  h4 {
    font-family: var(--primary-font-family), sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 1;
    padding: 10px 0 20px;
  }

  blockquote {
    color: ${({ $textColor }) => $textColor || "#fff"};
    font-family: var(--primary-font-family), sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.005em;
    position: relative;
    margin: 30px auto;
    &::before {
      font-size: 128px;
      line-height: 100%;
      display: inline-block;
      position: absolute;
      color: #000;
    }
    &::before {
      content: "“";
      transform: translate(-10%, -66%);
      color: #000;
    }
  }

  cite {
    font-style: normal;
    font-family: var(--primary-font-family), sans-serif;
    text-transform: uppercase;
    display: block;
    margin-top: 20px;
    line-height: 1.2rem;
    font-size: 1rem;
    color: ${({ $highlightColor }) => $highlightColor || "#00EAE4"};
    span {
      font-weight: 500;
      display: block;
      color: ${({ $textColor }) => $textColor || "#fff"};
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    blockquote {
      font-size: 1.1rem;
    }
    > div > div > div {
      &:nth-child(1) {
        padding-left: 0 !important;
      }
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    blockquote {
      font-size: 1.2rem;
    }
    > div {
      padding: 0 !important;
      > div > div {
        &:nth-child(1) {
          padding: 0 !important;
          max-height: 300px;
        }
        &:nth-child(2) {
          padding-left: 60px !important;
        }
        &:nth-child(3) {
          padding-right: 60px !important;
        }
      }
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    > div {
      > div > div {
        &:nth-child(2) {
          padding: 30px 60px !important;
        }
        &:nth-child(3) {
          padding: 30px 60px !important;
        }
      }
    }
  }
`;
