import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const LogoGrid = styled("section")<{
  $backgroundColor: string;
  $textColor?: string;
  $carousel?: string;
  $lang?: string;
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "#transparent"};
  color: ${({ $textColor }) => $textColor || "#FFF"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px 0 30px;

  h3 {
    font-family: var(--primary-font-family), sans-serif;
    margin: 0;
    text-transform: uppercase;
    font-size: 2.2rem;
    line-height: 0.9;
    padding: 0 22% 30px;
    text-align: center;
  }

  img {
    width: ${({ $carousel }) => $carousel || "100%"};
    height: 100%;
    min-height: 120px;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
    object-fit: contain !important;
    height: 200px !important;
    width: 200px;
  }

  img {
    max-width: 100% !important;
    margin: 20px 0 !important;
    border-radius: 5px !important;
    transition:
      filter 500ms ease,
      background 500ms ease !important;
  }
  a {
    img {
      &:hover {
        background-color: ${({ $backgroundColor }) =>
          $backgroundColor || "transparent"};
      }
    }
  }
  .cta-div {
    a {
      justify-content: flex-end;
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    h3 {
      padding: 0 10% 30px;
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    h3 {
      padding: 0 0 30px;
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 60px 0;
  }
`;
