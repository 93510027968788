import React from "react";
// Grid System
import { GridCard } from "./GridCard/GridCard";
import { CardGridSection } from "./CardGridStyles";
import VerboseCTA from "../VerboseCTA/VerboseCTA";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import Markdown from "markdown-to-jsx";

// Function
function CardGrid({
  backgroundColor,
  header,
  cards,
  cardStyle,
  customBackgroundColor,
  blockVerboseCta,
  lang,
}) {
  return (
    <CardGridSection
      $backgroundColor={backgroundColor || customBackgroundColor || "#fff"}
      $cardStyle={cardStyle}
    >
      <ContainerStyle className="card-grid-container">
        {header && (
          <h4 className="cardgrid-header">
            <Markdown>{header}</Markdown>
          </h4>
        )}
        <RowStyle>
          {cards &&
            cards.map((card, i) => {
              return (
                <GridCard
                  key={i}
                  card={card}
                  cardStyle={cardStyle}
                  lang={lang}
                />
              );
            })}
        </RowStyle>
        <RowStyle
          style={{ justifyContent: "space-around" }}
          className="card-grid-cta"
        >
          <div className="verbose-card-grid">
            {blockVerboseCta &&
              blockVerboseCta.map((verboseCta, i) => {
                verboseCta = verboseCta && {
                  ...verboseCta.fields,
                  lang,
                };
                return <VerboseCTA key={i} {...verboseCta} />;
              })}
          </div>
        </RowStyle>
      </ContainerStyle>
    </CardGridSection>
  );
}

export default CardGrid;
