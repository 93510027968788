import React, { lazy } from "react";
// Style
import * as Styles from "../FullWidthVideoBlockStyles";
// Video
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);

import Cookies from "js-cookie";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import CallToAction from "../../CallToAction/CallToAction";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";

const FullWidthVideoBlock = ({
  video,
  eyebrow,
  body,
  header,
  verboseCta,
  verboseCtaSecond,
  lightboxVideo,
  videoBodyCta,
  playerId,
  logo,
  lang,
}) => {
  // Video Options
  const options = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
    aspectRatio: "16:9",
    nativeTextTracks: false,
    preloadTextTracks: false,
    nativeVideoTracks: false,
    nativeControlsForTouch: false,
    userActions: {
      click: false,
      doubleClick: false,
    },
  };
  // Render Text
  const renderText = () => {
    return (
      <div className="container-text">
        <div className="wrapper-text">
          {/* Logo */}
          {logo && (
            <div className="logo">
              <ContentfulImage
                alt={logo.fields.title || ""}
                width={logo.fields.file?.details.image.width || 1000}
                height={logo.fields.file?.details.image.height || 1000}
                quality={100}
                priority={true}
                src={logo.fields.file?.url}
              />
            </div>
          )}
          {/* Header Eyebrow Body */}
          {eyebrow && <Markdown>{eyebrow}</Markdown>}
          {header && <Markdown>{header}</Markdown>}
          {body && <Markdown>{body}</Markdown>}
          <div className="wrapper-button">
            {/* Verbose CTA */}
            {verboseCta ? (
              <VerboseCTA
                {...verboseCta.fields}
                lang={lang}
                iconColor={verboseCta.fields.iconColor}
              />
            ) : (
              ""
            )}
            {verboseCtaSecond ? (
              <VerboseCTA
                {...verboseCtaSecond.fields}
                lang={lang}
                iconColor={verboseCta.fields.iconColor}
              />
            ) : (
              ""
            )}
            {/* Link lightbox */}
            {lightboxVideo && (
              <CallToAction
                id="hero-cta"
                callToActionCopy={
                  <Markdown>{videoBodyCta}</Markdown> || "Watch this"
                }
                linkPath="#"
                iconImage="play"
                iconColor="#fff"
                lightboxData={{
                  type: "video",
                  content: lightboxVideo.video_id,
                  playerId,
                }}
                textColor="#fff"
                playerId={playerId || "RUsPQ8qzl"}
                lang={lang}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Styles.FullWidthVideoBlock>
      <ContainerStyle id="Model-D" fluid="maxFluid">
        {/* Video Player */}
        <React.Suspense
            fallback={
              <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                  style={{
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
              </div>
            }
          >
        <LazyReactPlayerLoader
          playerId={playerId || "RUsPQ8qzl"}
          baseUrl="https://players.brightcove.net"
          refNode="video-wrap"
          accountId={video.account_id || "1160438696001"}
          options={options}
          videoId={video.video_id}
          onEmbedCreated={el => {
            sfmcCookie();
            el.setAttribute("data-bc-known-user", 1);
            el.setAttribute("data-bc-custom-guid", Cookies.get("_sfmc_id_"));
          }}
        /></React.Suspense>
        {renderText()}
      </ContainerStyle>
    </Styles.FullWidthVideoBlock>
  );
};

export default FullWidthVideoBlock;
