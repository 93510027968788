import styled from "styled-components";

export const Summary = styled("div")`
  details {
    margin-bottom: 10px;
    border-radius: 6px;
    &[open] {
      summary {
        h5 {
          &:after {
            content: "\\2212";
          }
        }
      }
    }
    summary {
      padding: 20px;
      cursor: pointer;
      background: #f2f2f2;
      h5 {
        padding: 5px;
        vertical-align: middle;
        &:after {
          content: "\\002B";
          font-size: 30px;
          padding: 5px;
          vertical-align: middle;
        }
      }
    }
  }
`;
export const PlainText = styled("div")<{ $backgroundColor?: any }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  padding: 50px 0;
  > div {
    width: 1280px;
    max-width: 1280px !important;
    @media only screen and (max-width: 1280px) {
      max-width: 100% !important;
      width: 100% !important;
      padding: 0 30px !important;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100% !important;
      padding: 0 30px !important;
    }
    h1 {
      font-size: 45px;
      margin-bottom: 0.67em;
      line-height: 1.1;
      font-weight: 700;
      @media only screen and (max-width: 1280px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    h2 {
      font-family: var(--primary-font-family), sans-serif;
      margin-bottom: 40px;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    h3 {
      font-family: var(--primary-font-family), sans-serif;
      padding-bottom: 30px;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    h4 {
      font-family: var(--primary-font-family), sans-serif;
      padding-bottom: 25px;
      font-size: 1.8rem;
      text-transform: uppercase;
    }

    h5 {
      font-family: var(--primary-font-family), sans-serif;
      padding-bottom: 20px;
      font-size: 1.4rem;
      text-transform: uppercase;
    }

    p {
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 300;
    }

    ul,
    ol {
      margin-left: 40px;
      list-style: disc;
      li {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 0.725rem;
      }
    }

    p,
    li {
      font-weight: 300;
      a {
        border-bottom: 2px solid #fff;
        text-underline-offset: 2px;
        color: #000;
        p {
          display: inline;
        }
        &:hover {
        }
      }
    }

    hr {
      background: rgb(214, 214, 214);
      margin: 60px 0;
    }

    .social-wrapper {
      padding: 30px !important;
    }
  }
  &#broader-reach-and-deeper-engagement {
    text-align: center;
    padding-bottom: 25px;
    h2 {
      margin-bottom: 0;
    }
  }
  &#social-widget {
    width: calc((1280px - 60px) / 3) !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  &#social-widget > div {
    width: 292px !important;
  }
  // Table styles
  section {
    padding: 40px;
    .contained {
      .features-content-table,
      .editions-table,
      .support-table {
        width: 100%;
        padding: 20px;
        margin-bottom: 40px;
        tr {
          &:nth-of-type(even) {
            background: #f6f6f6;
          }
          &.headers {
            td {
              background: black;
              color: white;
              text-align: right;
              font-family: "Agrandir";
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          th,
          td {
            padding: 10px;
          }
          th {
            font-family: "Agrandir";
            text-transform: uppercase;
            background: #08088c;
            color: #fff;
          }
          td {
            .icon-check-mark,
            .icon-star {
              display: inline-block;
              vertical-align: middle;
              font-size: 26px;
              line-height: 14px;
              color: #95c039;
            }
            .icon-check-mark:before {
              content: "\\2713";
            }
            .icon-star:before {
              content: "\\2713";
            }
          }
        }
      }
    }
  }
`;
