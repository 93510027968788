import React from "react";
import ModelA from "./Models/ModelA";

const DescriptionBlock = props => {
  return (
    <>
      <ModelA {...props} />
    </>
  );
};

export default DescriptionBlock;
