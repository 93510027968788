import React, { useEffect } from "react";
import { Container } from "react-grid-system";
import Markdown from "markdown-to-jsx";
import { marketingTables, enterpriseTables } from "./pricing-tables";
import { agencyHTML, supportHTML } from "./tableHTML";
import * as Styles from "./PlainTextStyles";

const AgencyProgramTiers = () => {
  return <Markdown>{agencyHTML}</Markdown>;
};
const SupportPackageComparison = props => {
  const { lang } = props;
  return <Markdown>{supportHTML[lang]}</Markdown>;
};
const SummaryDetails = props => {
  const { lang } = props;
  const sumdetData = [
    {
      key: "brightcove-for-marketing",
      summary: "Brightcove for Marketing",
      detail: marketingTables[lang.slice(0, 2)],
    },
    {
      key: "brightcove-for-enterprise",
      summary: "Brightcove for Enterprise",
      detail: enterpriseTables[lang.slice(0, 2)],
    },
  ];
  useEffect(() => {
    // Override for card link behavior on /pricing page
    if (typeof document !== "undefined") {
      const cardLinks = document.querySelectorAll(
        "section#pricing-panels > div > div > div > a"
      );
      cardLinks.forEach(link => {
        // @ts-ignore
        const linkArr = link.href.split("/");
        const slug = linkArr[linkArr.length - 1];
        if (slug !== "contact-sales") {
          link.addEventListener("click", () => {
            document.querySelector(slug).open = true;
          });
        }
      });
    }
  }, []);
  return (
    <Styles.Summary>
      {sumdetData &&
        sumdetData.map(data => {
          return (
            <details key={data.key} id={data.key}>
              <summary>
                <h5>{data.summary}</h5>
              </summary>
              <Markdown>{data.detail}</Markdown>
            </details>
          );
        })}
    </Styles.Summary>
  );
};

const PlainText = props => {
  const { slug, title, html, body, backgroundColor } = props;
  return (
    <Styles.PlainText
      id={slug}
      $backgroundColor={backgroundColor}
      className="plain-text-block"
    >
      <Container>
        {title && <h2>{title}</h2>}
        {body && html && (
          // @ts-ignore
          <div dangerouslySetInnerHTML={{ __html: body }} innerHTML={body} />
        )}
        {body && !html && (
          <Markdown
            options={{
              overrides: {
                SummaryDetails: {
                  component: SummaryDetails,
                  props,
                },
                AgencyProgramTiers: {
                  component: AgencyProgramTiers,
                },
                SupportPackageComparison: {
                  component: SupportPackageComparison,
                  props,
                },
              },
            }}
          >
            {body}
          </Markdown>
        )}
      </Container>
    </Styles.PlainText>
  );
};

export default PlainText;
