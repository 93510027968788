import Link from "next/link";
import { styled } from "styled-components";

export const TopicCard = styled(Link)`
  background-color: #f0f0f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100% - 120px) / 4);
  padding: 30px 0 20px 0;
  gap: 20px;
  img {
    width: 120px;
    height: 120px;
  }

  p,
  span {
    font-size: 25px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 0 10px;
  }

  @media only screen and (max-width: 1280px) {
    width: calc((100vw - 150px) / 4);
    padding: 25px 0 15px 0;
    gap: 15px;
    img {
      width: 80px;
      height: 80px;
    }

    /* TODO: Can probably remove p for cleanup */
    p,
    span {
      font-size: 18px;
      line-height: 19px;
      letter-spacing: 1.15px;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 131px;
    padding: 20px 0 10px 0;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
    }

    /* TODO: Can probably remove p for cleanup */
    p,
    span {
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 1px;
    }
  }
`;
