import styled from "styled-components";

// Style for 1 Model of Ribbon Styles.

export const Ribbon = styled("div")<{
  $withHeroBanner?: string;
  $background?: string;
}>`
  /************************************** Model A **********************************************/
  /*********************************************************************************************/
  /* Effect for parallax */
  margin-top: ${({ $withHeroBanner }) => ($withHeroBanner ? "-174px" : "0px")};
  z-index: 1;
  position: relative;
  /* end */
  #model-A {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: ${({ $background }) => $background || "#000"};
    /* Description */
    .description {
      max-width: 722px;
      margin-right: auto;
      color: #fff;
      margin-left: 40px;
      h2 {
        margin-bottom: 10px;
      }
    }
    /* Button */
    .button-outline {
      padding: 10px 40px;
      min-width: 200px;
      text-align: center;
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    margin-top: 0px;
    #model-A {
      flex-direction: column;
      .description {
        display: none;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-A {
    }
  }
`;
