import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/src/components/ui/tabs";
import Markdown from "markdown-to-jsx";

interface ITabsComponentProps {
    header: string;
    tabs: {
        fields: {
            tabTitle: string;
            tabContent: string;
        }
    }[];
}

const TabComponent = ({ header, tabs }: ITabsComponentProps) => {
    return (
      <section className="max-w-full md:max-w-5xl xl:max-w-[1280px] mx-[30px] xl:mx-auto py-16">
        {header && <h3 className="pb-8">{header}</h3>}
        <Tabs defaultValue={tabs[0].fields.tabTitle.toLowerCase()} className="">
          <TabsList>
            {tabs.map((tab, i) => {
              return (
                <TabsTrigger
                  className="active:bg-[#ccc]"
                  key={i}
                  value={tab.fields.tabTitle.toLowerCase()}
                >
                  {tab.fields.tabTitle}
                </TabsTrigger>
              );
            })}
          </TabsList>
          {tabs.map((tab, i) => (
            <TabsContent key={i} value={tabs[i].fields.tabTitle.toLowerCase()}>
              <Markdown>{tab.fields.tabContent}</Markdown>
            </TabsContent>
          ))}
        </Tabs>
      </section>
    );
};

export default TabComponent
