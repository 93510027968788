import React from "react";
import CustomersBlockSection from "./CustomersBlockStyles";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import Link from "next/link";
import { ContentCTA } from "../ContentCTA/ContentCTA";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import CTA from "../Topics/CTA";

// Function Column
const parseColumns = numberOfItems => {
  switch (numberOfItems) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 3:
      return 4;
    default:
      return 12;
  }
};

function CustomersBlock({
  header,
  backgroundColor,
  customerLogos,
  customerCards,
  preContentCtaCopy,
  contentCta,
  logoPosition,
  lang,
}) {
  return (
    <CustomersBlockSection
      $backgroundColor={backgroundColor}
      $logoPosition={logoPosition}
    >
      <ContainerStyle>
        {/* Headline */}
        <RowStyle>
          <ColStyle lg={12} md={12} sm={12}>
            {header && (
              <h4>
                <Markdown>{header}</Markdown>
              </h4>
            )}
          </ColStyle>
        </RowStyle>
        {/* Logos */}
        <RowStyle>
          <ColStyle lg={12} md={12} sm={12}>
            {customerLogos && (
              <div className="customer-logos">
                {customerLogos.map((customer, i) => {
                  // People need to add the logoOverride in Contentful or the logo won't show
                  if (customer.fields.logoOverride) {
                    const { logoOverride, story, name, node_locale } =
                      customer.fields;
                    // If there's a story link to the story,
                    // else to the customer page
                    // remove that condition /${node_locale.slice(0, 2)}/customers/
                    const to = story?.slug
                      ? `/${node_locale.slice(0, 2)}/customers/${story?.slug}/`
                      : ``;

                    return (
                      <React.Fragment key={i}>
                        {to ? (
                          <Link prefetch href={to}>
                            <ContentfulImage
                              alt={logoOverride.fields.name || ""}
                              width={
                                logoOverride.fields.file?.details.image.width ||
                                1000
                              }
                              height={
                                logoOverride.fields.file?.details.image
                                  .height || 1000
                              }
                              quality={100}
                              priority={true}
                              src={logoOverride.fields.file?.url}
                            />
                          </Link>
                        ) : (
                          <div key={name}>
                            <ContentfulImage
                              alt={logoOverride.fields.name || ""}
                              width={
                                logoOverride.fields.file?.details.image.width ||
                                1000
                              }
                              height={
                                logoOverride.fields.file?.details.image
                                  .height || 1000
                              }
                              quality={100}
                              priority={true}
                              src={logoOverride.fields.file?.url}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
              </div>
            )}
          </ColStyle>
        </RowStyle>
        {/* Customer cards */}
        <RowStyle style={{ justifyContent: "center" }}>
          {customerCards && (
            <>
              {customerCards.map((card, i) => {
                const {
                  image,
                  body,
                  quoteName,
                  quoteTitle,
                  contentCta,
                  bottomLogo,
                  contentful_id,
                } = card.fields;
                const isQuote = quoteName && quoteTitle;
                return (
                  <ColStyle
                    lg={parseColumns(customerCards.length)}
                    md={
                      customerCards.length >= 2
                        ? 6
                        : parseColumns(customerCards.length)
                    }
                    sm={12}
                    key={i}
                    className={customerCards.length >= 2 ? "col-style" : ""}
                  >
                    <div
                      className={
                        customerCards.length >= 2
                          ? "customer-cards-Model-b"
                          : "customer-cards"
                      }
                    >
                      {/* Image */}
                      <div className="wrapper-image">
                        <ContentfulImage
                          className="card-image"
                          alt={image.fields.name || ""}
                          width={image.fields.file?.details.image.width || 1000}
                          height={
                            image.fields.file?.details.image.height || 1000
                          }
                          quality={100}
                          priority={true}
                          src={image.fields.file?.url}
                        />
                      </div>
                      {/* Text, Quote, CTA, Logo */}
                      <div className="card-text">
                        <Markdown className="body">{body}</Markdown>
                        {isQuote && (
                          <div className="quote">
                            <div className="title">{quoteName}</div>
                            {quoteTitle && (
                              <div className="subtitle">
                                <Markdown>{quoteTitle}</Markdown>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="cta-logo-wrapper">
                          {bottomLogo && (
                            <ContentfulImage
                              alt={bottomLogo.fields.name || ""}
                              width={
                                bottomLogo.fields.file?.details.image.width ||
                                1000
                              }
                              height={
                                bottomLogo.fields.file?.details.image.height ||
                                1000
                              }
                              quality={100}
                              priority={true}
                              src={bottomLogo.fields.file?.url}
                            />
                          )}
                          {contentCta && (
                            <div>
                              <ContentCTA
                                className="card-cta"
                                contentCta={{
                                  ...contentCta,
                                  lang,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ColStyle>
                );
              })}
            </>
          )}
        </RowStyle>
        {/* Pre-Content CTA text  */}

        {preContentCtaCopy || contentCta ? (
          <RowStyle>
            <ColStyle lg={12} md={12} sm={12}>
              <div className="wrapper-pre-content">
                {preContentCtaCopy && (
                  <h5>
                    <Markdown>{preContentCtaCopy}</Markdown>
                  </h5>
                )}
                {contentCta && (
                  <div className="cta-content">
                    <CTA
                      cta={{
                        fields: {
                          internalName: contentCta.fields.title,
                          link: {
                            fields: {
                              internalName: "customers",
                              url: "customers",
                            },
                          },
                          title: contentCta.fields.ctaText,
                        },
                      }}
                      ctaLength={1}
                      lang={lang}
                      key={1}
                      index={0}
                      type="buttonLink"
                    />
                  </div>
                )}
              </div>
            </ColStyle>
          </RowStyle>
        ) : null}
      </ContainerStyle>
    </CustomersBlockSection>
  );
}

export default CustomersBlock;
