import {
  defaultStyles,
  gradientMap,
  headerFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleHeadline = styled("section")<{
  $backgroundColor?: "Black" | "White";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $headlineStyle?:
    | "Regular"
    | "Gradient 1"
    | "Gradient 2"
    | "Gradient 3"
    | "Gradient 4";
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "column",
      gap: "10px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })}
  align-items: center;

  .content-tag {
    text-align: center;
    font-size: 33px;
    line-height: 40px;
    font-weight: ${({ $headlineStyle }) =>
      $headlineStyle === "Regular" || !$headlineStyle ? 400 : 700};
    letter-spacing: 1.65px;
  }

  .gradient-header {
    text-align: center;
    ${headerFontStyles()}
    ${({ $headlineStyle }) =>
      $headlineStyle !== "Regular" && !!$headlineStyle
        ? `background-color: #f3ec78;
		background-image: ${`linear-gradient(
			to right,
			${gradientMap[Number($headlineStyle.split(" ")[1]) - 1]}
		)`};
		background-size: 100%;
		-webkit-background-clip: text;
		-moz-background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;`
        : null};
  }

  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "8px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}

    .content-tag {
      text-align: center;
      font-size: 20px;
      line-height: 26px;
    }

    .gradient-header {
      ${headerFontStyles("Tablet")}
    }
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "4px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Mobile",
      })}

    .content-tag {
      text-align: center;
      font-size: 20px;
      line-height: 26px;
    }

    .gradient-header {
      ${headerFontStyles("Mobile")}
    }
  }
`;
