import styled from "styled-components";

export const CardGridSection = styled("section")<{
  $backgroundColor: string;
  $cardStyle?: string;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  padding-bottom: 60px;

  .card-grid-container {
    @media only screen and (max-width: 1280px) {
      width: 100%;
    }
  }
  > div > div {
    column-gap: 40px;
    row-gap: 30px;

    @media only screen and (max-width: 1280px) {
      column-gap: 30px;
      padding: 0 30px;
      flex-wrap: wrap;
    }
  }

  > div > div > div {
    padding: 0 !important;
    width: ${({ $cardStyle }) =>
      $cardStyle === "Image-left"
        ? "calc((1280px - 160px)/2)"
        : "calc((100% - 80px) / 3)"};
    flex: 0 0
      ${({ $cardStyle }) =>
        $cardStyle === "Image-left"
          ? "calc((1280px - 160px)/2)"
          : "calc((100% - 80px) / 3)"};
    max-width: ${({ $cardStyle }) =>
      $cardStyle === "Image-left"
        ? "calc((1280px - 160px)/2)"
        : "calc((100% - 80px) / 3)"};
    @media only screen and (max-width: 1280px) {
      width: ${({ $cardStyle }) =>
        $cardStyle === "Image-left"
          ? "calc((100% - 30px)/2)"
          : "calc((100% - 60px) / 3)"};
      flex: 0 0
        ${({ $cardStyle }) =>
          $cardStyle === "Image-left"
            ? "calc((100% - 30px)/2)"
            : "calc((100% - 60px) / 3)"};
      max-width: ${({ $cardStyle }) =>
        $cardStyle === "Image-left"
          ? "calc((100% - 30px)/2)"
          : "calc((100% - 60px) / 3)"};
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .verbose-card-grid {
    width: 100%;
    flex: 0 0 100% !important;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  // Headline
  h4 {
    text-align: center;
    font-family: "articulat-cf";
    letter-spacing: 0px;
    font-size: 30px;
    font-weight: bold;
    padding: 60px 15%;
    p {
      text-align: center;
    }
  }
  .title-business {
    font-size: 53px;
  }
  /* Tablet and Mobile: max-width: 1280px */
  @media only screen and (max-width: 1280px) {
    .title-business {
      font-size: 32px;
    }
  }
  /* Tablet and Mobile: max-width: 769px */
  @media only screen and (max-width: 769px) {
    .verbose-card-grid {
      margin: 20px;
    }
  }
`;
