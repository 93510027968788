import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const BlogPostPreviewCardWrapper = styled("div")<{
  $textColor?: string;
}>`
  margin: 0 0 100px 0;
  a {
    cursor: pointer;
  }

  img {
    width: 100%;
    border-radius: 5px;
    height: 166px;
    object-fit: cover;
  }
  span {
    margin-top: 0px !important;
  }
  span {
    display: block;
    font-family: var(--primary-font-family), sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: ${({ $textColor }) => $textColor || "#000"};
    text-transform: uppercase;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 14px;
    display: inline-block;

    @media only screen and (${BREAK_POINTS.TABLET}) {
      margin-top: 24px;
    }
  }

  a .icon-arrow {
    background-position: -10px 0;
    margin-right: 0;
  }

  a .icon-arrow,
  a .icon-arrowLeft {
    transition: background-position 0.3s ease;
    width: 34px;
  }

  a:hover .icon-arrow {
    background-position: -3px 0;
    margin-right: 0;
    width: 34px;
  }

  h4 {
    font-family: var(--primary-font-family), sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    color: ${({ $textColor }) => $textColor || "#000"};
    margin: 0;
    margin-bottom: 20px;
    text-decoration: none;

    @media only screen and (${BREAK_POINTS.TABLET}) {
      margin-bottom: 15px;
    }
  }

  p {
    font-family: "articulat-cf", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${({ $textColor }) => $textColor || "#000"};
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (${BREAK_POINTS.TABLET}) {
      margin-bottom: 30px;
    }
  }
  .topic-tag {
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 5px 12px;
    font-family: "articulat-cf", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin: 0 4px 4px 0;
    text-transform: uppercase;
  }
  .author-date {
    margin-left: 7px;
    font-family: "articulat-cf", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    color: #000000;
  }
`;

export const ImgContainer = styled.div`
  width: 100% !important;
  height: 166px !important;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;

  .img-inner-container {
    max-width: 600px;
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    object-fit: cover;
  }
`;
