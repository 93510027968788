import React from "react";
import * as Styles from "./styles";
import Link from "next/link";

interface IBreadcrumbsProps {
  containerSize?: "100%" | "1280px" | "1440px";
  linkList: string[][];
  translations: { [key: string]: string };
  lang: string;
}

const Breadcrumbs = ({
  containerSize,
  linkList,
  lang,
  translations,
}: IBreadcrumbsProps) => {
  return (
    // Apply container size styling from props
    <Styles.Breadcrumbs $containerSize={containerSize}>
      <Link prefetch href={`/${lang}/`}>
        {translations["Home"]}
      </Link>

      {/* Map over linkList to create breadcrumbs */}
      {linkList.map((link, i) => {
        // Determine if the current item is the last in the list
        const isLast = i === linkList.length - 1;

        // If link has two items, it includes a href and a label
        const content =
          link.length === 2 ? (
            <Link prefetch href={`/${lang}/${link[0]}`}>
              {link[1]}
            </Link>
          ) : (
            // If link has one item, it's just a label without href
            <span>{link[0]}</span>
          );

        return (
          // Each fragment needs a unique key
          <React.Fragment key={i}>
            <span className="breadcrumbs-chevron">{">"}</span>
            {isLast ? (
              // If it's the last item, make it bold
              <span className="breadcrumbs-title">{content}</span>
            ) : (
              // Otherwise, render the content normally
              content
            )}
          </React.Fragment>
        );
      })}
    </Styles.Breadcrumbs>
  );
};

export default Breadcrumbs;
