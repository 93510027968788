import styled from "styled-components";

// Style for JobCard

export const JobCard = styled.div`
  /************************************** Job Card **********************************************/
  /***************************************************************************************************/
  a {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 0px;
    height: 145px;
    animation: zoom-in-zoom-out 1s ease;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(0.5, 0.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    /* Hover */
    &:hover {
      transform: scale(1.1);
    }
  }
  .color-bar {
    width: 5px;
    background: linear-gradient(to top, #4ea9d1, #aa6c7e, #f76531, #efd900);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 20px 15px 15px 20px;
    .title {
      font-weight: 600;
      font-family: "articulat-cf";
      margin-bottom: 5px;
    }
    p {
      font-family: "articulat-cf";
    }
    .bottom {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
  }
  .content .upper {
    justify-content: normal;
  }
`;
