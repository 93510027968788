import React from "react";

import * as Styles from "./BackgroundPlayerStyles";

const BackgroundPlayer = ({ videoData, source }) => {
  let mp4;
  if (Object.keys(videoData).length > 0) {
    videoData.sources.map(source => {
      if (
        source.container === "MP4" &&
        source.width >= 1280 &&
        source.src.indexOf("https") === 0
      ) {
        mp4 = source.src;
      }
    });
  }

  if (source) {
    mp4 = source;
  }

  return mp4 ? (
    <Styles.BackgroundPlayer>
      <video muted autoPlay loop preload="auto">
        {/* @ts-ignore */}
        <source src={mp4} type="video/mp4" muted />
      </video>
    </Styles.BackgroundPlayer>
  ) : null;
};

export default BackgroundPlayer;
