import {
  definePadding,
  defaultStyles,
  headerFontStyles,
  smallHeaderFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleHero = styled("section")<{
  $backgroundColor?: "Black" | "White" | "Grey";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $isProduct: boolean;
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "row",
      gap: "40px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })}

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .eyebrow {
      ${({ $isProduct }) => {
        return $isProduct
          ? `font-family: Helvetica;
					   font-size: 39px;
					   line-height: 40px;`
          : `font-size: 27px;
						line-height: 30px;
						font-weight: 600;`;
      }}
      sup {
        font-size: 6px;
        vertical-align: top;
        top: 0;
      }
    }

    h1 {
      font-family: "Helvetica";
      font-size: 39px;
      font-weight: 300;
      line-height: 40px;
      sup {
        font-size: 6px;
        vertical-align: top;
        top: 0;
      }
    }
    .logo {
      width: 11rem;
    }

    .headline-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h1 {
        ${headerFontStyles()}
        width: 90%;
      }

      p,
      h2 {
        ${smallHeaderFontStyles()}
        font-weight: 400;
      }
    }

    .ctas-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;
    }
  }

  .media-section {
    img {
      max-width: calc((1280px - 40px) / 2);
    }
    .skeleton, .brightcove-react-player-loader > video-js {
      width: calc((1280px - 40px) / 2);
      height: calc(((1280px - 40px) / 2) / (16 / 9));
    }
    .skeleton {
      max-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "40px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
    .text-section {
      width: calc((100% - 40px) / 2);
      gap: 20px;
      .logo {
        width: 8rem;
      }

      .eyebrow {
        font-size: 20px;
        line-height: 22px;
      }

      .headline-content-wrapper {
        h1 {
          ${headerFontStyles("Tablet")}
        }

        p,
        h2 {
          ${smallHeaderFontStyles("Tablet")}
        }
      }

      .ctas-wrapper {
        gap: 10px;
      }
    }
    .media-section {
      max-width: calc((100% - 40px) / 2);
      img {
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        direction: "column",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Mobile",
      })}
    .text-section {
      width: 100%;
      gap: 10px;
      .logo {
        width: 6rem;
      }

      .eyebrow {
        font-size: 16px;
        line-height: 18px;
      }

      .headline-content-wrapper {
        gap: 5px;
        h1 {
          ${headerFontStyles("Mobile")}
        }

        p,
        h2 {
          ${smallHeaderFontStyles("Mobile")}
        }
      }

      .ctas-wrapper {
        gap: 10px;
      }
    }

    .media-section {
      display: none;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 900px) {
    .media-section {
      .brightcove-react-player-loader > video-js {
        width: calc((1280px - 42em) / 2);
        height: calc(((1280px - 42em) / 2) / (16 / 9));
      }
    }
  }

  @media only screen and (min-width: 901px) and (max-width: 1000px) {
    .media-section {
      .brightcove-react-player-loader > video-js {
        width: calc((1280px - 35em) / 2);
        height: calc(((1280px - 35em) / 2) / (16 / 9));
      }
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .media-section {
      .brightcove-react-player-loader > video-js {
        width: calc((1280px - 30em) / 2);
        height: calc(((1280px - 30em) / 2) / (16 / 9));
      }
    }
  }

  @media only screen and (min-width: 1101px) and (max-width: 1200px) {
    .media-section {
      .brightcove-react-player-loader > video-js {
        width: calc((1280px - 25em) / 2);
        height: calc(((1280px - 25em) / 2) / (16 / 9));
      }
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1279px) {
    .media-section {
      .brightcove-react-player-loader > video-js {
        width: calc((1280px - 20em) / 2);
        height: calc(((1280px - 20em) / 2) / (16 / 9));
      }
    }
  }
`;
