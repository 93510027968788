import styled from "styled-components";

export const MainStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 1280px) {
    align-items: flex-start;
    width: 220px;
  }

  .social-wrapper {
    background: #919090;
    padding: 30px;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto;
    strong {
      font-size: 1.2rem;
    }
    .social-container {
      display: flex;
      justify-content: space-between;

      a {
        span {
          margin: 0 auto;
        }
      }
    }
  }
`;
