import { defaultStyles } from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleLinkSection = styled("section")<{
  $backgroundColor?: "Black" | "White" | "Grey";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $linkCount: number;
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "column",
      gap: "50px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })}
  align-items: center;

  .title {
    font-size: 33px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 1.65px;
    text-align: center;
  }

  .link-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 50px;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    .link {
      display: flex;
      justify-content: center;
      width: calc(100% / 4);
    }
  }
  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "30px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })};
    .title {
      font-size: 29px;
      line-height: 41px;
      letter-spacing: 1.55px;
    }

    .link-list {
      row-gap: 30px;
      .link {
        width: calc(100% / 4);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "10px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Mobile",
      })}
    .title {
      font-size: 25px;
      line-height: 37px;
      letter-spacing: 1.45px;
    }

    .link-list {
      row-gap: 10px;
      .link {
        width: calc(100% / 2);
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .link-list {
      .link {
        width: 100%;
      }
    }
  }
`;
