import styled from "styled-components";

export const ResourceContainer = styled("div")<{
  $backgroundColor?: string;
  $textColor?: string;
}>`
  /************************************** Container Card **********************************************/
  /***************************************************************************************************/
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  /* Wave */
  .wave {
    margin-bottom: -230px;
  }
  .header {
    width: 100%;
    position: relative;
    text-align: center;
    font-family: "articulat-cf";
    color: ${({ $textColor }) => $textColor || "#000"};
    letter-spacing: 0px;
    font-size: 30px;
    font-weight: bold;
    padding: 10px 15%;
  }
  .result {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    text-transform: capitalize;
    position: relative;
    animation: fadeIn 1s alternate;
    font-weight: 700;
    font-size: 18px;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
    }
  }

  .value-result {
    font-size: 20px;
    color: #000;
    text-transform: capitalize;
    font-weight: 300;
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .result {
      flex-direction: column;
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .wave {
      margin-bottom: -50px;
    }
  }
`;
