import styled from "styled-components";

export const ResourceCard = styled("div")<{
  $backgroundColor?: string;
  $textColor?: string;
}>`
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding: 20px;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.99);
  }

  .link-wrapper {
    color: inherit;
    text-decoration: none;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .cta-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .cta-text-wrapper {
      margin-bottom: -9px;
      font-weight: 700;
    }
  }
  /************************************** Resource Card **********************************************/
  /***************************************************************************************************/
  .wrapper-card {
    background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    position: relative;
    animation: zoom-in-zoom-out 1s ease;
    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(0.5, 0.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    /* PlayTv */
    .playTv {
      position: absolute;
      width: 100px;
      right: 7px;
      top: 10px;
      z-index: 1;
    }

    /* Image */
    .resourceImage {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    /* Text Body CTA */
    .wrapper-text {
      padding: 20px 20px 0px 20px;
      .type,
      .date {
        color: #000;
        margin-bottom: 10px;
      }
      .header-card {
        font-family: "articulat-cf";
        font-size: 20px;
        margin-bottom: 15px;
        color: ${({ $textColor }) => $textColor || "#000"};
      }
      .body {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;
      }
    }

    .wrapper-tag {
      margin-top: auto;
      padding: 20px;
      .tag {
        color: #000;
        border: 1px solid #000;
        background-color: transparent;
        border-radius: 3px;
        margin: 8px 3px 0px 3px;
        padding: 5px;
        display: inline-block;
        font-size: 10px;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.65s;
        z-index: 1;
        position: relative;
        &:hover {
          border: 1px solid #000;
          color: #fff;
          background-color: #000;
        }
      }
    }

    .tag-wrapper {
      height: 60px;
      @media only screen and (max-width: 768px) {
        height: 100%;
      }
    }
    .text-wrapper {
      font-weight: bold;
    }
  }

  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
  }
`;
