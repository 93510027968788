import styled from "styled-components";

export const MainStyles = styled("div")<{
  $headerTextColor?: string;
  $width?: number;
  $backgroundColor?: string;
  $aspectRatio?: any;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Parent div  */
  .parent-wrapper {
    max-width: 1280px;
    padding: 30px;
    width: 100%;
  }
  h2 {
    font-family: "Agrandir";
    font-size: 30px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
    ${({ $headerTextColor }) => {
      if ($headerTextColor === "main-linear-color") {
        return `
          background: linear-gradient(to right, #A96c7E, #F86430);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `;
      } else {
        return $headerTextColor;
      }
    }}
  }
  > p {
    font-family: "articulat-cf", sans-serif;
    max-width: 1280px;
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 20px;
    @media screen and (max-width: 1280px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .relative-container-iframe {
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: ${({ $width }) => `${$width}px`};
    padding-top: ${({ $aspectRatio, $width }) =>
      `calc(${$width}px / (${$aspectRatio.split(":")[0]}/${$aspectRatio.split(":")[1]}))`};
    @media screen and (max-width: ${({ $width }) =>
        $width ? `${$width + 100}px` : "undefined"}) {
      width: 100%;
      padding-top: ${({ $aspectRatio, $width }) =>
        `calc(100vw / (${$aspectRatio.split(":")[0]}/${$aspectRatio.split(":")[1]}))`};
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: ${({ $aspectRatio, $width }) =>
        `calc(${$aspectRatio === "1:1" ? `${$width}px` : "100vw"} / (${$aspectRatio.split(":")[0]}/${$aspectRatio.split(":")[1]}))`};
    }
  }
  .iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
