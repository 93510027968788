import React from "react";

import * as Styles from "./styles";
import Icon from "@/src/components/Global_Components/Icon/Icon";

const SocialShareWidget = () => {
  // Array of objects for list of icons
  const iconMap = [
    { link: "https://www.facebook.com/Brightcove/", type: "facebook" },
    { link: "https://www.linkedin.com/company/brightcove/", type: "linkedin" },
    { link: "https://twitter.com/Brightcove", type: "twitter" },
    { link: "https://www.instagram.com/brightcove", type: "instagram" },
  ];
  return (
    <Styles.MainStyles>
      <div className="social-wrapper">
        <strong>Follow Brightcove</strong>
        <div className="social-container">
          {iconMap.map((icon, i) => (
            <a key={i} href={icon.link} target="_blank" rel="noreferrer">
              <Icon iconImage={icon.type} iconColor={"#000"} className={""} />
            </a>
          ))}
        </div>
      </div>
    </Styles.MainStyles>
  );
};

export default SocialShareWidget;
