import styled from "styled-components";

export const CenteredTextBlock = styled("section")`
  .headline {
    text-align: center;
    font-family: "articulat-cf";
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 35px;
    line-height: 1.2;
    color: #000000;
    margin: 40px 20% 0px 20%;
  }
  .sub-headline {
    text-align: center;
    font-family: "articulat-cf";
    font-weight: 500;
    letter-spacing: 0px;
    color: #000000;
    font-size: 24px;
    line-height: 1.25;
    margin: 20px 20% 40px;
  }
  .title-business {
    font-size: 43px;
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .sub-headline {
      margin: 40px 10%;
    }
    .headline {
      margin: 40px 10% 0px 10%;
    }
  }

  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    .sub-headline {
      margin: 40px 0%;
    }
    .headline {
      margin: 40px 0% 0px 0%;
    }
    .title-business {
      font-size: 32px;
    }
  }
`;
