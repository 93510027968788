import React from "react";

import * as Styles from "./JobCardStyles";
import Icon from "@/src/components/Global_Components/Icon/Icon";

export const JobCard = props => {
  const departements = props.card.departments[0]?.name || null;
  const offices = props.card.offices[0]?.name || null;
  const title = props.card.title;
  const absolute_url = props.card.absolute_url;
  return (
    <Styles.JobCard>
      <a href={absolute_url} target="_blank">
        <div className="color-bar"></div>
        <div>
          <div className="content">
            <div className="upper">
              <p className="title">{title}</p>
              {departements && <p>{departements}</p>}
            </div>

            <div className="bottom">
              <Icon
                className={""}
                iconImage="locationPin"
                iconColor={"black"}
              />
              {offices && <p className="offices">{offices}</p>}
            </div>
          </div>
        </div>
      </a>
    </Styles.JobCard>
  );
};
