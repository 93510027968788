import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const ResourcesBlockWrapper = styled("section")<{
  $backgroundColor?: string;
  $textColor?: string;
}>`
  content-visibility: auto;
  contain-intrinsic-size: 1280px 600px;
  background: ${({ $backgroundColor }) => $backgroundColor || "#FFF"};
  padding: 60px 0;
  color: ${({ $textColor }) => $textColor || "#000"};
  h3,
  h4 {
    font-size: 3.5rem;
    margin: 20px auto 40px;
    display: block;
    text-align: center;
    font-weight: 700;
    color: ${({ $textColor }) => $textColor || "#000"};
  }
  h5,
  span {
    color: ${({ $textColor }) => $textColor || "#000"};
  }
  &.featured-content {
    padding-top: 75px;
    padding-bottom: 0;
    .header-wrapper {
      text-align: center;
      h4 {
        font-size: 2.4rem;
        max-width: 780px;
        margin-top: 0;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
      }
      strong {
        display: block;
        margin-bottom: 20px;
        font-size: 1.5rem;
      }
      strong > p {
        text-align: center;
      }
    }
    .bottom-wrapper {
      text-align: center;
      padding: 20px;
      padding-bottom: 10px;
      max-height: 125px;
      @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
        max-height: unset;
        svg {
          height: 32% !important;
        }
      }
      p {
        margin-top: 15px;
        text-align: center;
      }
      svg {
        height: 40%;
        margin-top: 10px;
      }
    }
  }
  &.square-color-blocks {
    padding-bottom: 0;
    .header-wrapper {
      h4 {
        margin-bottom: 0;
      }
      margin-bottom: 20px;
    }
    .bottom-wrapper {
      margin: 20px 0;
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    padding: 60px 0;

    .image-block-list {
      max-width: 100% !important;
      width: 100% !important;
    }

    .related-cards-list {
      padding: 0 10px !important;
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    h3,
    h4 {
      font-size: 2.5rem;
    }

    > div > div > div:nth-child(4) {
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    contain-intrinsic-size: 400px 1200px;
    h3,
    h4 {
      font-size: 2rem;
    }
  }
`;
