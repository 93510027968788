import React from "react";
import * as Styles from "./styles";
import JumpLinkTopic, { INavItem } from "../../Topics/JumpLink";

interface IJumpNavigationProps {
  internalName: string;
  navigationItems?: INavItem[];
  backgroundColor?: "Black" | "White";
  paddingTop?: "Medium" | "Small";
  paddingBottom?: "Medium" | "Small";
}

const JumpNavigationModule = (props: IJumpNavigationProps) => {
  const { backgroundColor, navigationItems, paddingBottom, paddingTop } = props;
  return (
    <Styles.ModuleJumpNavigation
      $backgroundColor={backgroundColor}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
    >
      {navigationItems &&
        navigationItems.map((navItem, i) => (
          <JumpLinkTopic
            navItem={navItem}
            index={i}
            key={i}
            backgroundColor={backgroundColor}
          />
        ))}
    </Styles.ModuleJumpNavigation>
  );
};

export default JumpNavigationModule;
