import React from "react";

import * as Styles from "./SpaceStyles";

interface ISpaceProps {
  height?: string;
  backgroundColor?: string;
}

// Component to define space in between components
const Space = (spaceProps: ISpaceProps) => {
  const { height, backgroundColor } = spaceProps;
  return <Styles.Space $height={height} $backgroundColor={backgroundColor} />;
};

export default Space;
