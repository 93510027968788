import styled from "styled-components";

export const FullWidthVideoBlock = styled("div")`
  /************************************** Model A **********************************************/
  /*********************************************************************************************/
  #Model-A {
    /* Video Wrapper */
    .video-wrapper {
      position: relative;
      max-width: 100%;
      width: 1112px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      height: 625px;
      background-color: #000;
      .video-js {
        width: 100% !important;
        height: 100% !important;
        padding-top: 44% !important;
      }
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #Model-A {
      .video-wrapper {
        aspect-ratio: 16 / 9;
        height: auto;
      }
      .video-js {
        width: 100% !important;
        height: 0px !important;
        padding-top: 56.25% !important;
      }
    }
  }
  /************************************** Model B **********************************************/
  /*********************************************************************************************/
  /* Only for parallax effect  */
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  /* end */

  #Model-B {
    position: relative;
    height: 100%;
    /* Video Wrapper */
    .video-wrapper {
      position: relative;
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      height: 925px;
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;

        /* Video */
        .video-js[tabindex="-1"] {
          object-fit: cover;
          display: contents;
          object-position: top;
        }
        .video-js .vjs-tech {
          object-fit: cover;
          object-position: top;
        }
        /* Poster */
        .vjs-poster {
          object-fit: cover;
          display: contents;
          object-position: center;
        }
        .vjs-control-bar {
          display: none;
        }
      }
    }
    /* Text */
    .container-text {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      width: 100%;
      /* Logo */
      .logo {
        width: 800px;
        height: auto;
        margin-left: -5px;
        display: block;
        margin-bottom: 20px;
      }
      .wrapper-text {
        text-align: left;
        margin-left: 7%;
        padding: 10px;
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translate(0%, -50%);
        color: white;
        width: 49.95%;
        flex: 0 0 49.95%;
        max-width: 49.95%;
        .eyebrow,
        h1 {
          font-size: 20px;
          font-family: "articulat-cf";
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .header,
        h2 {
          font-size: 60px;
          font-family: "articulat-cf";
          font-weight: 800;
          text-transform: uppercase;
          padding: 5px 0px;
          letter-spacing: 0px;
          line-height: 1;
        }
        .body {
          font-family: "articulat-cf";
          font-size: 18px;
          letter-spacing: 0px;
          font-weight: 500;
          padding: 5px 0px 30px 0px;
        }
        /* Button For Video */
        .wrapper-button {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          a {
            margin-right: 10px;
            font-size: 1.2rem;
            font-family: "articulat-cf";
            font-weight: 400;
            margin-bottom: 10px;
            text-align: left;
          }
          /* Video Link */
          #hero-cta {
            width: 170px;
            height: 60px;
            opacity: 1;
            a {
              height: 100%;
              justify-content: left !important;
              align-content: center;
              align-items: center;
            }
            .icon-play {
              top: 0px;
            }
            p {
              font-size: 1.2rem;
              font-family: "articulat-cf" !important;
              font-weight: 400;
              margin-bottom: 0px;
              text-align: left;
            }
            p:after {
              height: 0px;
            }
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #Model-B {
      .video-wrapper {
        aspect-ratio: 16 / 9;
        height: 925px;
      }
      .container-text {
        .logo {
          width: 480px;
        }
        .wrapper-text {
          width: 80%;
          flex: 0 0 80%;
          max-width: 80%;
          margin-left: 5%;
          .header,
          h2 {
            font-size: 35px;
          }
        }
      }
    }
  }
  /************************************** Model C **********************************************/
  /*********************************************************************************************/
  #Model-C {
    position: relative;
    height: 100%;
    /* Video Wrapper */
    .video-wrapper {
      position: relative;
      max-width: 100%;
      width: 100%;
      height: 925px;
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;

        /* Video */
        .video-js[tabindex="-1"] {
          width: 100%;
          height: 100%;
          display: flex;
        }
        .video-js .vjs-tech {
          object-fit: cover;
          object-position: top;
        }
        /* Poster */
        .vjs-poster {
          object-fit: cover;
          display: contents;
          object-position: center;
        }
      }
    }
  }
  /* Big Tablet:  max-width: 1440px */
  @media only screen and (max-width: 1440px) {
    #Model-C {
      .video-wrapper {
        aspect-ratio: 16 / 9;
        height: auto;
      }
    }
  }
  /************************************** Model D **********************************************/
  /*********************************************************************************************/
  .poster-image {
    position: absolute;
    z-index: 0;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  #Model-D {
    /* Keep has reference */
    .brightcove-react-player-loader {
      .video-js .vjs-tech {
        position: unset;
      }
      .video-js {
        width: 100%;
        height: 100%;
        padding-top: 0 !important;
      }
      .vjs-control-bar {
        display: none;
      }
    }
    /* Text */
    .container-text {
      display: block;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      width: 100%;
      /* Logo */
      .logo {
        width: 800px;
        height: auto;
        margin-left: -5px;
        display: block;
        margin-bottom: 20px;
      }
      .wrapper-text {
        text-align: left;
        margin-left: 7%;
        padding: 10px;
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translate(0%, -50%);
        color: white;
        width: 49.95%;
        flex: 0 0 49.95%;
        max-width: 49.95%;
        .eyebrow,
        h1 {
          font-size: 20px;
          font-family: "Agrandir";
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .header,
        h2 {
          font-size: 60px;
          font-family: "articulat-cf";
          font-weight: 800;
          text-transform: uppercase;
          padding: 5px 0px;
          letter-spacing: 0px;
          line-height: 1;
        }
        .body {
          font-family: "articulat-cf";
          font-size: 18px;
          letter-spacing: 0px;
          font-weight: 500;
          padding: 5px 0px 30px 0px;
        }
        /* Button For Video */
        .wrapper-button {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          a {
            margin-right: 10px;
            font-size: 1.2rem;
            font-family: "articulat-cf";
            font-weight: 400;
            margin-bottom: 10px;
            text-align: left;
            color: #fff;
          }
          a .icon-arrow {
            color: #fff;
          }
          /* Video Link */
          #hero-cta {
            width: 170px;
            height: 60px;
            opacity: 1;
            a {
              height: 100%;
              justify-content: left !important;
              align-content: center;
              align-items: center;
            }
            .icon-play {
              top: 0px;
            }
            p {
              font-size: 1.2rem;
              font-family: "articulat-cf" !important;
              font-weight: 400;
              margin-bottom: 0px;
              text-align: left;
            }
            p:after {
              height: 0px;
            }
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1440px */
  @media only screen and (max-width: 1440px) {
    #Model-D {
      .container-text {
        .logo {
          width: 480px;
        }
        .wrapper-text {
          width: 80%;
          flex: 0 0 80%;
          max-width: 80%;
          margin-left: 5%;
          .header,
          h2 {
            font-size: 35px;
          }
        }
      }
    }
  }
  /* Medium Tablet:  max-width: 900px */
  @media only screen and (max-width: 900px) {
    #Model-D {
      /* .brightcove-react-player-loader {
        width: 150%;
        margin-left: -25%;
        min-height: 38vw;
        overflow: hidden;
      } */
      .wrapper-text {
        .header,
        .body {
          display: none;
        }
      }
    }
  }
  /*  Mobile:  max-width: 500px */
  @media only screen and (max-width: 500px) {
    #Model-D .brightcove-react-player-loader .video-js .vjs-tech {
      min-height: 250px;
      object-fit: cover;
    }
    #Model-D {
      min-height: 250px;
      .container-text {
        .wrapper-text {
          .wrapper-button {
            a {
              font-size: 16px;
            }
            a .icon-arrow {
              width: 28px;
            }
          }
        }
      }
    }
  }

  /************************************** Model E **********************************************/
  /*********************************************************************************************/
  #Model-E {
    background-color: #fff;
    .video-wrapper {
      position: relative;
      max-width: 100%;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      height: 100%;
      background-color: transparent;
      .video-js {
        aspect-ratio: 16 / 9;
        height: auto;
      }
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
      }
    }
  }
  /************************************** Model F **********************************************/
  /*********************************************************************************************/
  #Model-F {
    /* Video Wrapper */
    height: 725px;
    .video-wrapper {
      background-color: #000;
      .brightcove-react-player-loader {
        .vjs-control-bar {
          display: none;
        }
      }
    }
    /* Text */
    .container-text {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      width: 100%;
      .wrapper-text {
        text-align: left;
        margin-left: 7%;
        padding: 10px;
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translate(0%, -50%);
        color: white;
        width: 60%;
        flex: 0 0 60%;
        max-width: 60%;
        .eyebrow {
          font-size: 75px;
          font-family: "Agrandir";
          text-transform: uppercase;
          margin-bottom: 0px;
        }
        .header > h1 {
          font-size: 75px;
          font-family: "Agrandir";
          text-transform: uppercase;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: White;
          color: transparent;
          font-weight: 700;
          line-height: 1.1;
          span {
            font-family: Agrandir;
            text-transform: uppercase;
          }
        }
        /* Special French and German Homepage header */
        /* French */
        .header-french-homepage > h1 {
          font-size: 55px;
          font-family: "Agrandir";
          text-transform: uppercase;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: White;
          color: transparent;
        }
        /* German */
        .header-german-homepage > h1 {
          font-size: 35px;
          font-family: "Agrandir";
          text-transform: uppercase;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: White;
          color: transparent;
        }
        /* End */
        .body {
          font-family: "articulat-cf", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 1.4;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        /* CTA */
        .wrapper-button {
          a {
            .button-solid {
              background: linear-gradient(
                to right,
                #4ea9d1,
                #aa6c7e,
                #f76531,
                #efd900
              );
              border-radius: 40px;
              border: none;
              padding: 15px 50px;
              &:hover {
                background: transparent
                  linear-gradient(
                    236deg,
                    #efd900 0%,
                    #f76531 32%,
                    #aa6c7e 62%,
                    #4ea9d1 100%
                  )
                  0% 0% no-repeat padding-box;
              }
              .cta-text {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #Model-F {
      height: auto;
      .video-wrapper {
        aspect-ratio: 16 / 9;
        height: auto;
      }
      .container-text {
        .wrapper-text {
          width: 80%;
          flex: 0 0 80%;
          max-width: 80%;
          margin-left: 5%;
          .eyebrow {
            font-size: 35px;
            display: flex;
          }
          .header > h1,
          .header-french-homepage > h1,
          .header-german-homepage > h1 {
            font-size: 35px;
          }
        }
      }
    }
  }
  /* Big Tablet:  max-width: 972px For German home page */
  @media only screen and (max-width: 972px) {
    #Model-F {
      .container-text {
        .wrapper-text {
          .header-german-homepage > h1 {
            font-size: 25px;
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 679px */
  @media only screen and (max-width: 679px) {
    #Model-F {
      .container-text {
        .wrapper-text {
          width: 90%;
          flex: 0 0 90%;
          max-width: 90%;
          .eyebrow {
            font-size: 25px;
          }
          .header h1 {
            font-size: 25px;
            -webkit-text-stroke-width: 1px;
            margin-bottom: 0px;
          }
          .header-french-homepage > h1 {
            font-size: 20px;
            margin-bottom: 0px;
            -webkit-text-stroke-width: 1px;
          }
          .header-german-homepage > h1 {
            font-size: 14px;
            margin-bottom: 0px;
            -webkit-text-stroke-width: 1px;
          }
          .body {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .wrapper-button {
            a {
              .button-solid {
                padding: 8px 30px;
              }
            }
          }
        }
      }
    }
  }
`;
