import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

const PaginationStyle = styled.div`
  .paginationBttns {
    margin: 40px auto;
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #000;
    color: #000;
    cursor: pointer;
  }

  .paginationBttns a:hover {
    color: white;
    background-color: #000;
  }

  .paginationActive a {
    color: white;
    background-color: #000;
  }

  .no-previous-next {
    display: none;
  }

  .paginationDisabled a,
  .paginationDisabled a:hover {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    .paginationBttns {
      overflow: scroll;
      width: 100%;
      height: 100px;
      margin: 0;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .previous {
      display: none;
    }
    .next {
      display: none;
    }
  }
`;

export default PaginationStyle;
