import { defaultStyles } from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleCardGrid = styled("section")<{
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $numOfItems?: number;
}>`
  ${({ $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "row",
      gap: "40px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: "#fff",
    })}
  justify-content: center;
  flex-wrap: wrap;
  ${({ $numOfItems }) =>
    $numOfItems === 5 || $numOfItems === 6
      ? `
        padding-left: calc(((1280px - 120px) / 4) / 2);
        padding-right: calc(((1280px - 120px) / 4) / 2);
    `
      : ``}

  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "30px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
    ${({ $numOfItems }) =>
      $numOfItems === 5 || $numOfItems === 6
        ? `
        padding-left: calc((((100% - 60px) / 4) / 2));
        padding-right: calc((((100% - 60px) / 4) / 2));
    `
        : ``}
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        direction: "row",
        gap: "20px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Mobile",
      })}
    padding-left: 30px;
    padding-right: 30px;
  }
`;
