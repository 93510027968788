import styled from "styled-components";

export const Space = styled("div")<{
  $height?: string;
  $backgroundColor?: string;
}>`
  height: ${({ $height }) => ($height ? `${$height}px` : "0px")};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? `${$backgroundColor}` : "none"};
`;
