import {
  definePadding,
  defaultStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModuleJumpNavigation = styled("section")<{
  $backgroundColor?: "Black" | "White";
  $paddingTop?: "Medium" | "Small";
  $paddingBottom?: "Medium" | "Small";
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "row",
      gap: "40px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })}

  @media only screen and (max-width: 1280px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "30px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        screenSize: "Tablet",
      })}
  }

  @media only screen and (max-width: 768px) {
    ${({ $paddingTop, $paddingBottom }) =>
      defaultStyles({
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        gap: "20px",
        screenSize: "Mobile",
      })}
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 375px) {
    flex-direction: column;
  }

  align-items: stretch;
`;
