import React from "react";

import * as Styles from "./CenteredTextBlockStyles";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";

const CenteredTextBlock = props => {
  const { header, subHeader } = props;
  return (
    <Styles.CenteredTextBlock>
      <ContainerStyle>
        <RowStyle>
          <ColStyle lg={12} md={12} sm={12}>
            {header && (
              <h3 className="headline">
                <Markdown>{header}</Markdown>
              </h3>
            )}
            {subHeader && (
              <div className="sub-headline">
                <Markdown>{subHeader}</Markdown>
              </div>
            )}
          </ColStyle>
        </RowStyle>
      </ContainerStyle>
    </Styles.CenteredTextBlock>
  );
};

export default CenteredTextBlock;
